import { ExampleModal } from 'components/Phantom/_shop/Modals/ExampleModal'
import { ReactNode } from 'react'
import { MattressModal, MattressModalConnected, SizesModal, SizesModalDisconnected } from 'components/WebEv/Shop/Modals'
import { MembershipModal, MembershipModalConnected } from 'components/Phantom/_shop/Modals/MembershipModal'
import { ModelModal, ModelModalConnected } from 'components/Phantom/_shop/Modals/ModelModal'
import { LegKitModal, LegKitModalConnected } from 'components/Phantom/_shop/Modals/LegKitModal'
import { FinancingTabModalConnected } from 'components/FinancingTabModal'

export type ModalKey = 'example' | '' | 'model-modal' | 'size-modal' | 'legkit-modal' | 'membership-modal' | 'mattress-modal' | 'financing-modal'

// Use for development, maybe there's a better way to do this
export const ModalMapping: Record<ModalKey, ReactNode> = {
	'': null,
	example: <ExampleModal />,
	'legkit-modal': <LegKitModal />,
	'mattress-modal': MattressModal(''),
	'membership-modal': <MembershipModal />,
	'model-modal': <ModelModal />,
	'size-modal': <SizesModalDisconnected region={'us'} />,
}

// Use for production
export const ModalMappingConnected: Record<ModalKey, ReactNode> = {
	'': null,
	example: <ExampleModal />,
	'legkit-modal': <LegKitModalConnected />,
	'mattress-modal': <MattressModalConnected />,
	'membership-modal': <MembershipModalConnected />,
	'model-modal': <ModelModalConnected />,
	'size-modal': <SizesModal />,
	'financing-modal': <FinancingTabModalConnected />,
}
