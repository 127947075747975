import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isABTestActiveVariant, shopPagePricingAB } from 'ab_testing'

export default function useShopPagePricingAB(excludedPages = ['product/pod4', 'product/pod4ultra']) {
	const router = useRouter()
	const [isTestVariantActive, setIsTestVariantActive] = useState<boolean>(isABTestActiveVariant(router.asPath, shopPagePricingAB, 1))

	useEffect(() => {
		if (typeof window !== 'undefined') {
			for (const page of excludedPages) {
				if (router.asPath.includes(page)) {
					setIsTestVariantActive(false)
					return
				}
			}

			setIsTestVariantActive(isABTestActiveVariant(router.asPath, shopPagePricingAB, 1))
		}
	}, [router.asPath, setIsTestVariantActive])

	return isTestVariantActive
}
