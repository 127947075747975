import { getCookie } from 'config/utils'
import { MAIN_EXPERIMENT } from 'middleware'

export interface IVariant {
	exclude?: string[]
	threshold: number
	id: number
}

export interface IABTest {
	id: string
	name: string
	key: string
	active: boolean
	client_only: boolean
	paths: string[]
	excludedPaths?: string[]
	variants: IVariant[]
	validator?: () => boolean
}

export const COOKIE_FOR_IDS = 'ab-tests'
export const COOKIE_FOR_KEYS = 'ab-keys'

export const pathWithRegions = (path: string) => {
	return [path, ...['ca', 'eu', 'uk', 'au', 'se', 'dk'].map((it) => `/${it}${path}`)]
}

export const shopPagePricingAB: IABTest = {
	id: 'shopPagePricingAB',
	key: 'shopPagePricingAB',
	name: 'Shop Page Pricing AB',
	active: true,
	client_only: false,
	paths: [],
	excludedPaths: [...pathWithRegions('/product/pod4/'), ...pathWithRegions('/product/pod4ultra/')],
	variants: [
		{
			threshold: 0.5,
			id: 0,
		},
		{
			threshold: 1,
			id: 1,
		},
	],
}

export const abtests: IABTest[] = [shopPagePricingAB]

export const getActiveExperiments = () => {
	return abtests.filter((it) => it.active)
}

export const getActiveExperimentByID = (id: string) => {
	return abtests.find((it) => it.active && it.id === id)
}

export const getActiveExperimentByKey = (key: string) => {
	return abtests.find((it) => it.active && it.key === key)
}

// export const chooseSubscriptionIncreaseABVariant = (isCalifornia: boolean, isNewYork: boolean) => {
// 	if (isCalifornia) {
// 		return subscriptionIncreaseAB.variants[1]
// 	} else if (isNewYork) {
// 		return subscriptionIncreaseAB.variants[2]
// 	} else {
// 		return subscriptionIncreaseAB.variants[0]
// 	}
// }

export const chooseExperimentVariant = (experiment: IABTest) => {
	const n = Math.random()
	return experiment.variants.find((v) => {
		return n <= v.threshold
	})
}

export const isABTestActiveVariant = (path: string, experiment: IABTest, variantId: number, abTestIdsCookie?: string) => {
	if (typeof window !== 'undefined') {
		if (window.sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')) {
			return false
		}
	}

	if (experiment.active) {
		if (typeof window === 'undefined') {
			// condition (path.includes('/root') && variantId === 0) is for cases when checking if an A version of the test is active
			return experiment.client_only
				? false
				: path.includes(`${experiment.id}.${variantId}`) || (path.includes('/root') && variantId === 0) || !!(abTestIdsCookie && abTestIdsCookie.includes(`${experiment.id}.${variantId}`))
		}

		const abcookie = getCookie(COOKIE_FOR_IDS)
		const expvar = `${experiment.id}.${variantId}`
		const cookieContainsAb = abcookie.includes(expvar)
		const expContainsPath = experiment.paths.includes(window.location.pathname.split('?')[0])
		const experimentExcluded = experiment.excludedPaths?.includes(window.location.pathname.split('?')[0])
		return (experiment.paths.length === 0 || expContainsPath) && cookieContainsAb && !experimentExcluded
	}
	return false
}

export const generatePathPermutationsForExperiments = (path: string) => {
	const normalized_path = (path.startsWith('/') ? path : `/${path}`) + (path.endsWith('/') || path.length === 0 ? '' : '/') // make sure the path starts and ends with /
	const activeExperiments = abtests.filter((it) => it.active && !it.client_only && (it.paths.length === 0 || it.paths.includes(normalized_path)) && !it.excludedPaths?.includes(normalized_path))

	const experimentPaths: string[] = []
	activeExperiments.forEach((it) => {
		const variants = it.variants.filter((v) => v.id !== 0)
		experimentPaths.push(...variants.map((v) => `${it.id}.${v.id}`))
	})
	experimentPaths.sort((a, b) => a.length - b.length || a.localeCompare(b))

	const results: string[] = [...experimentPaths]
	experimentPaths.forEach((it, i) => {
		const prefix = it.split('.')[0]
		const rest = results.slice(i + 1).filter((el) => !el.includes(prefix))
		rest.sort((a, b) => a.length - b.length || a.localeCompare(b))

		let acc = `${it}`
		rest.forEach((p) => {
			const tmp = [it, ...p.split(';')].sort((a, b) => a.length - b.length || a.localeCompare(b))
			const newItem = tmp.join(';')
			if (!results.includes(newItem)) {
				results.push(newItem)
			}

			const prefix2 = p.split('.')[0]
			if (!acc.includes(prefix2)) {
				const tmp2 = [...acc.split(';'), ...p.split(';')].sort((a, b) => a.length - b.length || a.localeCompare(b))
				acc += tmp2.join(';')
			}
			if (!results.includes(acc) && !acc.includes(prefix2)) results.push(acc)
		})
	})

	results.sort((a, b) => a.length - b.length || a.localeCompare(b))
	return [MAIN_EXPERIMENT, ...results]
}

export function getCookieABTests(): { ids: string[]; keys: string[] } {
	const abTestIdsCookie = getCookie(COOKIE_FOR_IDS)
	const abKeysIdsCookie = getCookie(COOKIE_FOR_KEYS)
	if (!abTestIdsCookie) {
		return { ids: [], keys: [] }
	}
	const ids = abTestIdsCookie.split('%3B').filter((it) => {
		const values = it.split('.')
		const id = values[0]
		const variant = values[1]
		const exp = getActiveExperimentByID(id)
		if (exp && exp.validator && variant !== '0') {
			return exp.validator()
		}
		return !!exp
	})
	const keys = abKeysIdsCookie.split('%3B').filter((it) => {
		const values = it.split('_')
		const key = values[0]
		const variant = values[1]
		const exp = getActiveExperimentByKey(key)
		if (exp && exp.validator && variant !== '0') {
			return exp.validator()
		}
		return !!exp
	})
	return { ids, keys }
}
