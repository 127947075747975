// eslint-disable

import { config } from 'utils/internationalization'
import { VanityData, VanityHeroComponent } from './types'
import { PARTNER_COUPONS } from 'stripe_lib/discounts'
import { datadogReportError } from 'events/datadog'

export interface IVanityConfig {
	[key: string]: VanityData
}

const vanities: IVanityConfig = {
	rogan: {
		utm_source: 'podcast',
		utm_medium: 'jre',
		promoCode: 'rogan',
		promoBar: {
			'*': {
				message: 'Joe Rogan Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Joe Rogan Sale',
		savingsTag: 'Joe Rogan Sale: Up to %%% off',
		heroSettings: {
			title: 'The Joe Rogan Experience',
			description: ['Upgrade your sleep with the Pod Cover and receive ### off.'],
		},
		preFooterSettings: {
			title: 'Joe Rogan Approved',
			paragraphs: ['Host of The Joe Rogan Experience podcast, UFC commentator, and comedian.'],
		},
		emailCapSettings: {
			title: 'Joe Rogan Sale',
			imageSrc: 'https://eightsleep.imgix.net/generic_vanity_hero.jpg?v=1682434446',
			imageAlt: 'Portrait of Joe Rogan',
			description: 'Joe Rogan swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Joe Rogan',
	},

	pomp: {
		utm_source: 'podcast',
		utm_medium: 'pomp',
		promoCode: 'pomp',
		promoBar: {
			'*': {
				message: 'Anthony Pomp Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Anthony Pomp Sale',
		savingsTag: 'Anthony Pomp Sale: Up to %%% off',
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/apomp_mobile_hero.jpg?v=1644589722',
			imageAlt: 'Portrait of Anthony Pomp',
			subtitle: 'Pomp Exclusive',
			description: 'Anthony Pomp swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Anthony Pomp',
		heroSettings: {
			title: '"Eight Sleep changed my life. I sleep better and have become more effective at work and happier outside work."',
			description: ['Anthony Pomp', 'Host of The Best Business Show and The Pomp Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/apomp_mobile_hero.jpg?v=1644589722',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/apomp_hero_big.jpg?v=1644590154',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/apomp_hero_big.jpg?v=1644590154',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Pomp Approved',
			paragraphs: ['Pomp swears by the Pod to get better sleep. Learn more for yourself.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/apomp_mobile_bottom.jpg?v=1644589722',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/apomp_bottom.jpg?v=1644589722',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/apomp_bottom.jpg?v=1644589722',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
	},

	unbeatable: {
		utm_source: 'podcast',
		utm_medium: 'unbeatable',
		promoCode: 'unbeatable',
		promoBar: {
			'*': {
				message: 'Unbeatable Mind Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Unbeatable Mind Sale',
		savingsTag: 'Unbeatable Mind Sale: Up to %%% off',
		heroSettings: {
			title: 'Get unbeatable sleep.',
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		emailCapSettings: {
			title: 'Unbeatable Mind Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'The hosts of Unbeatable Mind swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'The hosts of Unbeatable Mind',
	},

	suitingup: {
		utm_source: 'podcast',
		utm_medium: 'suitingup',
		promoCode: 'suitingup',
		promoBar: {
			'*': {
				message: 'Suiting Up Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Suiting Up Sale',
		savingsTag: 'Suiting Up Sale: Up to %%% off',
		heroSettings: {
			title: 'Suit up for savings.',
		},
		emailCapSettings: {
			title: 'Suiting Up Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'Paul Rabil swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Paul Rabil',
	},

	outkick: {
		utm_source: 'podcast',
		utm_medium: 'outkick',
		promoCode: 'outkick',
		promoBar: {
			'*': {
				message: 'OutKick Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'OutKick Sale',
		savingsTag: 'OutKick Sale: Up to %%% off',
		heroSettings: {
			title: 'Out with the old, in with the new.',
		},
		emailCapSettings: {
			title: 'OutKick Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'The OutKick podcast swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'the OutKick podcast',
	},

	amp: {
		utm_source: 'podcast',
		utm_medium: 'aubreymarcus',
		promoCode: 'amp',
		promoBar: {
			'*': {
				message: 'Aubrey Marcus Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Aubrey Marcus Sale',
		savingsTag: 'Aubrey Marcus Sale: Up to %%% off',
		heroSettings: {
			title:
				'The ability to keep yourself cool at night is absolutely paramount to optimize your sleep. Eight-Sleep integrates all the tools you need into one beautifully designed bed. It can’t be beat.',
			description: ['Aubrey Marcus', 'Founder/CEO of Onnit, NYT Bestselling Author of “Own The Day”, Host of the Aubrey Marcus Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/aubrey_marcus_header_mobile.jpg?v=1633688184',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/aubrey_marcus_footer.png?v=1633694374',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/aubrey_marcus_footer.png?v=1633694374',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Aubrey Marcus Approved',
			paragraphs: ['Founder/CEO of Onnit, NYT Bestselling Author of “Own The Day”, Host of the Aubrey Marcus Podcast'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/aubrey_marcus_footer.png?v=1633694374',
					mediaQuery: '',
				},
			],
		},
		emailCapSettings: {
			title: 'Aubrey Marcus’s Favorite',
			imageSrc: 'https://eightsleep.imgix.net/aubrey_marcus_email.jpg?v=1633695604',
			imageAlt: 'Portrait of Aubrey Marcus',
			description: 'Aubrey Marcus swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Aubrey Marcus',
	},

	sleeplikethomas: {
		utm_source: 'podcast',
		utm_medium: 'thomasdelauer',
		promoCode: 'sleeplikethomas',
		promoBar: {
			'*': {
				message: 'Thomas DeLauer Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Thomas DeLauer Sale',
		savingsTag: 'Thomas DeLauer Sale: Up to %%% off',
		heroSettings: {
			title: '“The coolest part of Eight Sleep is the adaptive cooling mechanism. It’s like it knows what I need to get the most restorative sleep.”',
			description: ['Thomas DeLauer', 'Thomas DeLauer has become a household name in the biohacking community with his educational videos reaching over 20 Million people per week'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/thomas_delauer_hero.jpg?v=1644994952',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Thomas DeLauer Approved',
			paragraphs: ['Thomas DeLauer has become a household name in the biohacking community with his educational videos reaching over 20 Million people per week'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/thomas_delauer_footer_big.jpg?v=1633958445',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Thomas DeLauer’s Favorite',
			imageSrc: 'https://eightsleep.imgix.net/thomas_delauer_email.jpg?v=1634042572',
			imageAlt: 'Portrait of Thomas DeLauer',
			description: 'Thomas DeLauer swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Thomas DeLauer',
	},

	mark: {
		holiday_promo: true,
		utm_source: 'newsletter',
		utm_medium: 'mark',
		promoCode: 'mark',
		promoBar: {
			'*': {
				message: "Mark's Picks Sale: Up to ### off the Pod",
				link: null,
			},
		},
		saleName: "Mark's Picks Sale",
		savingsTag: "Mark's Picks Sale: Up to %%% off",
		heroSettings: {
			title:
				'“I always sleep better when my bed is nice and cool, which is why I love Eight Sleep’s Pod. It’s a mattress that actively regulates my temperature all night, so I sleep deeper and wake up feeling rested and energized.”',
			description: ['Dr. Mark Hyman', 'American physician and New York Times bestselling author'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mark_hero_mobile.jpg?v=1638285007',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mark_hero_mobile.jpg?v=1638285007',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mark_hero.jpg?v=1638285007',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Mark Hyman Approved',
			paragraphs: ['Dr. Mark Hyman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mark_hyman_bottom_hero_mobile_c3cb067d-90c3-41a6-b9c5-703d4ac4bd0a.jpg?v=1637604420',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mark_hyman_bottom_hero_mobile_c3cb067d-90c3-41a6-b9c5-703d4ac4bd0a.jpg?v=1637604420',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mark_hyman_bottom_hero.jpg?v=1637603778',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: 'https://eightsleep.imgix.net/mark_hyman_email_cap.jpg?v=1637603778',
			imageAlt: 'Portrait of Mark Hyman',
			description: 'Dr. Mark Hyman swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Dr. Mark Hyman',
		klaviyoProperties: {
			title:
				'I always sleep better when my bed is nice and cool, which is why I love Eight Sleep’s Pod. It’s a mattress that actively regulates my temperature all night, so I sleep deeper and wake up feeling rested and energized.',
			image: 'https://eightsleep.imgix.net/mark_hero.jpg?v=1638285007',
			name: 'Dr. Mark Hyman',
			discount: '%%%',
			mattressDiscount: '%%%',
			link: 'https://www.eightsleep.com/mark',
		},
	},

	twist: {
		utm_source: 'podcast',
		utm_medium: 'twist',
		promoCode: 'twist',
		promoBar: {
			'*': {
				message: 'TWIST Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'TWIST Sale',
		savingsTag: 'TWIST Sale: Up to %%% off',
		heroSettings: {
			title: '“I loved the product so much I invested in the company!”',
			description: ['Jason Calacanis'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/twist_hero_mobile.jpg?v=1638284921',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twist_hero_mobile.jpg?v=1638284921',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twist_hero.jpg?v=1638284922',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jason Approved',
			paragraphs: ['Jason swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jason_bottom_mobile.jpg?v=1638197424',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jason_bottom_mobile.jpg?v=1638197424',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jason_bottom_desktop.jpg?v=1638197424',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/jason_email_cap.jpg?v=1638197465',
			imageAlt: 'Portrait of Jason',
			title: 'TWIST Exclusive',
			description: 'Jason Calacanis swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Jason Calacanis',
		klaviyoProperties: {
			title: 'I loved the product so much I invested in the company!',
			image: 'https://eightsleep.imgix.net/twist_hero.jpg?v=1638284922',
			name: 'Jason Calacanis',
			discount: '%%%',
			link: 'https://www.eightsleep.com/twist',
			mattressDiscount: '%%%',
		},
	},

	whereithappens: {
		utm_source: 'podcast',
		utm_medium: 'whereithappens',
		promoCode: 'whereithappens',
		promoBar: {
			'*': {
				message: 'Where it Happens Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Where it Happens Sale',
		savingsTag: 'Where it Happens Sale: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep transformed me from a poor sleeper to a wonderful one.”',
			description: ['Sahil Bloom & Greg Isenberg', 'Co-hosts of the Where it Happens podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/wih_hero_mobile.jpg?v=1638285268',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wih_hero_mobile.jpg?v=1638285268',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wih_hero_b95c7da4-d0da-4694-877a-a8850fbfbb45.jpg?v=1638299801',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Where it Happens Approved',
			paragraphs: ['Sahil and Greg swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/where_it_happens_bottom_mobile.jpg?v=1638204431',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/where_it_happens_bottom_mobile.jpg?v=1638204431',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/where_it_happens_bottom_destkop.jpg?v=1638204431',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Where it Happens Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/where_it_happens_email_cap.jpg?v=1638204431',
			imageAlt: 'Portrait of Sahil and Greg',
			description: 'Sahil and Greg swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Sahil and Greg',
	},

	nochill: {
		utm_source: 'podcast',
		utm_medium: 'no-chill',
		promoCode: 'nochill',
		promoBar: {
			'*': {
				message: 'No Chill Podcast Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'No Chill Podcast Sale',
		savingsTag: 'No Chill Podcast Sale: Up to %%% off',
		heroSettings: {
			title: "“Eight Sleep has become a game changer for me. Recovering after a workout gets tougher as you get older. You need good rest, and with Eight Sleep, the rest isn't good. It's great.”",
			description: ['Gilbert Arenas', 'Host of the the No Chill Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/nochill_hero_mobile.jpg?v=1638280250',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/nochill_hero_mobile.jpg?v=1638280250',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/nochill_hero_desktop.jpg?v=1638280076',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Gilbert Approved',
			paragraphs: ['Gilbert swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/no_chill_bottom_mobile.jpg?v=1638205212',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/no_chill_bottom_mobile.jpg?v=1638205212',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/no_chill_bottom_desktop.jpg?v=1638205212',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/no_chill_email_cap.jpg?v=1638205212',
			imageAlt: 'Portrait of Gilbert Arenas',
			title: 'The No Chill Exclusive',
			description: 'Gilbert swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Gilbert',
	},

	influencer: {
		utm_source: 'podcast',
		utm_medium: 'tip',
		promoCode: 'influencer',
		promoBar: {
			'*': {
				message: 'The Influencer Podcast Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Influencer Podcast Sale',
		savingsTag: 'The Influencer Podcast Sale: Up to %%% off',
		heroSettings: {
			title: "“Since using Eight Sleep I've slept warmer and more comfortably. This is definitely a product that will help you sleep through the night!”",
			description: ['Julie Solomon | Influencer Podcast', 'Host of the The Influencer Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/influencer_hero_mobile_b7fbab8d-ce9c-483e-8f8e-9e2db7a5c391.jpg?v=1638284788',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/influencer_hero_mobile_b7fbab8d-ce9c-483e-8f8e-9e2db7a5c391.jpg?v=1638284788',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/influencer_hero.jpg?v=1638284788',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Julie Approved',
			paragraphs: ['Julie swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/influencer_bottom_mobile.jpg?v=1638208496',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/influencer_bottom_mobile.jpg?v=1638208496',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/influencer_bottom_desktop.jpg?v=1638208496',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/influencer_email_cap.jpg?v=1638208496',
			imageAlt: 'Portrait of Julie Solomon',
			title: 'The Influencer Podcast Exclusive',
			description: 'Julie swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Julie',
		klaviyoProperties: {
			title: "Since using Eight Sleep I've slept warmer and more comfortably. This is definitely a product that will help you sleep through the night!",
			image: 'https://eightsleep.imgix.net/influencer_hero.jpg?v=1638284788',
			name: 'Julie Solomon',
			discount: '%%%',
			link: 'https://www.eightsleep.com/influencer',
			mattressDiscount: '%%%',
		},
	},

	yourewelcome: {
		utm_source: 'podcast',
		utm_medium: 'chaelsonnen',
		promoCode: 'yourewelcome',
		promoBar: {
			'*': {
				message: "You're Welcome! Exclusive: Up to ### off the Pod",
				link: null,
			},
		},
		saleName: "You're Welcome! With Chael Sonnen",
		savingsTag: "You're Welcome! Exclusive: %%% off",
		heroSettings: {
			title: "Big savings, you're welcome.",
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		emailCapSettings: {
			title: "You're Welcome! With Chael Sonnen",
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'Chael Sonnen swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Chael Sonnen',
	},

	podcast: {
		utm_source: 'podcast',
		utm_medium: 'podcast',
		promoCode: 'podcast',
		promoBar: {
			'*': {
				message: 'Spotify! Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Spotify Sale',
		savingsTag: "You're Welcome! Exclusive: %%% off",
		heroSettings: {
			title: 'Tune up your sleep.',
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		partnerName: 'Spotify',
	},

	logan: {
		utm_source: 'podcast',
		utm_medium: 'loganpaul',
		promoCode: 'logan',
		promoBar: {
			'*': {
				message: 'Impaulsive Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Impaulsive Sale',
		savingsTag: 'Impaulsive Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep like the champ.',
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		emailCapSettings: {
			title: 'Impaulsive Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'Logan Paul swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Logan Paul',
	},

	span: {
		utm_source: 'podcast',
		utm_medium: 'span',
		promoCode: 'span',
		promoBar: {
			'*': {
				message: 'Spotify Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Spotify Sale',
		savingsTag: 'Spotify Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep smarter this year',
		},
		emailCapSettings: {
			title: 'Spotify Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'The hosts of SPAN swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'the hosts of SPAN',
	},

	ryen: {
		utm_source: 'podcast',
		utm_medium: 'ryen',
		promoCode: 'ryen',
		promoBar: {
			'*': {
				message: 'Ryen Russilo Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ryen Russilo Sale',
		savingsTag: 'Ryen Russilo Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep smarter this year',
		},
		emailCapSettings: {
			title: 'Ryen Russilo Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'Ryen Russilo swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Ryen Russilo',
	},

	powerproject: {
		utm_source: 'podcast',
		utm_medium: 'powerproject',
		promoCode: 'powerproject',
		promoBar: {
			'*': {
				message: 'Power Project Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Power Project Sale',
		savingsTag: 'Power Project Sale: Up to %%% off',
		heroSettings: {
			title: '“Utilizing the Eight Sleep Pod has made a huge positive impact on our fitness.”',
			description: ['Mark Bell, Nsima Inyang, and Andrew Zaragoza', 'Hosts of the Power Project Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/power_project_mobile.jpg?v=1644995263',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/power_project_hero.jpg?v=1644995263',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/power_project_hero.jpg?v=1644995263',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Power Project Approved',
			paragraphs: ['Power Project hosts Mark Bell, Nsima Inyang, and Andrew Zaragoza swear by the Pod to help improve their overall sleep quality. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/power_project_bottom_hero_mobile.jpg?v=1635510382',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/power_project_bottom_hero_desktop.jpg?v=1635510763',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/power_project_bottom_hero_desktop.jpg?v=1635510763',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Power Project Sale',
			subtitle: 'Power Project',
			description: 'Mark Bell swears by the Pod to sleep at the perfect temperature.',
			imageSrc: 'https://eightsleep.imgix.net/power_project_email.jpg?v=1644995264',
			imageAlt: 'Mark Bell’s Favorite',
		},
		partnerName: 'Mark Bell',
	},

	morningbrew: {
		utm_source: 'morningbrew',
		utm_medium: 'newsletter',
		promoCode: 'morningbrew',
		promoBar: {
			'*': {
				message: 'Morning Brew Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Morning Brew Sale',
		savingsTag: 'Morning Brew Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep cool.<br /> All night long.',
			description: ['Receive ### off the Pod, exclusively for Morning Brew readers.'],
			component: VanityHeroComponent.MORNING_BREW,
		},
		preFooterSettings: {
			title: 'Morning Brew readers save %%%',
			paragraphs: ['Try the Pod at home today.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/pod_compare_dual_cta.jpg?v=1634649431',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pod_compare_dual_cta.jpg?v=1634649431',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pod_compare_dual_cta.jpg?v=1634649431',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Morning Brew exclusive deal.',
			description: 'Morning Brew swears by the Pod to sleep at the perfect temperature.',
			imageSrc: 'https://eightsleep.imgix.net/joe_rogan_footer.jpg?v=1632776936',
			imageAlt: '',
		},
		partnerName: 'Morning Brew',
		// customHeroBadge: {
		// 	src: 'https://eightsleep.imgix.net/morning_brew_and_eightsleep.png?v=1634309821',
		// 	alt: 'Morning Brew and Eightsleep'
		// }
	},

	farnamstreet: {
		utm_source: 'newsletter',
		utm_medium: 'farnamstreet',
		promoCode: 'farnamstreet',
		promoBar: {
			'*': {
				message: 'Farnam Street Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Farnam Street Sale',
		savingsTag: 'Farnam Street Exclusive: Up to %%% off',
		heroSettings: {
			title: '“I use this every night and love that Eight Sleep gives me just the right temperature everytime."',
			description: ['Shane Parrish', 'Founder of Farnam Street Media and the CEO of Syrus Partners, a private investment company.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/farnam_street_hero_mobile.jpg?v=1635509035',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/farnam_street_hero.jpg?v=1644996078',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/farnam_street_hero.jpg?v=1644996078',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Farnam Street Approved',
			paragraphs: ['Shane Parrish swears by the Pod to sleep at the perfect temperature. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/farnam_street_bottom_hero_mobile.jpg?v=1635509035',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/farnam_street_bottom_hero_desktop.jpg?v=1635509035',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/farnam_street_bottom_hero_desktop.jpg?v=1635509035',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Farnam Street',
			subtitle: 'Shane Parrish’s Favorite',
			description: 'Shane Parrish swears by the Pod to sleep at the perfect temperature.',
			imageSrc: 'https://eightsleep.imgix.net/farnam_street_hero_email_cap.jpg?v=1635509035',
			imageAlt: 'Shane Parrish’s Favorite',
		},
		partnerName: 'Shane Parrish',
	},

	sahil: {
		utm_source: 'newsletter',
		utm_medium: 'sahil',
		promoCode: 'sahil',
		promoBar: {
			'*': {
				message: 'Sahil Bloom Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sahil Bloom Sale',
		savingsTag: 'Sahil Bloom Exclusive: Up to %%% off',
		heroSettings: {
			title: '“The science doesn’t lie—sleep is foundational to performance. Eight Sleep has changed the game with its Pod and Pod."',
			description: ['Sahil Bloom', 'Co-host of the Where it Happens Podcast and author of The Curiosity Newsletter'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sahil_small_da7204fd-8898-4f14-a414-7cf651c4bc9b.jpg?v=1644996488',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sahil_hero.jpg?v=1644996442',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sahil_hero.jpg?v=1644996442',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sahil Bloom Approved',
			paragraphs: ['Sahil Bloom swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sahil-bloom-bottom-mobile.jpg?v=1637073805',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sahil-bloom-bottom-desktop.jpg?v=1637073805',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sahil-bloom-bottom-desktop.jpg?v=1637073805',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Sahil Exclusive',
			description: 'Sahil Bloom swears by the Pod to sleep at the perfect temperature.',
			imageSrc: 'https://eightsleep.imgix.net/sahil_email.jpg?v=1636996878',
			imageAlt: 'Sahil',
		},
		partnerName: 'Sahil Bloom',
	},

	wodcast: {
		utm_source: 'podcast',
		utm_medium: 'wodcast',
		promoCode: 'wodcast',
		promoBar: {
			'*': {
				message: 'WODcast Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'WODcast Sale',
		savingsTag: 'WODcast Sale: Up to %%% off',
		heroSettings: {
			title: '“The Eight sleep mattress has taken my health to another level.  I feel like I’m getting all the quality sleep I’ve lost over the years."',
			description: ['Eddie Ifft', 'Host of the WODcast podcast and standup comedian'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/wodcast_small_0bf37bb5-5389-4c17-a40b-b0ceb14b822c.jpg?v=1644996611',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wodcast_hero.jpg?v=1644996611',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wodcast_hero.jpg?v=1644996611',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'WODcast Approved',
			paragraphs: ['Eddie swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/wodcast-bottom-mobile.png?v=1637076094',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wodcast-bottom-desktop.png?v=1637074316',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/wodcast-bottom-desktop.png?v=1637074316',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'WODcast Exclusive',
			description: 'Eddie swears by the Pod to sleep at the perfect temperature.',
			imageSrc: 'https://eightsleep.imgix.net/wodcast_email.jpg?v=1636996325',
			imageAlt: 'WODcast',
		},
		partnerName: 'Eddie',
	},

	talking150: {
		utm_source: 'podcast',
		utm_medium: 'talking-elite-fitness',
		promoCode: 'talking150',
		promoBar: {
			'*': {
				message: 'Talking Elite Fitness Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Talking Elite Fitness Sale',
		savingsTag: 'Talking Elite Fitness Sale: Up to %%% off',
		heroSettings: {
			title: '"The Eight Sleep Pod helps to keep our bodies at the ideal temperature so we can get the best night\'s sleep possible."',
			description: ['Sean Woodland & Tommy Marquez', 'CrossFit Games Commentators & Hosts of the Talking Elite Fitness Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/talking_elite_small_6d98ed40-aec9-4384-8878-cf8a1081e889.jpg?v=1644996727',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/taling_elite_hero.jpg?v=1644996727',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/taling_elite_hero.jpg?v=1644996727',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Talking Elite Fitness Approved',
			paragraphs: ['Talking Elite Fitness hosts Sean Woodland & Tommy Marquez swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/tef_bottom_hero_mobile.jpg?v=1635507343',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/tef_bottom_hero_desktop2.jpg?v=1635506097',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/tef_bottom_hero_desktop2.jpg?v=1635506097',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: 'https://eightsleep.imgix.net/tef_emailcap.jpg?v=1635441593',
			imageAlt: '%%% off - Talking Elite Fitness Approved',
			description: 'Sean Woodland & Tommy Marquez swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Sean Woodland & Tommy Marquez',
	},

	yap: {
		utm_source: 'podcast',
		utm_medium: 'yap',
		promoCode: 'yap',
		promoBar: {
			'*': {
				message: 'YAP Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'YAP Sale',
		savingsTag: 'YAP Exclusive: %%% off',
		heroSettings: {
			title: '“The Eight Sleep Pod has not only increased the quantity of sleep I get but the quality as well!”',
			description: ['Hala Taha', 'Host of the Young and Profiting podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/yap_hero_mobile.jpg?v=1638516439',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/yap_hero_mobile.jpg?v=1638516439',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/yap_hero.jpg?v=1638516439',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Hala Taha Approved',
			paragraphs: ['Hala Taha swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/yap_bottom_mobile.jpg?v=1638516439',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/yap_bottom_mobile.jpg?v=1638516439',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/yap_bottom.jpg?v=1638516439',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/yap_email_cap.jpg?v=1638516439',
			imageAlt: 'Portrait of Hala Taha',
			title: 'YAP Exclusive',
			description: 'Hala Taha swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Hala Taha',
	},

	dhru: {
		utm_source: 'podcast',
		utm_medium: 'dhru',
		promoCode: 'dhru',
		promoBar: {
			'*': {
				message: 'Dhru Purohit Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dhru Purohit Sale',
		savingsTag: 'Dhru Purohit Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep smarter this year',
			description: ['Dhru Purohit', 'Host of the Dhru Purohit Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_hero_small_636577c1-e599-46aa-89f3-615b36db2ff5.jpg?v=1638954099',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_hero_small_636577c1-e599-46aa-89f3-615b36db2ff5.jpg?v=1638954099',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_hero.jpg?v=1638954099',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dhru Approved',
			paragraphs: ['Dhru swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_bottom_small_5e20c239-b1aa-4185-a9e6-c4d86d4ee623.jpg?v=1638954099',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_bottom_small_5e20c239-b1aa-4185-a9e6-c4d86d4ee623.jpg?v=1638954099',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dhru_purohit_bottom.jpg?v=1638954099',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/dhru_purohit_hero_small_636577c1-e599-46aa-89f3-615b36db2ff5.jpg?v=1638954099',
			imageAlt: 'Portrait of Dhru Purohit',
			title: 'Dhru Purohit Exclusive',
			description: 'Dhru swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Dhru',
		klaviyoProperties: {
			title:
				"Our body temperature is a major factor in how soundly we sleep at night. If you're having night sweats, tossing and turning, or just not feeling rested, it might be time to get serious about your sleep hygiene by investing in Eight Sleep.",
			image: 'https://eightsleep.imgix.net/dhru_purohit_hero.jpg?v=1638954099',
			name: 'Dhru Purohit',
			discount: '%%%',
			mattressDiscount: '%%%',
			link: 'https://www.eightsleep.com/dhru',
		},
	},

	endelmembers: {
		utm_source: 'endel_email',
		utm_medium: 'email',
		promoCode: 'endelmembers',
		promoBar: {
			'*': {
				message: 'Endel exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Endel Sale',
		savingsTag: 'Endel exclusive',
		heroSettings: {
			title: 'The Future of Sound Meets the Future of Sleep',
			description: ['Take your sleep to the next level with Eight Sleep. Receive up to ### off the Pod, exclusively for Endel members.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/endel_mobile_header.jpg?v=1641488472',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/endel_desktop_header.jpg?v=1641488472',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/endel_desktop_header.jpg?v=1641488472',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Endel members save up to %%% off Pod 4 Ultra off',
			paragraphs: ['Try the Pod at home today.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/endel_bottom_bg.jpg?v=1641488961',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/endel_bottom_bg.jpg?v=1641488961',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/endel_bottom_bg.jpg?v=1641488961',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/endel_mobile_header.jpg?v=1641488472',
			imageAlt: 'Endel Exclusive',
			title: 'Endel Exclusive',
			description: `Endel swears by the Pod to sleep at the perfect temperature.`,
		},
		partnerName: 'Endel',
	},

	morningchalkup: {
		utm_source: 'newsletter',
		utm_medium: 'morningchalkup',
		promoCode: 'morningchalkup',
		promoBar: {
			'*': {
				message: 'Justin Medeiros Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Justin Medeiros Sale',
		savingsTag: 'Justin Medeiros Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep as hard as you train like Justin Medeiros',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/justin_mobile.png?v=1641764252',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_desktop.png?v=1641764253',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_desktop.png?v=1641764253',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Be powered like the best CrossFit',
			paragraphs: ['Justin Medeiros and many more swear by the Pod to help improve their overall sleep quality. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/justin_mobile.png?v=1641764252',
			imageAlt: 'Portrait of Justin Medeiros',
			title: 'Recover like Justin',
			description: 'Justin Medeiros swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Justin Medeiros',
	},

	justinmedeiros: {
		utm_source: 'vanity',
		utm_medium: 'justinmedeiros',
		promoCode: 'justinmedeiros',
		promoBar: {
			'*': {
				message: 'Justin Medeiros Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Justin Medeiros Sale',
		savingsTag: 'Justin Medeiros Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep as hard as you train like Justin Medeiros',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/justin_mobile.png?v=1641764252',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_desktop.png?v=1641764253',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_desktop.png?v=1641764253',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Be powered like the best CrossFit',
			paragraphs: ['Justin Medeiros and many more swear by the Pod to help improve their overall sleep quality. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/justin_bottom.jpg?v=1641764252',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/justin_mobile.png?v=1641764252',
			imageAlt: 'Portrait of Justin Medeiros',
			title: 'Recover like Justin',
			description: 'Justin Medeiros swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Justin Medeiros',
	},

	exclusive: {
		utm_source: 'direct-mail',
		utm_medium: 'pebblepost',
		promoCode: 'pebblepost',
		promoBar: {
			'*': {
				message: 'Exclusive - ### off the Pod',
				link: null,
			},
		},
		saleName: 'Exclusive Sale',
		savingsTag: 'Exclusive Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep smarter this year',
		},
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: 'Couple lying on their Eight Sleep Mattress',
			title: 'Exclusive Offer',
			description: 'PebblePost swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'PebblePost',
	},

	'joe-pomp': {
		utm_source: 'partnership',
		utm_medium: 'joe-pomp',
		promoCode: 'joepomp',
		page: '/product/pod-cover',
		promoBar: {
			'*': {
				message: 'Joe Pomp Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Joe Pomp Sale',
		savingsTag: 'Joe Pomp Sale: Up to %%% off',
		heroSettings: {
			title: "“As someone who was always struggled with sleep, the Pod has been a game changer. It's amazing”",
			description: ['Joe Pompliano', 'Founder and Author of Huddle Up newsletter'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/joe_pomp_mobile.jpg?v=1644996907',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/joe_pomp_hero.jpg?v=1644996907',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/joe_pomp_hero.jpg?v=1644996907',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Joe Pomp Approved',
			paragraphs: ['Ready for better sleep? Shop our holiday prices. Replace your mattress or just add the Pod technology to your bed.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/pomp-bottom-mobile.png?v=1637352220',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/joe-pomp-bottom-desktop.png?v=1637352889',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/joe-pomp-bottom-desktop.png?v=1637352889',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/joe_pomp_email.jpg?v=1644996959',
			imageAlt: 'Portrait of Joe Pompliano',
			subtitle: 'Joe Pomp Sale',
			description: 'Joe Pomp swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Joe Pomp',
	},

	tim: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tim-crop.png',
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'tim-ferriss',
		promoCode: 'tim',
		promoBar: {
			'*': {
				message: 'Tim Ferriss Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Tim Ferriss Sale',
		savingsTag: 'Tim Ferriss Sale',
		heroSettings: {
			title: "Now, there's a simple way to sleep at the perfect temperature, and it's a device I use myself - The Pod Cover",
			description: ['- Tim Ferriss'],
			component: VanityHeroComponent.TIM_FERRISS,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/tim-header-mobile.jpg?v=1610667840',
					mediaQuery: '(max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/tim-header-desktop.jpg?v=1610667840',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: 'https://eightsleep.imgix.net/tim_ecap_image.png?v=1685129242',
			imageAlt: 'Portrait of Tim Ferriss',
			description: 'Tim Ferriss swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Tim Ferriss',
		preFooterSettings: {
			title: 'Tim Ferris Approved',
		},
		ecapImg: 'https://eightsleep.imgix.net/tim_ecap_image.png?v=1685129242',
	},

	naval: {
		utm_source: 'podcast',
		utm_medium: 'naval',
		promoCode: 'naval',
		page: '/product/pod-cover',
		promoBar: {
			'*': {
				message: 'Infinity Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Infinity Sale',
		savingsTag: 'Infinity Sale',
		heroSettings: {
			title: 'Sleep smarter this year',
			description: ["Infinity Exclusive: ### off the Pod for Infinity's readers."],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: '',
			imageAlt: '',
			description: 'Naval swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Naval',
	},

	lex: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lex-crop.png',
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'lex',
		promoCode: 'lex',
		promoBar: {
			'*': {
				message: 'Lex Fridman Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Lex Fridman Sale',
		savingsTag: 'Lex Fridman: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep helps me fall asleep faster and sleep better. I love it. For me, a cool bed with a warm blanket is a recipe for optimal sleep”',
			description: ['Lex Fridman', 'AI researcher at MIT and beyond'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/lex_small_b3e000a9-3327-4850-a071-f0b66ea4a821.jpg?v=1644994451',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lex_hero.jpg?v=1644994451',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lex_hero.jpg?v=1644994451',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Lex Fridman Approved',
			paragraphs: ['Lex Fridman swears by the Pod to boost his sleep. Learn more for yourself'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/lex_hero.jpg?v=1644994451',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/lex_small_b3e000a9-3327-4850-a071-f0b66ea4a821.jpg?v=1644994451',
			imageAlt: 'Portrait of Lex Fridman',
			subtitle: 'Lex Fridman Sale',
			description: 'Lex Fridman swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Lex Fridman',
	},

	dave: {
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'bulletproof',
		promoCode: 'dave',
		promoBar: {
			'*': {
				message: 'Dave Asprey Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dave Asprey Sale',
		savingsTag: 'Dave Asprey Exclusive: Up to %%% off',
		heroSettings: {
			title: '"As someone who has always struggled with sleep, the Pod has been a game changer. It\'s more than worth your time."',
			description: ['- Dave Asprey'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dave_hero_desktop.jpg?v=1645023142',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dave_hero_desktop.jpg?v=1645023142',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dave_hero_desktop.jpg?v=1645023142',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dave Asprey Approved',
			paragraphs: [
				'Dave Asprey is the Founder & Chairman of Bulletproof. A New York Times bestselling science author, and host of the Webby award-winning podcast Bulletproof Radio, Dave Asprey swears by the Pod to boost his sleep. Learn more for yourself.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dave_footer.jpg?v=1645023291',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/dave_email_cap.png?v=1645023142',
			imageAlt: 'Portrait of Dave Asprey',
			subtitle: 'Dave Asprey Sale',
			description: 'Dave Asprey swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Dave Asprey',
	},

	ryan: {
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'ryan',
		promoCode: 'dailystoic',
		promoBar: {
			'*': {
				message: 'Ryan Holiday Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ryan Holiday Exclusive',
		savingsTag: 'Ryan Holiday Exclusive: Up to %%% off',
		heroSettings: {
			title: '“I love a warm bed and the Eight Sleep technology makes it easier to sleep through the night.”',
			description: ["— Ryan Holiday, bestselling author of Trust Me, I'm Lying, The Obstacle Is the Way, Ego Is the Enemy and other books"],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ryan Holiday Approved',
			paragraphs: [
				'Ryan Holiday is the bestselling author of Trust Me, I’m Lying; The Obstacle Is the Way; Ego Is the Enemy; Conspiracy and other books about marketing, culture, and the human condition.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ryan_footer.jpg?v=1645023447',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/ryan_email_cap.png?v=1645023447',
			imageAlt: 'Portrait of Ryan Holiday',
			subtitle: 'Ryan Holiday Sale',
			description: 'Ryan Holiday swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Ryan Holiday',
	},

	dailystoic: {
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'ryan',
		promoCode: 'dailystoic',
		promoBar: {
			'*': {
				message: 'Ryan Holiday Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ryan Holiday Sale',
		savingsTag: 'Ryan Holiday Sale: Up to %%% off',
		heroSettings: {
			title: '“I love a warm bed and the Eight Sleep technology makes it easier to sleep through the night.”',
			description: ["— Ryan Holiday, bestselling author of Trust Me, I'm Lying, The Obstacle Is the Way, Ego Is the Enemy and other books"],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ryan_hero.jpg?v=1645023447',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ryan Holiday Approved',
			paragraphs: [
				'Ryan Holiday is the bestselling author of Trust Me, I’m Lying; The Obstacle Is the Way; Ego Is the Enemy; Conspiracy and other books about marketing, culture, and the human condition.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ryan_footer.jpg?v=1645023447',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/ryan_email_cap.png?v=1645023447',
			imageAlt: 'Portrait of Ryan Holiday',
			subtitle: 'Ryan Holiday Sale',
			description: 'Ryan Holiday swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Ryan Holiday',
	},

	findingmastery: {
		utm_source: 'podcast',
		utm_medium: 'findingmastery',
		promoCode: 'findingmastery',
		promoBar: {
			'*': {
				message: 'Finding Mastery Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Finding Mastery Sale',
		savingsTag: 'Finding Mastery Sale: Up to %%% off',
		heroSettings: {
			title: '“My sleep is optimized with colder temperatures. I love that Eight Sleep gives me the ability to dial in just the right temperature.”',
			description: ['Dr. Michael Gervais', 'Co-founder of Compete to Create & Host of Finding Mastery podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/findingmastery_hero.jpg?v=1645023606',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/findingmastery_hero.jpg?v=1645023606',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/findingmastery_hero.jpg?v=1645023606',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Michael Gervais Approved',
			paragraphs: ['Host of Finding Mastery podcast, Co-Author of Compete to Create, High Performance Psychologist, clientele includes Olympians, Fortune 500 companies and more.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/findingmastery_footer.jpg?v=1645023606',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/findingmastery_email_cap.png?v=1645023606',
			imageAlt: 'Portrait of Dr. Michael Gervais',
			subtitle: 'Finding Mastery Sale',
			description: 'Dr. Michael Gervais swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Dr. Michael Gervais',
	},

	patrick: {
		utm_source: 'podcast',
		utm_medium: 'patricko',
		promoCode: 'patrick',
		promoBar: {
			'*': {
				message: "Patrick O'Shaughnessy Sale: Up to ### off the Pod",
				link: null,
			},
		},
		saleName: `Patrick O'Shaughnessy Sale`,
		savingsTag: "Patrick O'Shaughnessy Sale: Up to %%% off",
		heroSettings: {
			title: "“I was so impressed after using Eight Sleep's mattress and believed it was the future of sleep that I became an investor in the company.”",
			description: ["— Patrick O'Shaughnessy", "Host of Invest Like the Best, CEO of O'Shaughnessy Asset Management, and partner at early-stage venture firm Positive Sum"],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/patrick_hero.jpg?v=1645023810',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/patrick_hero.jpg?v=1645023810',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/patrick_hero.jpg?v=1645023810',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		partnerName: "Patrick O'Shaughnessy",
		preFooterSettings: {
			title: "Patrick O'Shaughnessy Approved",
			paragraphs: ["Host of Invest Like the Best, CEO of O'Shaughnessy Asset Management, and partner at early-stage venture firm Positive Sum"],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/patrick_footer.jpg?v=1645023810',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/patrick_email_cap.png?v=1645023810',
			imageAlt: "Portrait of Patrick O'Shaughnessy",
			subtitle: `Patrick O'Shaughnessy Sale`,
			description: "Patrick O'Shaughnessy swears by the Pod to sleep at the perfect temperature.",
		},
	},

	carnivoremd: {
		utm_source: 'podcast',
		utm_medium: 'paul',
		promoCode: 'carnivoremd',
		promoBar: {
			'*': {
				message: 'Paul Saladino Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Paul Saladino Sale',
		savingsTag: 'Paul Saladino Sale: Up to %%% off',
		heroSettings: {
			title: '“To get the best sleep, our bodies must cool down. Most mattresses cause us to get hotter, preventing deep sleep. How cool is it that Eight Sleep has figured out a way to fix this!”',
			description: ['— Paul Saladino', 'Host of Fundamental Health Podcast, Author of Carnivore Code'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/carnivoremd_hero.jpg?v=1645028038',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/carnivoremd_hero.jpg?v=1645028038',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/carnivoremd_hero.jpg?v=1645028038',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Paul Saladino Approved',
			paragraphs: ['Host of Fundamental Health Podcast, Author of "Carnivore Code", leading authority on the science of the carnivore diet appeared on The Doctors TV show.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/carnivoremd_footer.jpg?v=1645028038',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/carnivoremd_email_cap.png?v=1645028038',
			imageAlt: 'Portrait of Paul Saladino',
			subtitle: 'Paul Saladino Sale',
			description: 'Paul Saladino swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Paul Saladino',
	},

	neuro: {
		utm_source: 'podcast',
		utm_medium: 'louisa',
		promoCode: 'neuro',
		promoBar: {
			'*': {
				message: 'Louisa Nicola Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Louisa Nicola Sale',
		savingsTag: 'Louisa Nicola Sale: Up to %%% off',
		heroSettings: {
			title: "“With the Pod I'm now able to control my temperature for good quality sleep.”",
			description: ['Louisa Nicola', 'Neurophysiologist and Human Performance Coach'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/neuro-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/neuro-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/neuro-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Louisa Nicola Approved',
			paragraphs: ['Neurophysiologist and Human Performance Coach. She founded Neuro Athletics in 2014- a full-service sports neurology agency for NBA and NFL players.'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/neuro-main-t1.jpg',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/neuro-main-t1.jpg',
			imageAlt: 'Portrait of Louisa Nicola',
			subtitle: 'Louisa Nicola Sale',
			description: 'Louisa Nicola swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Louisa Nicola',
	},

	sean: {
		utm_source: 'podcast',
		utm_medium: 'seandelaney',
		promoCode: 'seandelaney',
		promoBar: {
			'*': {
				message: 'Sean DeLaney Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sean DeLaney Sale',
		savingsTag: 'Sean DeLaney Sale: Up to %%% off',
		heroSettings: {
			title: '“The Eight Sleep Pod has been a game-changer in the quality and the amount of sleep I get. By allowing me to control the temperature I fall asleep faster with fewer interruptions.”',
			description: [
				'— Sean DeLaney',
				'Host of What Got You There Podcast , Founder & CEO of Culture Finders Inc. , Co-Founder of NuSkool Snacks , Angel Investor, & Former Professional Lacrosse Player.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sean_hero.jpg?v=1645028346',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sean_hero.jpg?v=1645028346',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sean_hero.jpg?v=1645028346',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sean DeLaney Approved',
			paragraphs: [
				'Host of the Sean DeLaney Show, former Director of Human Optimization at Onnit, former professional mixed martial artist, competed for King of the Cage, cast member of The Ultimate Fighter.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sean_footer.jpg?v=1645028346',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/sean_email_cap.png?v=1645028345',
			imageAlt: 'Portrait of Sean DeLaney',
			subtitle: 'Sean DeLaney Sale',
			description: 'Sean DeLaney swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Sean DeLaney',
	},

	kkp: {
		utm_source: 'podcast',
		utm_medium: 'kylekingsbury',
		promoCode: 'kkp',
		promoBar: {
			'*': {
				message: 'Kyle Kingsbury Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Kyle Kingsbury Sale',
		savingsTag: 'Kyle Kingsbury Sale: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep is the coolest technology on the planet. I am cooler at night resulting in deeper better sleep and the bed has virtually eliminated my hip pain.”',
			description: [
				'— Kyle Kingsbury',
				'Host of the Kyle Kingsbury Show, former Director of Human Optimization at Onnit, former professional mixed martial artist, competed for King of the Cage, cast member of The Ultimate Fighter',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/kkp_hero.jpg?v=1645028451',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/kkp_hero.jpg?v=1645028451',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/kkp_hero.jpg?v=1645028451',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Kyle Kingsbury Approved',
			paragraphs: [
				'Host of the Kyle Kingsbury Show, former Director of Human Optimization at Onnit, former professional mixed martial artist, competed for King of the Cage, cast member of The Ultimate Fighter.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/kkp_footer.jpg?v=1645028451',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/kkp_email_cap.png?v=1645028451',
			imageAlt: 'Portrait of Kyle Kingsbury',
			subtitle: 'Kyle Kingsbury Sale',
			description: 'Kyle Kingsbury swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Kyle Kingsbury',
	},

	'20minvc': {
		utm_source: 'podcast',
		utm_medium: '20minvc',
		promoCode: '20minvc',
		promoBar: {
			'*': {
				message: 'Harry Stebbings Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Harry Stebbings Sale',
		savingsTag: 'Harry Stebbings Sale: Up to %%% off',
		heroSettings: {
			title: '“The Pod has been a game-changer for my sleep. The temperature control is incredible.”',
			description: [
				'— Harry Stebbings',
				'Harry Stebbings is the Founder and Managing Partner of 20VC and host of The Twenty Minute VC Podcast, with over 100m downloads and listeners in 114 countries.',
			],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/20minVC_header.jpg?v=1645028565',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/20minVC_header.jpg?v=1645028565',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/20minVC_header.jpg?v=1645028565',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Harry Stebbings Approved',
			paragraphs: ['Founder and Managing Partner of 20VC and host of The Twenty Minute VC Podcast, with over 100m downloads and listeners in 114 countries.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/20minVC_footer.jpg?v=1645028565',
					mediaQuery: '(min-width: 0px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/20minVC_email_cap.png?v=1645028564',
			imageAlt: 'Portrait of Harry Stebbings',
			subtitle: 'Harry Stebbings Sale',
			description: 'Harry Stebbings swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Harry Stebbings',
	},

	// very specific standalone pages

	wza: {
		utm_source: 'partnership',
		utm_medium: 'wza',
		page: '/wza',
		promoCode: 'wza',
		promoBar: {
			'*': {
				message: 'Wodapalooza Exclusive - ### off the Pod',
				link: null,
			},
		},
		saleName: 'Wodapalooza Exclusive',
		heroSettings: {
			title: 'Sleep smarter this year',
		},
		savingsTag: 'Wodapalooza Exclusive: Up to %%% off',
		emailCapSettings: {
			imageSrc: 'https://eightsleep.imgix.net/justin_mobile.png?v=1641764252',
			imageAlt: 'Portrait of Justin Medeiros',
			title: 'Recover like Justin',
			description: 'Top performers swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Top performers',
	},

	'barrys-members': {
		utm_source: 'podcast',
		utm_medium: 'barrys',
		promoCode: 'barrys',
		page: '/product/pod-cover',
		promoBar: {
			'*': {
				message: 'Barrys Sale: Up to ### off the Pod  ',
				link: null,
			},
		},
		saleName: 'Barrys Sale',
		heroSettings: {
			title: 'Sleep smarter this year',
		},
		savingsTag: 'Barrys Sale: Up to %%% off',
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: '',
			imageAlt: '',
		},
		partnerName: 'Barrys',
	},

	twit: {
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'twit',
		promoCode: 'twit',
		promoBar: {
			'*': {
				message: 'TWiT Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'TWiT Sale',
		savingsTag: 'TWiT Sale: Up to %%% off',
		emailCapSettings: {
			title: 'Unlock up to %%% off',
			imageSrc: 'https://eightsleep.imgix.net/Leo_Photo_direct.jpg?v=1646418647',
			imageAlt: 'Portrait of Leo Laporte',
			subtitle: 'TWiT Exclusive',
			description: 'Leo Laporte swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Leo Laporte',
		heroSettings: {
			title: `"I'd heard about Eight Sleep from friends, but until I tried the Pod I had no idea what a difference it could make."`,
			description: ['Leo Laporte', 'Host of This Week In Tech'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/Leo_Photo_direct.jpg?v=1646418647',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Leo_Photo_direct.jpg?v=1646418647',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Leo_Photo_direct.jpg?v=1646418647',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Leo Laporte Approved',
			paragraphs: ['Leo swears by the Pod to get better sleep. Learn more for yourself.'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/leo_dark.jpg?v=1646427363',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/leo_dark.jpg?v=1646427363',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/leo_dark.jpg?v=1646427363',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
	},

	ios: {
		utm_source: 'podcast',
		utm_medium: 'ios',
		promoCode: 'ios',
		promoBar: {
			'*': {
				message: 'Mikah Sargent Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mikah Sargent Sale',
		savingsTag: 'Mikah Sargent Sale: Up to %%% off',
		heroSettings: {
			title: '“I am a HUGE sleep science nerd, so being able to track and improve my sleep fitness with the Pod is like a dream come true (pun intended).”',
			description: ['Mikah Sargent', 'Host of IOS Today'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mikah_sarget_small_e209a43f-e712-419b-998a-abbcf4156774.jpg?v=1648053921',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mikah_sarget_small_e209a43f-e712-419b-998a-abbcf4156774.jpg?v=1648053921',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hero_movie_bg_09972856-f5e6-4338-b28d-38464684efe8.png?v=1648054249',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mikah Sargent Approved',
			paragraphs: ['Mikah swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mikah_sargent_bottom.jpg?v=1648053921',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mikah_sargent_bottom.jpg?v=1648053921',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mikah_sargent_bottom_big.jpg?v=1648054380',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'IOS Today Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/mikah_sarget_small_e209a43f-e712-419b-998a-abbcf4156774.jpg?v=1648053921',
			imageAlt: 'Portrait of Mikah Sargent',
			description: 'Mikah Sargent swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Mikah Sargent',
		klaviyoProperties: {
			title: 'I am a HUGE sleep science nerd, so being able to track and improve my sleep fitness with the Pod is like a dream come true (pun intended).',
			image: 'https://eightsleep.imgix.net/hero_movie_bg_09972856-f5e6-4338-b28d-38464684efe8.png?v=1648054249',
			name: 'Mikah Sargent',
			discount: '%%%',
			link: 'https://www.eightsleep.com/ios',
			mattressDiscount: '%%%',
		},
	},

	ealshow: {
		utm_source: 'podcast',
		utm_medium: 'eal',
		promoCode: 'ealshow',
		promoBar: {
			'*': {
				message: 'Erik Anders Lang Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Erik Anders Lang Sale',
		savingsTag: 'Erik Anders Lang Sale: Up to %%% off',
		heroSettings: {
			title: "“As a constant traveler, nothing makes me happier than coming home to the Pod. I lay down and next thing I know it's the next morning and I've never been more refreshed.”",
			description: ['Erik Anders Lang', 'Co-founder/CEO of Random Golf Club and host of the Erik Anders Lang Show'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/erik_anders_hero_small_ca76766f-3b66-4488-96ca-ea52b6f7a012.jpg?v=1648541202',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/erik_anders_hero_small_ca76766f-3b66-4488-96ca-ea52b6f7a012.jpg?v=1648541202',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/erik_anders_hero_big_a3512845-529c-4fce-95ee-b34da967285b.jpg?v=1648541984',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Erik Anders Lang Approved',
			paragraphs: ['Erik swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/erik_anders_bottom_mobile.jpg?v=1648541202',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/erik_anders_bottom_mobile.jpg?v=1648541202',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/erik_anders_bottom_desktop.jpg?v=1648541202',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Erik Anders Lang Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/erik_anders_hero_small_ca76766f-3b66-4488-96ca-ea52b6f7a012.jpg?v=1648541202',
			imageAlt: 'Portrait of Erik Anders',
			description: 'Erik swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Erik',
		klaviyoProperties: {
			title: "As a constant traveler, nothing makes me happier than coming home to the Pod. I lay down and next thing I know it's the next morning and I've never been more refreshed.",
			image: 'https://eightsleep.imgix.net/erik_anders_hero_small_ca76766f-3b66-4488-96ca-ea52b6f7a012.jpg?v=1648541202',
			name: 'Erik Anders',
			discount: '%%%',
			link: 'https://www.eightsleep.com/ealshow',
			mattressDiscount: '%%%',
		},
	},

	deep: {
		utm_source: 'podcast',
		utm_medium: 'deep',
		promoCode: 'deep',
		promoBar: {
			'*': {
				message: 'Cal Newport Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Cal Newport Sale',
		savingsTag: 'Cal Newport Sale: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep is one of my productivity secrets. A properly cooled mattress helps me get the deep sleep I need to produce deep work.”',
			description: ['Cal Newport', 'New York Times bestselling author and host of the Deep Questions Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/cal_newport_hero_small_0989b921-e28e-4f23-8832-9bda6709d2d3.jpg?v=1648542105',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/cal_newport_hero_small_0989b921-e28e-4f23-8832-9bda6709d2d3.jpg?v=1648542105',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/cal_newport_hero_big.jpg?v=1648541984',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Cal Newport Approved',
			paragraphs: ['Cal Newport swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/cal_newport_bottom_mobile.jpg?v=1648541984',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/cal_newport_bottom_mobile.jpg?v=1648541984',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/cal_newport_bottom_desktop.jpg?v=1648542105',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Cal Newport Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/cal_newport_hero_small_0989b921-e28e-4f23-8832-9bda6709d2d3.jpg?v=1648542105',
			imageAlt: 'Portrait of Cal Newport',
			description: 'Cal Newport swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Cal Newport',
		klaviyoProperties: {
			title: 'Eight Sleep is one of my productivity secrets. A properly cooled mattress helps me get the deep sleep I need to produce deep work.',
			image: 'https://eightsleep.imgix.net/cal_newport_hero_small_0989b921-e28e-4f23-8832-9bda6709d2d3.jpg?v=1648542105',
			name: 'Cal Newport',
			discount: '%%%',
			link: 'https://www.eightsleep.com/deep',
			mattressDiscount: '%%%',
		},
	},

	darkhorse: {
		utm_source: 'podcast',
		utm_medium: 'darkhorse',
		promoCode: 'darkhorse',
		promoBar: {
			'*': {
				message: 'DarkHorse Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'DarkHorse Sale',
		savingsTag: 'DarkHorse Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“The EightSleep Pod is something of a revelation. Getting into a bed that is already at your desired temperature allows for an easier, quicker transition to sleep. Having a cool room and a warm bed is a luxury that EightSleep makes easy to obtain”',
			description: ['Bret Weinsten and Heather Heying', 'Biologists and co-hosts of the DarkHorse Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dark_horse_hero_small_99134ade-b4a4-4222-bca1-04e1dde635e1.jpg?v=1648627772',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dark_horse_hero_small_99134ade-b4a4-4222-bca1-04e1dde635e1.jpg?v=1648627772',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dark_horse_hero_big.jpg?v=1648627772',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'DarkHorse Approved',
			paragraphs: ['Bret and Heather swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dark_horse_bottom_mobile.jpg?v=1648627772',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dark_horse_bottom_mobile.jpg?v=1648627772',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dark_horse_bottom_desktop.jpg?v=1648627772',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'DarkHorse Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/dark_horse_hero_small_99134ade-b4a4-4222-bca1-04e1dde635e1.jpg?v=1648627772',
			imageAlt: 'Portrait of Bret and Heather',
			description: 'Bret and Heather swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Bret and Heather',
		klaviyoProperties: {
			title:
				'The EightSleep Pod is something of a revelation. Getting into a bed that is already at your desired temperature allows for an easier, quicker transition to sleep. Having a cool room and a warm bed is a luxury that EightSleep makes easy to obtain',
			image: 'https://eightsleep.imgix.net/dark_horse_hero_big.jpg?v=1648627772',
			name: 'DarkHorse',
			discount: '%%%',
			mattressDiscount: '%%%',
			link: 'https://www.eightsleep.com/darkhorse',
		},
	},

	shrugged: {
		utm_source: 'podcast',
		utm_medium: 'barbellshrugged',
		promoCode: 'shrugged',
		promoBar: {
			'*': {
				message: 'Barbell Shrugged Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Barbell Shrugged Sale',
		savingsTag: 'Barbell Shrugged Sale: Up to %%% off',
		heroSettings: {
			title: '“I choose Eight Sleep because their science, technology, and tracking guarantees that when my head hits the pillow, I am only 8 hours away from waking up ready”',
			description: ['Anders Varner', 'Co-host of Barbell Shrugged'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_hero_small_3b13ce0d-f7c6-4ccc-a4e3-f0703a3abc67.jpg?v=1650632466',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_hero_large_2ad667ec-10b0-47ab-b877-0c8d98c3bd50.jpg?v=1650632467',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_hero_large_2ad667ec-10b0-47ab-b877-0c8d98c3bd50.jpg?v=1650632467',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Barbell Shrugged Approved',
			paragraphs: ['The hosts of Barbell Shrugged swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_footer_small_4a0f6b47-2494-4f68-8f21-a068ff1d8409.jpg?v=1650632466',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_footer_large_7c9f1d55-f49b-473a-8b08-f5fd451e12ba.jpg?v=1650632466',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/barbellshrugged_footer_large_7c9f1d55-f49b-473a-8b08-f5fd451e12ba.jpg?v=1650632466',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Barbell Shrugged Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/barbellshrugged_hero_small_3b13ce0d-f7c6-4ccc-a4e3-f0703a3abc67.jpg?v=1650632466',
			imageAlt: 'Portrait of Anders Varner',
			description: 'The hosts of Barbell Shrugged swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'the hosts of Barbell Shrugged',
	},

	richdad: {
		utm_source: 'podcast',
		utm_medium: 'richdad',
		promoCode: 'richdad',
		promoBar: {
			'*': {
				message: 'Rich Dad Radio Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Rich Dad Radio Sale',
		savingsTag: 'Rich Dad Radio Sale: Up to %%% off',
		heroSettings: {
			title: 'Sleep cool. All night long',
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		emailCapSettings: {
			title: 'Rich Dad Radio Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'Rich Dad Radio swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Rich Dad Radio',
	},

	impact: {
		utm_source: 'podcast',
		utm_medium: 'impact',
		promoCode: 'impact',
		promoBar: {
			'*': {
				message: 'Impact Theory Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Impact Theory Sale',
		savingsTag: 'Impact Theory Sale: Up to %%% off',
		heroSettings: {
			title:
				'“One of our Impact Theory team members has been trying to improve his sleep, and this came at the perfect time. And now he can fall asleep faster than ever before with the Eight Sleep Pod.”',
			description: ['Tom Bilyeu', 'Host of Impact Theory'],
			heading: 'Impact Theory approves the Pod',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/impact_hero_small_0fa1e151-ac52-4d40-a219-bb0ce5915963.jpg?v=1651046000',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/impact_hero_small_0fa1e151-ac52-4d40-a219-bb0ce5915963.jpg?v=1651046000',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/impact_hero_big.jpg?v=1651046000',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Impact Theory Approved',
			paragraphs: ['The team at Impact Theory swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/impact_footer_small_6c1e5994-9d63-4686-ac64-c1a381456a38.jpg?v=1651046000',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/impact_footer_big.jpg?v=1651046000',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/impact_footer_big.jpg?v=1651046000',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Impact Theory Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/impact_hero_small_0fa1e151-ac52-4d40-a219-bb0ce5915963.jpg?v=1651046000',
			imageAlt: 'Portrait of Impact Theory team',
			description: 'The team at Impact Theory swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'The team at Impact Theory',
	},

	insideline: {
		utm_source: 'podcast',
		utm_medium: 'insideline',
		promoCode: 'insideline',
		promoBar: {
			'*': {
				message: 'Inside Line Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Inside Line Sale',
		savingsTag: 'Inside Line Sale: Up to %%% off',
		heroSettings: {
			title:
				'“I’m an early riser - I love my mornings; and they’ve only gotten better since I started to use my Eight Sleep Pod. I sleep better, but more than that, I wake up fresher and with peak energy. And the best part is that Mercedes, the reigning Formula 1 World Champion team, uses the same technology!”',
			description: ['Kunal Shah', 'Co-host of Inside Line'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/insideline_hero_small_cbb5726e-49c4-42b7-8bcc-0d3ab5673f5e.jpg?v=1651555302',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/insideline_hero_small_cbb5726e-49c4-42b7-8bcc-0d3ab5673f5e.jpg?v=1651555302',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/insideline_hero_big.jpg?v=1651555302',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Inside Line Approved',
			paragraphs: ['The hosts of Inside Line swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			footerClass: 'minh-730',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/insideline_footer_small_f8658353-6ec6-4a22-946a-35977faa89c6.jpg?v=1651555302',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/insideline_footer_big_ffc1d698-85d0-4330-a113-315bcc52434d.jpg?v=1651586473',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/insideline_footer_big_ffc1d698-85d0-4330-a113-315bcc52434d.jpg?v=1651586473',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Inside Line Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/insideline_hero_small_cbb5726e-49c4-42b7-8bcc-0d3ab5673f5e.jpg?v=1651555302',
			imageAlt: 'Portrait of Impact Theory team',
			description: 'The hosts of Inside Line swear by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'the hosts of Inside Line',
	},

	huberman: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/huberman-crop-final.png',
		holiday_promo: true,
		utm_source: 'podcast',
		utm_medium: 'huberman',
		promoCode: 'huberman',
		promoBar: {
			'*': {
				message: 'Huberman Lab Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Huberman Lab Exclusive Sale',
		savingsTag: 'Huberman Lab Exclusive: Up to %%% off',
		heroSettings: {
			title:
				"“Put simply, our body temperature needs to drop 1-3° to get into sleep, and temperature increases in the body trigger awakening. By using Eight Sleep, I'm best able to experience a complete, deep and full night's sleep without waking up in the middle of the night.”",
			description: ['Andrew Huberman, Ph.D.', 'Neuroscientist and host of Huberman Lab'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_large_1204a9c4-8112-4d0d-b2c6-a0929acdaf98.jpg?v=1651826795',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Huberman Lab Approved',
			paragraphs: ['Andrew Huberman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/huberman_mobile_footer.jpg?v=1661262761',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_desktop_footer.jpg?v=1661262760',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_desktop_footer.jpg?v=1661262760',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Huberman Lab Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
			imageAlt: 'Portrait of Andrew Huberman',
			description: 'Andrew Huberman swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Andrew Huberman',
	},

	brainbody: {
		utm_source: 'podcast',
		utm_medium: 'brainbody',
		promoCode: 'brainbody',
		promoBar: {
			'*': {
				message: 'Huberman Lab Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Huberman Lab Sale',
		savingsTag: 'Huberman Lab Sale: Up to %%% off',
		heroSettings: {
			title:
				"“Put simply, our body temperature needs to drop 1-3° to get into sleep, and temperature increases in the body trigger awakening. By using Eight Sleep, I'm best able to experience a complete, deep and full night's sleep without waking up in the middle of the night.”",
			description: ['Andrew Huberman, Ph.D.', 'Neuroscientist and host of Huberman Lab'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_hero_large_1204a9c4-8112-4d0d-b2c6-a0929acdaf98.jpg?v=1651826795',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Huberman Lab Approved',
			paragraphs: ['Andrew Huberman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/huberman_mobile_footer.jpg?v=1661262761',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_desktop_footer.jpg?v=1661262760',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/huberman_desktop_footer.jpg?v=1661262760',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Brain Body Contract Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/huberman_hero_small_655d8fd0-f54d-4980-97e7-665ffc5fd743.jpg?v=1651826795',
			imageAlt: 'Portrait of Andrew Huberman',
			description: 'Andrew Huberman swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Andrew Huberman',
	},

	modernwisdom: {
		utm_source: 'podcast',
		utm_medium: 'modernwisdom',
		promoCode: 'modernwisdom',
		promoBar: {
			'*': {
				message: 'Modern Wisdom Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Modern Wisdom Sale',
		savingsTag: 'Modern Wisdom Sale: Up to %%% off',
		heroSettings: {
			title: '“Sleep is the ultimate performance enhancer and Eight Sleep makes the perfect night much easier.”',
			description: ['Chris Williamson', 'Host of Modern Wisdom'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Modern Wisdom Approved',
			paragraphs: ['Chris Williamson swears by the Pod to get better sleep. Learn more for yourself'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Modern Wisdom Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/modern-wisdom-t1-2024-main-new.jpg',
			imageAlt: 'Portrait of Chris Williamson',
			description: 'Chris Williamson swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Chris Williamson',
	},

	formulanerds: {
		utm_source: 'podcast',
		utm_medium: 'formulanerds',
		promoCode: 'formulanerds',
		promoBar: {
			'*': {
				message: 'FormulaNerds Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'FormulaNerds Sale',
		savingsTag: 'FormulaNerds Sale: Up to %%% off',
		heroSettings: {
			title: '“As a business owner I have always struggled to get a great night of sleep with thoughts running around in my head, tossing and turning, until I discovered Eight Sleep’s Pod.”',
			description: ['Oli Whitney', 'Host of Cut to the Race'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/formulanerds_hero_small_e913770f-db3d-4626-8079-b9df5cee95fe.jpg?v=1654091182',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/formulanerds_hero_small_e913770f-db3d-4626-8079-b9df5cee95fe.jpg?v=1654091182',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/formulanerds_hero_big.jpg?v=1654091182',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'FormulaNerds Approved',
			paragraphs: ['Oli Whitney swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/formulanerds_footer_small_e03e0141-ef1f-4f30-96ce-f906a1ada8e1.jpg?v=1654091182',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/formulanerds_footer_big.jpg?v=1654091182',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/formulanerds_footer_big.jpg?v=1654091182',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'FormulaNerds Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/formulanerds_hero_small_e913770f-db3d-4626-8079-b9df5cee95fe.jpg?v=1654091182',
			imageAlt: 'Portrait of Oli Whitney',
			description: 'Oli Whitney swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Oli Whitney',
	},

	bry: {
		utm_source: 'podcast',
		utm_medium: 'bry',
		promoCode: 'bry',
		promoBar: {
			'*': {
				message: 'Behind the Brand Exclusive Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Behind the Brand Exclusive Sale',
		savingsTag: 'Behind the Brand Exclusive Sale: Up to %%% off',
		heroSettings: {
			title:
				'“For me, the three pillars of health are: Sleep. Nutrition. Exercise. Sleep, especially on a thermo-regulated bed at the right temperature customized for me, has been the key to feeling my best and performing at a high level. The Eight Sleep Pro Pod is a game-changer!”',
			description: ['Bryan Elliott', 'Writer, director, producer, and host of Behind the Brand'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_hero_small_86f4b8f5-d0b3-4659-9ef4-738dfdb0d701.jpg?v=1655277896',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_hero_small_86f4b8f5-d0b3-4659-9ef4-738dfdb0d701.jpg?v=1655277896',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_hero_large_3141d4dc-dc02-452f-9933-c42d585f78b9.jpg?v=1655277896',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Bryan Elliott Approved',
			paragraphs: ['Bryan Elliott swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_footer_small_adbc82f6-54a2-400d-a2dd-578ca962b600.jpg?v=1655277896',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_footer_large_0f7f8c70-c4bb-4cf9-aed7-25dabc1c77a1.jpg?v=1655277896',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/behind_the_brand_footer_large_0f7f8c70-c4bb-4cf9-aed7-25dabc1c77a1.jpg?v=1655277896',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Behind the Brand Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/behind_the_brand_hero_small_86f4b8f5-d0b3-4659-9ef4-738dfdb0d701.jpg?v=1655277896',
			imageAlt: 'Portrait of Bryan Elliott',
			description: 'Bryan Elliott swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Bryan Elliott',
	},

	boxing: {
		utm_source: 'podcast',
		utm_medium: 'boxing',
		promoCode: 'boxing',
		promoBar: {
			'*': {
				message: 'Tony Jeffries Exclusive Sale: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Tony Jeffries Exclusive Sale',
		savingsTag: 'Tony Jeffries Exclusive Sale: Up to %%% off',
		heroSettings: {
			title: '“I wish I had a Pod when I was competing in the Olympics. Maybe then I would’ve won a gold medal rather than bronze. That’s how much I believe in Eight Sleep”',
			description: ['Tony Jeffries', 'Professional English boxer'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/boxing_hero_small_2c5d16c7-3f52-43cf-9cf5-3c549ea52d92.jpg?v=1655477703',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/boxing_hero_small_2c5d16c7-3f52-43cf-9cf5-3c549ea52d92.jpg?v=1655477703',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/boxing_hero_large_946276a1-8457-459b-84d1-d76f10ea2675.jpg?v=1655477703',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Tony Jeffries Approved',
			paragraphs: ['Tony Jeffries swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/boxing_footer_small_9e2d7d82-855d-49cf-b2bd-7cfe4dc7a2d7.jpg?v=1655477564',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/boxing_footer_large_52bff6bb-4ef5-48db-a386-8b5047226035.jpg?v=1655477564',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/boxing_footer_large_52bff6bb-4ef5-48db-a386-8b5047226035.jpg?v=1655477564',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Tony Jeffries Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/boxing_hero_small_2c5d16c7-3f52-43cf-9cf5-3c549ea52d92.jpg?v=1655477703',
			imageAlt: 'Portrait of Tony Jeffries',
			description: 'Tony Jeffries swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Tony Jeffries',
	},

	techdaily: {
		utm_source: 'podcast',
		utm_medium: 'techdaily',
		promoCode: 'techdaily',
		promoBar: {
			'*': {
				message: 'TechDaily Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'TechDaily Exclusive Sale',
		savingsTag: 'TechDaily Exclusive: Up to %%% off',
		heroSettings: {
			title: "“Eight Sleep's Pod keeps me cool all night long, improves my sleep and limits the A/C use which makes my wallet very happy!”",
			description: ['Wade Bennett', 'Host of TechDaily'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/techdaily_hero_small_560addaf-abee-475a-b5b8-6d80b7b04fd3.jpg?v=1658216161',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/techdaily_hero_small_560addaf-abee-475a-b5b8-6d80b7b04fd3.jpg?v=1658216161',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/techdaily_hero_large_331f5cab-985f-4fdd-9869-744882c16616.jpg?v=1658216161',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'TechDaily Approved',
			paragraphs: ['Wade swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/techdaily_footer_small_87e99eae-3a35-4e0f-abd5-2710918519ca.jpg?v=1658216161',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/techdaily_footer_large_13689fb2-bc2f-4e8e-b31b-6424e577b4d2.jpg?v=1658216161',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/techdaily_footer_large_13689fb2-bc2f-4e8e-b31b-6424e577b4d2.jpg?v=1658216161',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'TechDaily Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/techdaily_hero_small_560addaf-abee-475a-b5b8-6d80b7b04fd3.jpg?v=1658216161',
			imageAlt: 'Portrait of Wade Bennett',
			description: 'Wade swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Wade',
	},

	coindesk: {
		utm_source: 'podcast',
		utm_medium: 'coindesk',
		promoCode: 'coindesk',
		promoBar: {
			'*': {
				message: 'CoinDesk Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'CoinDesk Sale',
		savingsTag: 'CoinDesk Exclusive: Up to %%% off',
		heroSettings: {
			title: 'Sleep cool. All night long.',
			description: ['Upgrade your sleep with the Pod and receive %%% off during our Sale'],
		},
		emailCapSettings: {
			title: 'CoinDesk Sale',
			imageSrc: 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			imageAlt: '',
			description: 'CoinDesk swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'CoinDesk',
		preFooterSettings: {
			title: 'CoinDesk Approved',
			paragraphs: ['CoinDesk swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			sources: [
				{
					src: 'https://eightsleep.imgix.net/coindesk_footer_small_c97abeef-29d5-49cc-a2f5-7cb6ec903cd0.jpg?v=1656450190',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coindesk_footer_large_94a77a8e-9d42-444d-963a-01ddd24840b7.jpg?v=1656450190',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coindesk_footer_large_94a77a8e-9d42-444d-963a-01ddd24840b7.jpg?v=1656450190',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
	},

	coiski: {
		utm_source: 'podcast',
		utm_medium: 'coiski',
		promoCode: 'coiski',
		promoBar: {
			'*': {
				message: 'Sports Dissected Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sports Dissected Exclusive Sale',
		savingsTag: 'Sports Dissected Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“I absolutely love the Pod mattress! The five-layer premium foam mattress is super pillowy, and it supports me in all of the right places. And the fact that I can adjust the cooling and heating through the app makes it really convenient.”',
			description: ['George Kiel', 'Brand Director at Sports Dissected'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/coiski_hero_small_9b7bcd97-c82c-4622-9690-4a82b7f03541.jpg?v=1658384943',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coiski_hero_small_9b7bcd97-c82c-4622-9690-4a82b7f03541.jpg?v=1658384943',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coiski_hero_large_17495c65-aae4-4e65-a372-6f16d3e94534.jpg?v=1658384943',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sports Dissected Approved',
			paragraphs: ['George Kiel swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/coiski_footer_small_deb90f41-d94b-4d3e-a886-8596b6a4cc01.jpg?v=1658384943',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coiski_footer_large_a3e35183-dbbe-4fdc-a194-f0cd3eccece0.jpg?v=1658384943',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/coiski_footer_large_a3e35183-dbbe-4fdc-a194-f0cd3eccece0.jpg?v=1658384943',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Sports Dissected Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/coiski_hero_small_9b7bcd97-c82c-4622-9690-4a82b7f03541.jpg?v=1658384943',
			imageAlt: 'Portrait of George Kiel',
			description: 'George Kiel swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'George Kiel',
	},

	twobit: {
		utm_source: 'podcast',
		utm_medium: 'twobit',
		promoCode: 'twobit',
		promoBar: {
			'*': {
				message: 'Two Bit Da Vinci Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Two Bit Da Vinci Exclusive Sale',
		savingsTag: 'Two Bit Da Vinci Exclusive: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep’s innovative Pod brings science to sleep. With water cooling built in, I toss and turn way less and have truly amazing sleep.”',
			description: ['Ricky Roy', 'Host of Two Bit Da Vinci'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/twobit_hero_small_4c47d7e2-9aa5-4b0d-9926-7affb9dfd6b1.jpg?v=1658732335',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twobit_hero_small_4c47d7e2-9aa5-4b0d-9926-7affb9dfd6b1.jpg?v=1658732335',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twobit_hero_large_e5e64b38-1b2a-4786-8e13-d11f3ac9ec70.jpg?v=1658732335',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Two Bit Da Vinci Approved',
			paragraphs: ['Ricky swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/twobit_footer_small_88b4bc9e-a476-4b7e-8645-38f3d276a1bc.jpg?v=1658732335',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twobit_footer_large_b163c645-6d5f-4a3d-ab48-7d0bc50f8454.jpg?v=1658732335',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/twobit_footer_large_b163c645-6d5f-4a3d-ab48-7d0bc50f8454.jpg?v=1658732335',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Two Bit Da Vinci Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/twobit_hero_small_4c47d7e2-9aa5-4b0d-9926-7affb9dfd6b1.jpg?v=1658732335',
			imageAlt: 'Portrait of Ricky Roy',
			description: 'Ricky swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Ricky',
	},

	sound: {
		utm_source: 'podcast',
		utm_medium: 'sound',
		promoCode: 'sound',
		promoBar: {
			'*': {
				message: '12 Hour Sound Machines Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: '12 Hour Sound Machines Exclusive Sale',
		savingsTag: '12 Hour Sound Machines Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“Eight Sleep has created the missing element to the perfect formula for sleep. Sound Machines and temperature regulation working together are an unbeatable combination, and have given me the best sleep of my life.”',
			description: ['Bradon Reed ', 'Host of 12 Hour Sound Machines'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/12_hour_header_small_eb77a2e8-de15-4048-98b4-197bb1a9c100.jpg?v=1663061203',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/12_hour_header_small_eb77a2e8-de15-4048-98b4-197bb1a9c100.jpg?v=1663061203',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/12_hour_header_big.jpg?v=1663061203',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: '12 Hour Sound Machines Approved',
			paragraphs: ['Brandon swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/12_hour_footer_small_bcf26d3d-8375-4f60-9c09-1a0203520a99.jpg?v=1663061672',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/12_hour_footer_big.jpg?v=1663061667',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/12_hour_footer_big.jpg?v=1663061667',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: '12 Hour Sound Machines Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/12_hour_header_small_eb77a2e8-de15-4048-98b4-197bb1a9c100.jpg?v=1663061203',
			imageAlt: 'Portrait of Bradon Reed ',
			description: 'Brandon swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Brandon',
	},

	hurdle: {
		utm_source: 'podcast',
		utm_medium: 'hurdle',
		promoCode: 'hurdle',
		promoBar: {
			'*': {
				message: 'Hurdle Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Hurdle Exclusive Sale',
		savingsTag: 'Hurdle Exclusive: Up to %%% off',
		heroSettings: {
			title: "“With Eight Sleep, I feel like this is the most restful sleep I've gotten in my life and I've also seen improvements in my HRV, sleep duration, and overall sleep fitness benchmarks.”",
			description: ['Emily Abbate', 'Host of Hurdle'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hurdle_hero_small_18f6ec45-e6d0-444a-a2c0-fb3774adc337.jpg?v=1664961276',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hurdle_hero_small_18f6ec45-e6d0-444a-a2c0-fb3774adc337.jpg?v=1664961276',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hurdle_hero_big.jpg?v=1664961279',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Emily Abbate Approved',
			paragraphs: ['Emily swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hurdle_footer_small_07ae27f4-cdc1-4544-a1e9-604d0826ddd3.jpg?v=1664961277',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hurdle_footer_big.jpg?v=1664961278',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hurdle_footer_big.jpg?v=1664961278',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Hurdle Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/hurdle_hero_small_18f6ec45-e6d0-444a-a2c0-fb3774adc337.jpg?v=1664961276',
			imageAlt: 'Portrait of Emily Abbate',
			description: 'Emily Abbate swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Emily Abbate',
	},

	drlyon: {
		utm_source: 'podcast',
		utm_medium: 'drlyon',
		promoCode: 'drlyon',
		promoBar: {
			'*': {
				message: 'Dr. Gabrielle Lyon Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Gabrielle Lyon Exclusive Sale',
		savingsTag: 'Dr. Gabrielle Lyon Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“The Eight Sleep Pod Cover has many amazing features to help optimize your sleep and recovery. One of my favorites is the ability for my husband and me to set our preferred temperatures that allow for restful sleep.”',
			description: ['Dr. Gabrielle Lyon', 'Host of The Dr. Gabrielle Lyon Show'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drlyon_mobile.jpg?v=1672990075',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drlyon_mobile.jpg?v=1672990075',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drlyon_desktop.jpg?v=1672990078',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Gabrielle Lyon Approved',
			paragraphs: ['Dr. Gabrielle Lyon swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drlyon_mobile.jpg?v=1672990075',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drlyon_desktop.jpg?v=1672990078',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drlyon_desktop.jpg?v=1672990078',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dr. Gabrielle Lyon Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/drlyon_mobile.jpg?v=1672990075',
			imageAlt: 'Portrait of Dr. Gabrielle Lyon',
			description: 'Dr. Gabrielle Lyon swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Dr. Gabrielle Lyon',
	},

	max: {
		utm_source: 'podcast',
		utm_medium: 'max',
		promoCode: 'max',
		promoBar: {
			'*': {
				message: 'The Genius Life Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Genius Life Exclusive Sale',
		savingsTag: 'The Genius Life Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“I sleep hot and Eight Sleep has been a game changer for me. Problem solved! I like how customizable and smart it is. The cherry on top is it automatically tracks sleep data without having to wear a cumbersome device.”',
			description: ['Max Lugavere', 'New York Times best-selling author and Host of The Genius Life'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Max Lugavere Approved',
			paragraphs: ['Max Lugavere swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'The Genius Life Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/max_desktop.jpg?v=1672991754',
			imageAlt: 'Portrait of Max Lugavere',
			description: 'Max Lugavere swears by the Pod to sleep at the perfect temperature.',
		},
		partnerName: 'Max Lugavere',
	},
	samiclarke: {
		utm_source: 'ig',
		utm_medium: 'sami_clarke',
		promoCode: 'sami',
		promoBar: {
			'*': {
				message: 'Sami Clarke Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sami Clarke Exclusive Sale',
		savingsTag: 'Sami Clarke Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'When you understand that sleep effects all aspects of your life, you prioritize it. Eight sleep has changed the game for me in the way it optimizes my sleep and allows me to get to sleep quicker, more comfortable, and wake up fully energized!',
			description: ['Sami Clarke', 'Founder of FORM'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/Artboard_1_2x_2.jpg?v=1679639462',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/6B1A8997_3.jpg?v=1679639463',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/6B1A8997_3.jpg?v=1679639463',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sami Clarke Approved',
			paragraphs: ['Sami Clarke swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/Artboard_1_2x_2.jpg?v=1679639462',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/6B1A8997_3.jpg?v=1679639463',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/6B1A8997_3.jpg?v=1679639463',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Sami Clarke Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/Artboard_1_2x_2.jpg?v=1679639462',
			imageAlt: 'Portrait of Sami Clarke',
			description: 'Sami Clarke swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Sami Clarke',
	},

	allthehacks: {
		utm_source: 'podcast',
		utm_medium: 'allthehacks',
		promoCode: 'allthehacks',
		promoBar: {
			'*': {
				message: 'All The Hacks Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'All The Hacks Exclusive Sale',
		savingsTag: 'All The Hacks Exclusive: Up to %%% off',
		heroSettings: {
			title: '“This is one of my favorite life hacks. Ever since I started sleeping with my Eight Sleep, I’ve gotten better, deeper sleep at the perfect temperature.”',
			description: ['Chris Hutchins', 'Host of All The Hacks'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/allthehacks_hero.jpg?v=1682433922',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/allthehacks_hero.jpg?v=1682433922',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/allthehacks_hero.jpg?v=1682433922',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Chris Hutchins Approved',
			paragraphs: ['Chris Hutchins swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/allthehacks_footer.jpg?v=1682433922',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/allthehacks_footer.jpg?v=1682433922',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/allthehacks_footer.jpg?v=1682433922',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Chris Hutchins Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/allthehacks_ecap.jpg?v=1682433919',
			imageAlt: 'Portrait of Chris Hutchins',
			description: 'Chris Hutchins swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Chris Hutchins',
	},

	excellence: {
		utm_source: 'podcast',
		utm_medium: 'excellence',
		promoCode: 'excellence',
		promoBar: {
			'*': {
				message: 'Chasing Excellence Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Chasing Excellence Exclusive Sale',
		savingsTag: 'Chasing Excellence Exclusive: Up to %%% off',
		heroSettings: {
			heading: 'Chasing Excellence approves the Pod',
			title:
				'“We were recently away from home for an extended period of time, and, man, we really missed our EightSleep. When you don’t have it for a little while, you get home and really value what it does to improve your sleep.”',
			description: ['Ben Bergeron & Patrick Cummings', 'Hosts of Chasing Excellence'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Chasing Excellence Approved',
			paragraphs: ['Ben and Patrick swear by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ben Bergeron & Patrick Cummings Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/excellence-new-t1-main.png',
			imageAlt: 'Portrait of Ben Bergeron & Patrick Cummings',
			description: 'Ben Bergeron & Patrick Cummings swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Ben Bergeron & Patrick Cummings',
	},

	jordan: {
		utm_source: 'podcast',
		utm_medium: 'jordan',
		promoCode: 'jordan',
		promoBar: {
			'*': {
				message: 'Jordan Harbinger Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Jordan Harbinger Exclusive Sale',
		savingsTag: 'Jordan Harbinger Exclusive: Up to %%% off',
		heroSettings: {
			title: '“Eight Sleep has been a game changer for my sleep health. It helps regulate my body temperature through the night. One of the best products we own in our home.”',
			description: ['Jordan Harbinger', 'Host of The Jordan Harbinger Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/purpose_header.jpg?v=1682439401',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_header.jpg?v=1682439401',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_header.jpg?v=1682439401',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jordan Harbinger Approved',
			paragraphs: ['Jordan Harbinger swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/purpose_footer_small_f5ff3bf8-bb1e-48f7-aab5-7d4b802f3086.jpg?v=1682439401',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_footer_small_f5ff3bf8-bb1e-48f7-aab5-7d4b802f3086.jpg?v=1682439401',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_footer.jpg?v=1682439401',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Jordan Harbinger Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/purpose_ecap.jpg?v=1682439400',
			imageAlt: 'Portrait of Jordan Harbinger',
			description: 'Jordan Harbinger swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Jordan Harbinger',
	},

	purpose: {
		utm_source: 'podcast',
		utm_medium: 'purpose',
		promoCode: 'purpose',
		promoBar: {
			'*': {
				message: 'Jay Shetty Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Jay Shetty Exclusive Sale',
		savingsTag: 'Jay Shetty Exclusive: Up to %%% off',
		heroSettings: {
			title: '“A good night’s rest is the essence of my well-being. With Eight Sleep I feel confident that I can wake up feeling restored and well rested after sleeping comfortably.”',
			description: ['Jay Shetty', 'Author and host of On Purpose with Jay Shetty'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/purpose_hero_mobile.jpg?v=1683872539',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_hero_desktop.jpg?v=1683872539',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_hero_desktop.jpg?v=1683872539',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jay Shetty Approved',
			paragraphs: ['Jay Shetty swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/purpose_footer_mobile.jpg?v=1683872539',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_footer_mobile.jpg?v=1683872539',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/purpose_footer_desktop.jpg?v=1683872539',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Jay Shetty Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/purpose_email_cap.jpg?v=1683872539',
			imageAlt: 'Portrait of Jay Shetty',
			description: 'Jay Shetty swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Jay Shetty',
	},

	dial: {
		utm_source: 'podcast',
		utm_medium: 'dial',
		promoCode: 'dial',
		promoBar: {
			'*': {
				message: 'Rob Dial Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Rob Dial Exclusive Sale',
		savingsTag: 'Rob Dial Exclusive: Up to %%% off',
		heroSettings: {
			title: '“My Pod Cover keeps me cool through the night and gives me an amazing sleep experience!”',
			description: ['Rob Dial', 'Host of The Mindset Mentor'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dial_header.jpg?v=1682440127',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dial_header.jpg?v=1682440127',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dial_header.jpg?v=1682440127',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Rob Dial Approved',
			paragraphs: ['Rob Dial swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dial_footer_small_1d944cd6-78ef-4013-be37-2873c8cb300f.jpg?v=1682440127',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dial_footer_small_1d944cd6-78ef-4013-be37-2873c8cb300f.jpg?v=1682440127',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dial_footer.jpg?v=1682440127',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Rob Dial Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/dial_ecap.jpg?v=1682440126',
			imageAlt: 'Portrait of Rob Dial',
			description: 'Rob Dial swears by the Pod to get better sleep. Learn more for yourself.',
		},
		partnerName: 'Rob Dial',
	},

	founders: {
		utm_source: 'podcast',
		utm_medium: 'founders',
		promoCode: 'founders',
		promoBar: {
			'*': {
				message: 'Founders Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Founders Exclusive Sale',
		savingsTag: 'Founders Exclusive: Up to %%% off',
		heroSettings: {
			title: '“I keep my Eight Sleep mattress ice cold and as a result my sleep fitness is on point. There are very few no-brainer investments in life— an Eight Sleep mattress is one of them.”',
			description: ['David Senra', 'Host of Founders'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/founders_hero.webp?v=1683268090',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/founders_hero.webp?v=1683268090',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/founders_hero.webp?v=1683268090',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'David Senra Approved',
			paragraphs: ['David Senra swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/founder_footer_mobile_b375f1cf-39a8-4dfd-8483-459df9addcda.png?v=1683275081',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/founder_footer_mobile_b375f1cf-39a8-4dfd-8483-459df9addcda.png?v=1683275081',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/founders_footer.webp?v=1683268090',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Founders Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/Group_3509_47d79e82-e722-476c-b236-c506f2c17f89.png?v=1683268717',
			imageAlt: 'Portrait of David Senra',
			description: 'David Senra swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'David Senra',
	},

	gundry: {
		utm_source: 'podcast',
		utm_medium: 'gundry',
		promoCode: 'gundry',
		promoBar: {
			'*': {
				message: 'Dr. Gundry Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Gundry Exclusive Sale',
		savingsTag: 'Dr. Gundry Exclusive: Up to %%% off',
		heroSettings: {
			title: "“I've heard so much about the benefits of Eight Sleep for better sleep. I can't wait to try it out!”",
			description: ['Dr. Steven Gundry', 'American Physician and host of The Dr. Gundry Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/gundry_hero_mobile.jpg?v=1683901874',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/gundry_hero_mobile.jpg?v=1683901874',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/gundry_hero_desktop.jpg?v=1683901874',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Gundry Approved',
			paragraphs: ['Dr. Gundry swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/gundry_footer_mobile.jpg?v=1683901874',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/gundry_footer_desktop.jpg?v=1683901874',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/gundry_footer_desktop.jpg?v=1683901874',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dr. Gundry Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/gundry_ecap.jpg?v=1683901873',
			imageAlt: 'Portrait of Dr. Steven Gundry',
			description: 'Dr. Gundry swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Dr. Steven Gundry',
	},

	keltie: {
		utm_source: 'yt',
		utm_medium: 'keltie_oconnor',
		promoCode: 'keltie',
		promoBar: {
			'*': {
				message: 'Keltie O’Connor Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Keltie O’Connor Exclusive Sale',
		savingsTag: 'Keltie O’Connor Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“I’ve always struggled with having restorative sleep that improved my HRV. Eight Sleep allows me to control the temperature of my bed so it’s warm and cozy when I first get in. Then programmed to lower temperature in the middle of the night, that significantly improved my HRV.”',
			description: ['Keltie O’Connor', 'Former college basketball player turned YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/keltie_hero_desktop.jpg?v=1683911693',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/keltie_hero_desktop.jpg?v=1683911693',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/keltie_hero_desktop.jpg?v=1683911693',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Keltie O’Connor Approved',
			paragraphs: ['Keltie O’Connor swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/keltie_footer_mobile_6545a0cd-2e69-4550-b6ba-294692faf610.jpg?v=1683911693',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/keltie_footer_desktop_5d80eaed-4f7b-49a9-a5fb-8c3ca5774efa.jpg?v=1683911692',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/keltie_footer_desktop_5d80eaed-4f7b-49a9-a5fb-8c3ca5774efa.jpg?v=1683911692',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Keltie O’Connor Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/keltie_ecap.jpg?v=1683911692',
			imageAlt: 'Portrait of Keltie O’Connor',
			description: 'Keltie O’Connor swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Keltie O’Connor',
	},

	smartless: {
		utm_source: 'podcast',
		utm_medium: 'smartless',
		promoCode: 'smartless',
		promoBar: {
			'*': {
				message: 'Smartless Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Smartless Exclusive Sale',
		savingsTag: 'Smartless Exclusive: Up to %%% off',
		heroSettings: {
			title: 'The hosts of SmartLess swear by the Pod Cover to get better sleep. Learn more for yourself.',
			description: [],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/smartless_hero_mobile.jpg?v=1684244486',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smartless_hero_desktop.jpg?v=1684244487',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smartless_hero_desktop.jpg?v=1684244487',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'SmartLess Approved',
			paragraphs: ['The hosts of SmartLess swear by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/smartless_footer_mobile.jpg?v=1684244486',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smartless_footer_desktop.jpg?v=1684244487',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smartless_footer_desktop.jpg?v=1684244487',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Smartless Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/smartless_ecap.jpg?v=1684244486',
			imageAlt: 'Portrait of Smartless team',
			description: 'The hosts of SmartLess swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Smartless',
	},

	moonshots: {
		utm_source: 'podcast',
		utm_medium: 'moonshots',
		promoCode: 'moonshots',
		promoBar: {
			'*': {
				message: 'Peter Diamandis Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Peter Diamandis Exclusive Sale',
		savingsTag: 'Peter Diamandis Exclusive: Up to %%% off',
		heroSettings: {
			title: '“A lot of people ask me about tips for getting good sleep and I always say the same thing: stop drinking alcohol, no screens 2 hours before bed, get an Eight Sleep Pod Cover.”',
			description: ['Peter Diamandis, MD', 'Executive Chairmen at The XPRIZE Foundation and Fountain Life Therapeutics'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_hero_mobile.jpg?v=1685540728',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_hero_desktop.jpg?v=1685540728',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_hero_desktop.jpg?v=1685540728',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Peter Diamandis Approved',
			paragraphs: ['Peter Diamandis swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_footer_mobile.jpg?v=1685540728',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_footer_desktop.jpg?v=1685540728',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/Peter_Diamandis_footer_desktop.jpg?v=1685540728',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Peter Diamandis Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/Peter_Diamandis_ecap.jpg?v=1685540728',
			imageAlt: 'Portrait of Peter Diamandis',
			description: 'Peter Diamandis swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Peter Diamandis',
	},

	morenews: {
		utm_source: 'podcast',
		utm_medium: 'morenews',
		promoCode: 'morenews',
		promoBar: {
			'*': {
				message: 'Some More News Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Some More News Exclusive Sale',
		savingsTag: 'Some More News Exclusive: Up to %%% off',
		heroSettings: {
			title: '“I love it! I am a very sweaty, restless sleeper and Eight Sleep is going to save me this summer. This product is a total game changer.”',
			description: ['Katy Stoll', 'Actress, Producer, and Co-host of Some More News'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/some_more_news_hero.jpg?v=1686581445',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/some_more_news_hero.jpg?v=1686581445',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/some_more_news_hero.jpg?v=1686581445',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Some More News Approved',
			paragraphs: ['The hosts of Some More News swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/some_more_news_footer_mobile_01f19429-0975-421b-8216-91572f21a902.jpg?v=1686586380',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/some_more_news_footer_desktop_7a2a66bd-9b12-47b7-92b4-abc3d2ba1203.jpg?v=1686586380',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/some_more_news_footer_desktop_7a2a66bd-9b12-47b7-92b4-abc3d2ba1203.jpg?v=1686586380',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Some More News Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/some_more_news_ecap.jpg?v=1686581445',
			imageAlt: 'Portrait of Some More News',
			description: 'The hosts of Some More News swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Some More News',
	},

	unboxtherapy: {
		utm_source: 'yt',
		utm_medium: 'unbox_therapy',
		promoCode: 'unboxtherapy',
		promoBar: {
			'*': {
				message: 'Unbox Therapy Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Unbox Therapy Exclusive Sale',
		savingsTag: 'Unbox Therapy Exclusive: Up to %%% off',
		heroSettings: {
			title: '“The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.”',
			description: ['Unbox Therapy', 'Technology YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/unbox_hero_mobile.jpg?v=1686897856',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/unbox_hero_mobile.jpg?v=1686897856',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/unbox_hero_desktop.jpg?v=1686897856',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Unbox Therapy Approved',
			paragraphs: ['Lewis swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/unbox_footer_mobile.jpg?v=1686897856',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/unbox_footer_desktop.jpg?v=1686897856',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/unbox_footer_desktop.jpg?v=1686897856',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Unbox Therapy Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/unbox_ecap.jpg?v=1686897855',
			imageAlt: 'Portrait of Unbox Therapy',
			description: 'The host of Unbox Therapy swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Unbox Therapy',
	},

	mindpump: {
		utm_source: 'podcast',
		utm_medium: 'mindpump',
		promoCode: 'mindpump',
		promoBar: {
			'*': {
				message: 'Mind Pump Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mind Pump Exclusive Sale',
		savingsTag: 'Mind Pump Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“Eight Sleep is the most comprehensive sleep system on the market. Not only does it modulate the temperature of your bed for optimal sleep but it also tracks your individual sleep and adjusts accordingly, creating the perfect sleep experience.”',
			description: ['', ''],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mindpump_hero_mobile.jpg?v=1687249879',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mindpump_hero_mobile.jpg?v=1687249879',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mindpump_hero_desktop.jpg?v=1687249879',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mind Pump Approved',
			paragraphs: ['The guys of Mind Pump swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mindpump_footer_mobile.jpg?v=1687249928',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mindpump_footer_desktop.jpg?v=1687249929',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mindpump_footer_desktop.jpg?v=1687249929',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Mind Pump Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/mindpump_ecap.jpg?v=1687249879',
			imageAlt: 'Portrait of Mind Pump',
			description: 'The guys of Mind Pump swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Mind Pump',
	},

	andrewzeng: {
		utm_source: 'yt',
		utm_medium: 'andrew_zeng',
		promoCode: 'aez',
		promoBar: {
			'*': {
				message: 'Andrew Zeng Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Andrew Zeng Exclusive Sale',
		savingsTag: 'Andrew Zeng Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“Quality sleep is vital for our mind and body. Temperature control is a huge factor in facilitating quality sleep and Eight Sleep has been the key companion in regulating my sleep quality every night.”',
			description: ['Andrew Ethan Zeng', 'Tech YouTuber & Entrepreneur'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_hero_mobile.jpg?v=1687250729',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_hero_mobile.jpg?v=1687250729',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_hero_desktop.jpg?v=1687250729',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Andrew Approved',
			paragraphs: ['Andrew Zeng swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: false,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_footer_mobile.jpg?v=1687250729',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_footer_desktop.jpg?v=1687250729',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/andrewzeng_footer_desktop.jpg?v=1687250729',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Andrew Zeng Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/andrewzeng_ecap.jpg?v=1687250729',
			imageAlt: 'Portrait of Andrew Zeng',
			description: 'Andrew Zeng swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Andrew Zeng',
	},

	mkbhd: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mkbhd-crop.png',
		utm_source: 'yt',
		utm_medium: 'mkbhd',
		promoCode: 'mkbhd',
		promoBar: {
			'*': {
				message: 'Marques Brownlee Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Marques Brownlee Exclusive Sale',
		savingsTag: 'Marques Brownlee Exclusive: Up to %%% off',
		heroSettings: {
			title: '“In an age where humans are worse at sleeping than ever before with all this tech around us, finding some tech that actually helps improve my sleep is incredibly important to me.”',
			description: ['Marques Brownlee', 'Technology YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/marques_hero_mobile.jpg?v=1687357395',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/marques_hero_mobile.jpg?v=1687357395',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/marques_hero_desktop.jpg?v=1687357395',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Marques Brownlee Approved',
			paragraphs: ['Marques swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/marques_footer_mobile.jpg?v=1687357395',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/marques_footer_desktop.jpg?v=1687357395',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/marques_footer_desktop.jpg?v=1687357395',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Marques Brownlee Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/marques_ecap.jpg?v=1687357394',
			imageAlt: 'Portrait of Marques Brownlee',
			description: 'Marques Brownlee swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Marques Brownlee',
	},

	meidas: {
		utm_source: 'podcast',
		utm_medium: 'meidas',
		promoCode: 'meidas',
		promoBar: {
			'*': {
				message: 'MeidasTouch Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'MeidasTouch Exclusive Sale',
		savingsTag: 'MeidasTouch Exclusive: Up to %%% off',
		heroSettings: {
			title:
				"“The Pod Cover definitely improved my sleep with the automatic temperature adjustment feature - especially as we're hitting these hot summer nights! I truly couldn't imagine going back to anything else.”",
			description: ['Jordy Meiselas', 'MeidasTouch Podcast Host'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/meidas_hero_mobile_ad15bcc6-79aa-4c4d-a262-538ddaa5008f.jpg?v=1688058447',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/meidas_hero_mobile_ad15bcc6-79aa-4c4d-a262-538ddaa5008f.jpg?v=1688058447',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/meidas_hero_desktop.jpg?v=1688031731',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'MeidasTouch Approved',
			paragraphs: ['The hosts of MeidasTouch swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/meidas_footer_mobile_67c75c02-55d9-458a-8db9-571497fe4d0f.jpg?v=1688058447',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/meidas_footer_desktop_a5395d9a-6546-433f-af93-f9825dfa47c6.jpg?v=1688058447',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/meidas_footer_desktop_a5395d9a-6546-433f-af93-f9825dfa47c6.jpg?v=1688058447',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'MeidasTouch Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/meidas_ecap.jpg?v=1688031731',
			imageAlt: 'Portrait of MeidasTouch',
			description: 'The hosts of MeidasTouch swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'MeidasTouch',
	},

	legalaf: {
		utm_source: 'podcast',
		utm_medium: 'legalaf',
		promoCode: 'legalaf',
		promoBar: {
			'*': {
				message: 'LegalAF Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'LegalAF Exclusive Sale',
		savingsTag: 'LegalAF Exclusive: Up to %%% off',
		heroSettings: {
			title:
				"“We spend almost half our lives in bed - improving our sleep routine, habits and overall sleep quality should be a priority for everyone. I wake up feeling refreshed after a great night's sleep.”",
			description: ['Karen Friedman Agnifilo', 'LegalAF Host'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/legalaf_hero_mobile.jpg?v=1688376600',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/legalaf_hero_mobile.jpg?v=1688376600',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/legalaf_hero_desktop.jpg?v=1688376600',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'LegalAF Approved',
			paragraphs: ['The hosts of LegalAF swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/legalaf_footer_mobile.jpg?v=1688376600',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/legalaf_footer_desktop.jpg?v=1688376600',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/legalaf_footer_desktop.jpg?v=1688376600',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'LegalAF Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/legalaf_ecap.jpg?v=1688376600',
			imageAlt: 'Portrait of LegalAF',
			description: 'The hosts of LegalAF swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'LegalAF',
	},

	themove: {
		utm_source: 'podcast',
		utm_medium: 'themove',
		promoCode: 'themove',
		promoBar: {
			'*': {
				message: 'THEMOVE Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'THEMOVE Exclusive Sale',
		savingsTag: 'THEMOVE Exclusive: Up to %%% off',
		heroSettings: {
			title:
				"“It's great! Especially for my naps. The vibrating alarm feature is amazing! I did snooze it three times this morning before my 7:00AM ride with the boss, but I never have to be woken up by an alarm again.”",
			description: ['George Hincapie', 'Former racing cyclist and co-host of THEMOVE'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'THEMOVE Approved',
			paragraphs: ['The hosts of THEMOVE swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/TheMove-img.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'THEMOVE Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/themove_ecap.jpg?v=1689104939',
			imageAlt: 'Portrait of THEMOVE hosts',
			description: 'The hosts of THEMOVE swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'THEMOVE',
	},

	kylegotcamera: {
		utm_source: 'yt',
		utm_medium: 'kylegotcamera',
		promoCode: 'kylegotcamera',
		promoBar: {
			'*': {
				message: 'Kyle Lasota Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Kyle Lasota Exclusive Sale',
		savingsTag: 'Kyle Lasota Exclusive: Up to %%% off',
		heroSettings: {
			title: "“If sleep optimization was a video game... Eight Sleep would be the cheat code. It just makes everything easier, it's almost unfair.”",
			description: ['Kyle Lasota', 'Biohacker'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Kyle Approved',
			paragraphs: ['Kyle Lasota swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Kyle Lasota Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/DSC08974.jpg?v=1689170905',
			imageAlt: 'Portrait of Kyle Lasota',
			description: 'Kyle Lasota swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Kyle Lasota',
	},

	sunny: {
		utm_source: 'podcast',
		utm_medium: 'sunny',
		promoCode: 'sunny',
		promoBar: {
			'*': {
				message: 'Always Sunny Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Always Sunny Exclusive Sale',
		savingsTag: 'Always Sunny Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'"I literally don’t know how I slept before we got our Pod Cover! Now my husband stays cool, I stay warm, and everybody stays happy. The Pod Cover is the only kind of technology you SHOULD sleep on."',
			description: ['Megan Ganz, American comedy writer and Podcaster'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sunny_hero_mobile.jpg?v=1689233744',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sunny_hero_mobile.jpg?v=1689233744',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sunny_hero_desktop.jpg?v=1689233744',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Always Sunny Approved',
			paragraphs: ['The hosts of Always Sunny swear by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sunny_footer_mobile.jpg?v=1689233744',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sunny_footer_desktop.jpg?v=1689233743',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sunny_footer_desktop.jpg?v=1689233743',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Always Sunny Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/sunny_ecap.jpg?v=1689233743',
			imageAlt: 'Portrait of Always Sunny hosts',
			description: 'The hosts of Always Sunny swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Always Sunny',
	},

	hih: {
		utm_source: 'podcast',
		utm_medium: 'hih',
		promoCode: 'hih',
		promoBar: {
			'*': {
				message: 'High Intensity Health Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'High Intensity Health Exclusive Sale',
		savingsTag: 'High Intensity Health Exclusive: Up to %%% off',
		heroSettings: {
			title:
				"“Eight sleep is an essential tool to improve my client's sleep quality by optimizing their sleep temperature and environment. It's especially helpful for peri and post-menopausal women experiencing sleep issues.”",
			description: ['Mike Mutzel', 'Functional medicine consultant and host of High Intensity Health'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hih_hero_mobile.jpg?v=1689358704',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hih_hero_desktop.jpg?v=1689358704',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hih_hero_desktop.jpg?v=1689358704',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mike Mutzel Approved',
			paragraphs: ['Mike Mutzel swears by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hih_column_mobile.jpg?v=1689358704',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hih_column_desktop.jpg?v=1689358704',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hih_column_desktop.jpg?v=1689358704',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'High Intensity Health Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/hih_ecap.jpg?v=1689358704',
			imageAlt: 'Portrait of Mike Mutzel',
			description: 'Mike Mutzel swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Mike Mutzel',
	},

	mrwhosetheboss: {
		utm_source: 'yt',
		utm_medium: 'mrwhosetheboss',
		promoCode: 'boss',
		promoBar: {
			'*': {
				message: 'Mrwhosetheboss Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mrwhosetheboss Exclusive Sale',
		savingsTag: 'Mrwhosetheboss Exclusive: Up to %%% off',
		heroSettings: {
			title: '“We had a heatwave this summer but I just flipped my Eight Sleep app out, set my Pod temperature lower, and never thought about it again.”',
			description: ['Mrwhosetheboss', 'Tech YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_hero.jpg?v=1690292191',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_hero.jpg?v=1690292191',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_hero.jpg?v=1690292191',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Arun Approved',
			paragraphs: ['Arun swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_footer_mobile.jpg?v=1690292191',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_footer_desktop.jpg?v=1690292191',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/mrwhosetheboss_footer_desktop.jpg?v=1690292191',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Mrwhosetheboss Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/mrwhosetheboss_ecap.jpg?v=1690292191',
			imageAlt: 'Portrait of Arun',
			description: 'Arun swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Arun',
	},

	pakman: {
		utm_source: 'podcast',
		utm_medium: 'pakman',
		promoCode: 'pakman',
		promoBar: {
			'*': {
				message: 'David Pakman Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'David Pakman Exclusive Sale',
		savingsTag: 'David Pakman Exclusive: Up to %%% off',
		heroSettings: {
			title: "“Eight Sleep's Pod Cover has truly been a game changer for me. Staying cool at night has always been a crucial part of me getting a good night's sleep.”",
			description: ['David Pakman', 'Host of The David Pakman Show'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/pakman_hero.jpg?v=1690293698',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pakman_hero.jpg?v=1690293698',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pakman_hero.jpg?v=1690293698',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'David Pakman Approved',
			paragraphs: ['David Pakman swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/pakman_footer_mobile.jpg?v=1690293697',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pakman_footer_desktop.jpg?v=1690293697',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/pakman_footer_desktop.jpg?v=1690293697',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'David Pakman Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/pakman_ecap.jpg?v=1690293697',
			imageAlt: 'Portrait of David Pakman',
			description: 'David Pakman swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'David Pakman',
	},

	sevan: {
		utm_source: 'podcast',
		utm_medium: 'sevan',
		promoCode: 'sevan',
		promoBar: {
			'*': {
				message: 'Sevan Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sevan Exclusive Sale',
		savingsTag: 'Sevan Exclusive: Up to %%% off',
		heroSettings: {
			title: "“The ability to control the temperature of the bed is a game changer! If you're looking to optimize your sleep... the Pod Cover is a no brainer”",
			description: ['Sevan Matossian', 'Former CMO of CrossFit and host of The Sevan Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sevan_hero.jpg?v=1690871905',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sevan_hero.jpg?v=1690871905',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sevan_hero.jpg?v=1690871905',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sevan Approved',
			paragraphs: ['Sevan swears by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sevan_footer_mobile.jpg?v=1690871905',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sevan_footer_desktop.jpg?v=1690871905',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sevan_footer_desktop.jpg?v=1690871905',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Sevan Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/sevan_ecap.jpg?v=1690871905',
			imageAlt: 'Portrait of Sevan Matossian',
			description: 'Sevan swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Sevan',
	},

	dangerclose: {
		utm_source: 'podcast',
		utm_medium: 'dangerclose',
		promoCode: 'dangerclose',
		promoBar: {
			'*': {
				message: 'Jack Carr Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Jack Carr Exclusive Sale',
		savingsTag: 'Jack Carr Exclusive: Up to %%% off',
		heroSettings: {
			title: "“As an author who writes late into the night, and as a parent with 3 kids who get up early, I need every second of sleep I can get. That's where the Pod Cover by Eight Sleep comes in.”",
			description: ['Jack Carr', 'Author, former Navy SEAL, and host of Danger Close'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dangerclose_hero.jpg?v=1691484715',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dangerclose_hero.jpg?v=1691484715',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dangerclose_hero.jpg?v=1691484715',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jack Carr Approved',
			paragraphs: ['Jack Carr swears by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/dangerclose_footer_mobile.jpg?v=1691484715',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dangerclose_footer_desktop.jpg?v=1691484715',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/dangerclose_footer_desktop.jpg?v=1691484715',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Jack Carr Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/dangerclose_ecap.jpg?v=1691484715',
			imageAlt: 'Portrait of Jack Carr',
			description: 'Jack Carr swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Jack Carr',
	},

	ijustine: {
		utm_source: 'yt',
		utm_medium: 'ijustine',
		promoCode: 'ijustine',
		promoBar: {
			'*': {
				message: 'iJustine Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'iJustine Exclusive Sale',
		savingsTag: 'iJustine Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“Eight Sleep has completely changed how I sleep! The technology behind Eight Sleep has helped me sleep comfortably through the night and wake up easily with the adjustable temperature settings. It is a real game changer!”',
			description: ['iJustine', 'Tech YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ijustine_hero.jpg?v=1692093499',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ijustine_hero.jpg?v=1692093499',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ijustine_hero.jpg?v=1692093499',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'iJustine Approved',
			paragraphs: ['Justine swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ijustine_footer_mobile.jpg?v=1692093499',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ijustine_footer_desktop.jpg?v=1692093499',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ijustine_footer_desktop.jpg?v=1692093499',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'iJustine Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ijustine_ecap.jpg?v=1692093499',
			imageAlt: 'Portrait of iJustine',
			description: 'Justine swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'iJustine',
	},

	steven: {
		utm_source: 'podcast',
		utm_medium: 'steven',
		promoCode: 'steven',
		promoBar: {
			'*': {
				message: 'Diary of a CEO Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Diary of a CEO Exclusive Sale',
		savingsTag: 'Diary of a CEO Exclusive: Up to %%% off',
		heroSettings: {
			title:
				'“My sleep, I would say now is the most important part of my health. Over the first 7 days of having the Pod Cover, it learned the temperature I need to have optimal sleep. A revelation in my life!”',
			description: ['Steven Bartlett', 'Entrepreneur and host of Diary of a CEO'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/diary_hero.jpg?v=1692257851',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/diary_hero.jpg?v=1692257851',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/diary_hero.jpg?v=1692257851',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Steven Bartlett Approved',
			paragraphs: ['Steven Bartlett swears by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/diary_footer_mobile.jpg?v=1692257850',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/diary_footer_desktop.jpg?v=1692257851',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/diary_footer_desktop.jpg?v=1692257851',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Diary of a CEO Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/diary_ecap.jpg?v=1692257850',
			imageAlt: 'Portrait of Steven Bartlett',
			description: 'Steven Bartlett swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Steven Bartlett',
	},

	powerathlete: {
		utm_source: 'podcast',
		utm_medium: 'powerathlete',
		promoCode: 'powerathlete',
		promoBar: {
			'*': {
				message: 'Power Athlete Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Power Athlete Exclusive Sale',
		savingsTag: 'Power Athlete Exclusive: %%% off',
		heroSettings: {
			title:
				"“I jumped on the mattress cooling pad train early in 2018 and never looked back. When I found Eight Sleep, I knew it was time for an upgrade. The integration of the app and sleep tracking to customize the Pod Cover’s temperature is a game changer. I can't imagine sleeping without it.”",
			description: ['John Welbourn', 'NFL veteran and founder/CEO of Power Athlete'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/powerathlete_hero.jpg?v=1692258865',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/powerathlete_hero.jpg?v=1692258865',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/powerathlete_hero.jpg?v=1692258865',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Power Athlete Approved',
			paragraphs: ['John Welbourn swears by the Pod 3 Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/powerathlete_footer_mobile.jpg?v=1692258865',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/powerathlete_footer_desktop.jpg?v=1692258865',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/powerathlete_footer_desktop.jpg?v=1692258865',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Power Athlete Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/powerathlete_ecap.jpg?v=1692258865',
			imageAlt: 'Portrait of Power Athlete',
			description: 'John Welbourn swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Power Athlete',
	},

	markellis: {
		utm_source: 'yt',
		utm_medium: 'mark_ellis',
		promoCode: 'markellis',
		promoBar: {
			'*': {
				message: 'Mark Ellis Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mark Ellis Exclusive Sale',
		savingsTag: 'Mark Ellis Exclusive: %%% off',
		heroSettings: {
			title: "“I had no idea how important temperature is when it comes to getting a great night's sleep. The insights I get from Eight Sleep are addictive, too - in the best possible way”",
			description: ['Mark Ellis reviews', 'Technology YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/markellis_hero.jpg?v=1693923950',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/markellis_hero.jpg?v=1693923950',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/markellis_hero.jpg?v=1693923950',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mark Ellis Approves',
			paragraphs: ['Mark swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/markellis_footer_mobile.jpg?v=1693923950',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/markellis_footer_desktop.jpg?v=1693923950',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/markellis_footer_desktop.jpg?v=1693923950',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Mark Ellis Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/markellis_ecap.jpg?v=1693923950',
			imageAlt: 'Portrait of Mark Ellis',
			description: 'Mark Ellis swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Mark Ellis',
	},

	drbecky: {
		utm_source: 'yt',
		utm_medium: 'drbecky',
		promoCode: 'drbecky',
		promoBar: {
			'*': {
				message: 'Dr. Becky Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Becky Exclusive Sale',
		savingsTag: 'Dr. Becky Exclusive: %%% off',
		heroSettings: {
			title: "“Once you sleep on an Eight Sleep Pod Cover, you'll be amazed at what you've been missing”",
			description: ['Dr. Becky Gillaspy', 'DC and Author'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drbecky_hero.jpg?v=1695632576',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drbecky_hero.jpg?v=1695632576',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drbecky_hero.jpg?v=1695632576',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Becky Approves',
			paragraphs: ['Dr. Becky swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drbecky_footer_mobile.jpg?v=1695632575',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drbecky_footer_desktop.jpg?v=1695632575',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drbecky_footer_desktop.jpg?v=1695632575',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dr. Becky Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/drbecky_ecap.jpg?v=1695632575',
			imageAlt: 'Portrait of Dr. Becky',
			description: 'Dr. Becky swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Dr. Becky Gillaspy',
	},

	mylett: {
		utm_source: 'podcast',
		utm_medium: 'mylett',
		promoCode: 'mylett',
		promoBar: {
			'*': {
				message: 'Ed Mylett Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ed Mylett Exclusive Sale',
		savingsTag: 'Ed Mylett Exclusive: %%% off',
		heroSettings: {
			title: 'The Ed Mylett Show',
			description: [''],
		},
		preFooterSettings: {
			title: 'Ed Mylett Approved',
			paragraphs: ['Ed Mylett swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/footer_generic_mobile.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/footer_generic_desktop.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ed Mylett Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
			imageAlt: 'Portrait of Ed Mylett',
			description: 'Ed Mylett swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Ed Mylett',
	},

	mel: {
		utm_source: 'podcast',
		utm_medium: 'mel',
		promoCode: 'mel',
		promoBar: {
			'*': {
				message: 'Mel Robbins Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mel Robbins Exclusive Sale',
		savingsTag: 'Mel Robbins Exclusive: %%% off',
		heroSettings: {
			title: 'The Mel Robbins Podcast',
			description: [''],
		},
		preFooterSettings: {
			title: 'Mel Robbins Approved',
			paragraphs: ['Mel Robbins swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/footer_generic_mobile.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/footer_generic_desktop.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Mel Robbins Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
			imageAlt: 'Portrait of Mel Robbins',
			description: 'Mel Robbins swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Mel Robbins',
	},

	built: {
		utm_source: 'podcast',
		utm_medium: 'built',
		promoCode: 'built',
		promoBar: {
			'*': {
				message: 'How I Built This Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Guy Raz Sale',
		savingsTag: 'How I Built This Exclusive: Up to %%% off',
		heroSettings: {
			title: 'How I Built This',
			description: ['Upgrade your sleep with the Pod Cover and receive %%% off.'],
		},
		preFooterSettings: {
			title: 'Guy Raz Approved',
			paragraphs: ['Guy Raz swears by the Pod Cover to get better sleep. Learn more for yourself.'],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			imageSrc: 'https://eightsleep.imgix.net/generic_vanity_hero.jpg?v=1682434446',
			imageAlt: 'The Pod',
			description: 'Guy Raz swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Guy Raz',
	},

	austinevans: {
		utm_source: 'yt',
		utm_medium: 'austinevans',
		promoCode: 'austin',
		promoBar: {
			'*': {
				message: 'Austin Evans Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Austin Evans Exclusive Sale',
		savingsTag: 'Austin Evans Exclusive: %%% off',
		heroSettings: {
			title: "“A comfortable temperature and useful data on your sleeping patterns can help you feel more rested and inform you on what does (and doesn't) impact your sleep”",
			description: ['Austin Evans', 'Technology YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/austinevans_hero.jpg?v=1697187930',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/austinevans_hero.jpg?v=1697187930',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/austinevans_hero.jpg?v=1697187930',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Austin Approved',
			paragraphs: ['Austin Evans swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/austinevans_footer_mobile.jpg?v=1697187929',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/austinevans_footer_desktop.jpg?v=1697187928',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/austinevans_footer_desktop.jpg?v=1697187928',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Austin Evans Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/austinevans_ecap.jpg?v=1697187929',
			imageAlt: 'Portrait of Austin Evans',
			description: 'Austin Evans swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Austin Evans',
	},

	jerryrigeverything: {
		utm_source: 'yt',
		utm_medium: 'jerryrigeverything',
		promoCode: 'jerryrig',
		promoBar: {
			'*': {
				message: 'JerryRigEverything Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'JerryRigEverything Exclusive Sale',
		savingsTag: 'JerryRigEverything Exclusive: %%% off',
		heroSettings: {
			title: "“Turns out you get better sleep at night when your body isn't working hard to regulate your temperature. Who woulda thought?”",
			description: ['Zack Nelson', 'JerryRigEverything'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_hero.jpg?v=1697529624',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_hero.jpg?v=1697529624',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_hero.jpg?v=1697529624',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'JerryRigEverything Approved',
			paragraphs: ['Zack swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_footer_mobile.jpg?v=1697529623',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_footer_desktop.jpg?v=1697529623',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jerryrigeverything_footer_desktop.jpg?v=1697529623',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'JerryRigEverything Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/jerryrigeverything_ecap.jpg?v=1697529623',
			imageAlt: 'Portrait of Zack Nelson',
			description: 'Zack swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'JerryRigEverything',
	},

	moreplatesmoredates: {
		utm_source: 'yt',
		utm_medium: 'moreplatesmoredates',
		promoCode: 'mpmd',
		promoBar: {
			'*': {
				message: 'MorePlatesMoreDates Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'MorePlatesMoreDates Exclusive Sale',
		savingsTag: 'MorePlatesMoreDates Exclusive: %%% off',
		heroSettings: {
			title:
				"“Eight Sleep is the first piece of tech I've seen that not only tracks sleep metrics without requiring any wearables, but also enhances sleep quality through auto-regulated temperature control”",
			description: ['MorePlatesMoreDates', 'Founder and Fitness YouTuber'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_hero.jpg?v=1697529625',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_hero.jpg?v=1697529625',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_hero.jpg?v=1697529625',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'MorePlatesMoreDates Approved',
			paragraphs: ['Derek swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_footer_mobile.jpg?v=1697529623',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_footer_desktop.jpg?v=1697529623',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/MorePlatesMoreDates_footer_desktop.jpg?v=1697529623',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'MorePlatesMoreDates Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/MorePlatesMoreDates_ecap.jpg?v=1697529623',
			imageAlt: 'Portrait of Derek',
			description: 'Derek swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'MorePlatesMoreDates',
	},

	natasha: {
		utm_source: 'ig',
		utm_medium: 'nvdm',
		promoCode: 'nvdm',
		promoBar: {
			'*': {
				message: 'Natasha Van Der Merwe Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Natasha Van Der Merwe Exclusive Sale',
		savingsTag: 'Natasha Van Der Merwe Exclusive: %%% off',
		heroSettings: {
			title:
				'“Eight Sleep is an incredible tool for anyone looking to fully optimize their recovery so that they can come back quicker and stronger the next day, be it for training or a long day of work.”',
			description: ['Natasha Van Der Merwe', 'Founder, CEO and Pro Triathlete'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/natasha_hero.jpg?v=1697694993',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/natasha_hero.jpg?v=1697694993',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/natasha_hero.jpg?v=1697694993',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Natasha Approved',
			paragraphs: ['Natasha swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/natasha_footer_desktop.jpg?v=1697694993',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/natasha_footer_desktop.jpg?v=1697694993',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/natasha_footer_desktop.jpg?v=1697694993',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Natasha Van Der Merwe Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/natasha_ecap.jpg?v=1697694993',
			imageAlt: 'Portrait of Natasha',
			description: 'Natasha swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Natasha Van Der Merwe',
	},

	markmanson: {
		utm_source: 'podcast',
		utm_medium: 'manson',
		promoCode: 'manson',
		promoBar: {
			'*': {
				message: 'Mark Manson Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mark Manson Exclusive Sale',
		savingsTag: 'Mark Manson Exclusive: %%% off',
		heroSettings: {
			title: '“Sleeping with an Eight Sleep is like a coma without the brain trauma, but, you know, in a good way.”',
			description: ['Mark Manson', 'Best Selling Author of The Subtle Art of Not Giving a F*ck'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/manson_hero_mobile.jpg?v=1700235564',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/manson_hero_mobile.jpg?v=1700235564',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/manson_hero_desktop.jpg?v=1700235565',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mark Manson Approved',
			paragraphs: ['Mark Manson swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/manson_footer_mobile.jpg?v=1700235564',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/manson_footer_desktop.jpg?v=1700235565',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/manson_footer_desktop.jpg?v=1700235565',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Mark Manson Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/manson_hero_desktop.jpg?v=1700235565',
			imageAlt: 'Portrait of Mark Manson',
			description: 'Mark Manson swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Mark Manson',
	},

	hacksmith: {
		utm_source: 'yt',
		utm_medium: 'hacksmith',
		promoCode: 'hacksmith',
		promoBar: {
			'*': {
				message: 'Hacksmith Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Hacksmith Exclusive Sale',
		savingsTag: 'Hacksmith Exclusive: %%% off',
		heroSettings: {
			title: '“I’ve struggled with getting a good night’s sleep for a while now, and couldn’t find a solution until I started using the Pod Cover. I wake up feeling like I’m ready for anything. ”',
			description: ['James', 'The Hacksmith'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hacksmith_hero_mobile.jpg?v=1700036880',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hacksmith_hero_mobile.jpg?v=1700036880',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hacksmith_hero_desktop.jpg?v=1700036880',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Hacksmith Approved',
			paragraphs: ['James swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/hacksmith_footer_mobile.jpg?v=1700036880',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hacksmith_footer_desktop.jpg?v=1700036880',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/hacksmith_footer_desktop.jpg?v=1700036880',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Hacksmith Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/hacksmith_hero_desktop.jpg?v=1700036880',
			imageAlt: 'Portrait of James',
			description: 'James swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'The Hacksmith',
	},

	drjubbal: {
		utm_source: 'yt',
		utm_medium: 'drjubbal',
		promoCode: 'kevin',
		promoBar: {
			'*': {
				message: 'Dr. Jubbal Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Jubbal Exclusive Sale',
		savingsTag: 'Dr. Jubbal Exclusive: %%% off',
		heroSettings: {
			title: '“By facilitating the natural body temperature changes we need for sleep and awakening, Eight Sleep helps me fall asleep faster and stay asleep with fewer disturbances.”',
			description: ['Dr. Kevin Jubbal', ''],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drjubbal_hero_mobile.jpg?v=1700217438',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drjubbal_hero_mobile.jpg?v=1700217438',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drjubbal_hero_desktop.jpg?v=1700217438',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Jubbal Approved',
			paragraphs: ['Dr. Jubbal swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/drjubbal_footer_mobile.jpg?v=1700217439',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drjubbal_footer_desktop.jpg?v=1700217438',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/drjubbal_footer_desktop.jpg?v=1700217438',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dr. Jubbal Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/drjubbal_hero_desktop.jpg?v=1700217438',
			imageAlt: 'Portrait of Dr. Jubbal ',
			description: 'Dr. Jubbal swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Dr. Kevin Jubbal',
	},

	sweats: {
		utm_source: 'yt',
		utm_medium: 'sweats',
		promoCode: 'sweats',
		promoBar: {
			'*': {
				message: 'Sweats & The City Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sweats & The City Exclusive Sale',
		savingsTag: 'Sweats & The City Exclusive: %%% off',
		heroSettings: {
			title:
				"“During my early postpartum days, the cooling feature was incredibly helpful to control those night sweats, I couldn't believe how effective it was. It's hard for me to sleep anywhere else without my Eight Sleep now!”",
			description: ['Dale', 'Sweats & the City'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sweats_hero_mobile.jpg?v=1700220159',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sweats_hero_mobile.jpg?v=1700220159',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sweats_hero_desktop.jpg?v=1700220159',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sweats & The City Approved',
			paragraphs: ['Dale swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sweats_footer_mobile.jpg?v=1700220159',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sweats_footer_desktop.jpg?v=1700220159',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sweats_footer_desktop.jpg?v=1700220159',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Sweats & The City Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/sweats_hero_desktop.jpg?v=1700220159',
			imageAlt: 'Portrait of Dale',
			description: 'Dale swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Sweats & The City',
	},

	colin: {
		utm_source: 'yt',
		utm_medium: 'colin',
		promoCode: 'colin',
		promoBar: {
			'*': {
				message: 'Colin Furze Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Colin Furze Exclusive Sale',
		savingsTag: 'Colin Furze Exclusive: %%% off',
		heroSettings: {
			title: '“It was not until I stayed away from home that I realized just how much the Pod does to improve my sleep.”',
			description: ['Colin Furze,', 'YouTuber and Inventor'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/colin_hero_mobile.jpg?v=1700228341',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/colin_hero_mobile.jpg?v=1700228341',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/colin_hero_desktop.jpg?v=1700228349',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Colin Furze Approved',
			paragraphs: ['Colin swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/colin_footer_mobile.jpg?v=1700228405',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/colin_footer_desktop.jpg?v=1700228402',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/colin_footer_desktop.jpg?v=1700228402',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Colin Furze Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/colin_hero_desktop.jpg?v=1700228349',
			imageAlt: 'Portrait of Colin Furze',
			description: 'Colin swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Colin Furze',
	},

	matttalkstech: {
		utm_source: 'yt',
		utm_medium: 'Matt_Talks_Tech',
		promoCode: 'matttalkstech',
		promoBar: {
			'*': {
				message: 'Matt Talks Tech Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Matt Talks Tech Exclusive Sale',
		savingsTag: 'Matt Talks Tech Exclusive: %%% off',
		heroSettings: {
			title: '“The Pod warms my bed when I go to sleep and regulates my temperature, increasing my deep sleep.”',
			description: ['Matt Talks Tech'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/matt_hero_mobile.jpg?v=1700567700',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/matt_hero_mobile.jpg?v=1700567700',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/matt_hero_desktop.jpg?v=1700567701',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Matt Talks Tech Approved',
			paragraphs: ['Matt swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/matt_footer_mobile.jpg?v=1700567701',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/matt_footer_desktop.jpg?v=1700567701',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/matt_footer_desktop.jpg?v=1700567701',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Matt Talks Tech Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/matt_hero_desktop.jpg?v=1700567701',
			imageAlt: 'Portrait of Matt',
			description: 'Matt swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Matt Talks Tech ',
	},

	jocr: {
		utm_source: 'podcast',
		utm_medium: 'jocr',
		promoCode: 'jocr',
		promoBar: {
			'*': {
				message: 'JOCR Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'JOCR Exclusive Sale',
		savingsTag: 'JOCR Exclusive: %%% off',
		heroSettings: {
			title: "“The Pod's temperature control and Autopilot adjustments make my bed the best place ever. I Love it!”",
			description: ['JOCR'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jocr_hero_mobile.jpg?v=1701333616',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jocr_hero_mobile.jpg?v=1701333616',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jocr_hero_desktop_5f7b8e4b-4bc9-4ba1-9a72-d30435c51679.jpg?v=1701333838',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'JOCR Approved',
			paragraphs: ['Joel swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jocr_footer_mobile.jpg?v=1701333624',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jocr_footer_desktop.jpg?v=1701333624',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jocr_footer_desktop.jpg?v=1701333624',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'JOCR Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/jocr_hero_desktop_5f7b8e4b-4bc9-4ba1-9a72-d30435c51679.jpg?v=1701333838',
			imageAlt: 'Portrait of Joel',
			description: 'Joel swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'JOCR',
	},

	doola: {
		utm_source: 'podcast',
		utm_medium: 'doola',
		promoCode: 'doola',
		promoBar: {
			'*': {
				message: 'Doola Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Doola Exclusive Sale',
		savingsTag: 'Doola Exclusive: %%% off',
		heroSettings: {
			title: '“I am a big believer that you can “sharpen your body to sharpen your mind.” And sharpening your body starts with good sleep.”',
			description: ['Arjun Mahadevan', 'CEO of doola'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/doola_hero_mobile.jpg?v=1701334311',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/doola_hero_mobile.jpg?v=1701334311',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/doola_hero_desktop.jpg?v=1701334311',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Arjun Mahadevan Approved',
			paragraphs: ['Arjun Mahadevan swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/doola_footer_mobile.jpg?v=1701334311',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/doola_footer_desktop.jpg?v=1701334311',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/doola_footer_desktop.jpg?v=1701334311',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Doola Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/doola_hero_desktop.jpg?v=1701334311',
			imageAlt: 'Portrait of Arjun Mahadevan',
			description: 'Arjun Mahadevan swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Doola',
	},

	ramsey: {
		utm_source: 'podcast',
		utm_medium: 'ramsey',
		promoCode: 'ramsey',
		promoBar: {
			'*': {
				message: 'The Ramsey Show Team Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Ramsey Show Team Exclusive Sale',
		savingsTag: 'The Ramsey Show Team Exclusive: %%% off',
		heroSettings: {
			title: '“The Pod Cover is the ultimate sleep experience and will change how you sleep and show up in your life.”',
			description: ['Dr. John Delony', 'Ramsey Show Mental Health and Relationships Expert'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ramsey+hero.jpg?v=1704734360',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramsey+hero.jpg?v=1704734360',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramsey+hero.jpg?v=1704734360',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ramsey Show Approved',
			paragraphs: ['The Ramsey Show Team swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ramsey+footer+mobile.jpg?v=1704734360',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramsey+footer+desktop.jpg?v=1704734359',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramsey+footer+desktop.jpg?v=1704734359',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'The Ramsey Show Team Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ramsey+hero.jpg?v=1704734360',
			imageAlt: 'Portrait of The Ramsey Show Team',
			description: 'The Ramsey Show Team swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'The Ramsey Show Team',
	},

	delony: {
		utm_source: 'podcast',
		utm_medium: 'delony',
		promoCode: 'delony',
		promoBar: {
			'*': {
				message: 'Dr. John Delony Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. John Delony Exclusive Sale',
		savingsTag: 'Dr. John Delony Exclusive: %%% off',
		heroSettings: {
			title: '“The Pod Cover is the ultimate sleep experience and will change how you sleep and show up in your life”',
			description: ['Dr. John Delony', 'A Mental Health and Relationships Expert'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/delaney+hero.jpg?v=1704735095',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/delaney+hero.jpg?v=1704735095',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/delaney+hero.jpg?v=1704735095',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. John Delony approved',
			paragraphs: ['John swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/delaney+footer+mobile.jpg?v=1704735095',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/delaney+footer+desktop.jpg?v=1704735109',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/delaney+footer+desktop.jpg?v=1704735109',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dr. John Delony Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/delaney+hero.jpg?v=1704735095',
			imageAlt: 'Portrait of Dr. John Delony',
			description: 'Dr. John Delony swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Dr. John Delony',
	},

	fore: {
		utm_source: 'podcast',
		utm_medium: 'fore',
		promoCode: 'fore',
		promoBar: {
			'*': {
				message: 'Foreplay Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Foreplay Exclusive Sale',
		savingsTag: 'Foreplay Exclusive: %%% off',
		heroSettings: {
			title: "“It's the best thing to ever happen to my sleep”",
			description: ['Frankie Borrelli', 'Co-host of Barstool’s Foreplay'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/fore+hero.jpg?v=1704813637',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/fore+hero.jpg?v=1704813637',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/fore+hero.jpg?v=1704813637',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Foreplay approved',
			paragraphs: ['The Foreplay Crew swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/fore+footer+mobile.jpg?v=1704813637',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/fore+footer+desktop.jpg?v=1704813637',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/fore+footer+desktop.jpg?v=1704813637',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Foreplay Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/fore+hero.jpg?v=1704813637',
			imageAlt: 'Portrait of The Foreplay Crew',
			description: 'The Foreplay Crew swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'The Foreplay Crew',
	},

	ramit: {
		utm_source: 'podcast',
		utm_medium: 'ramit',
		promoCode: 'ramit',
		promoBar: {
			'*': {
				message: 'Ramit Sethi Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ramit Sethi Exclusive Sale',
		savingsTag: 'Ramit Sethi Exclusive: %%% off',
		heroSettings: {
			title: '“Great sleep could help you fall back in love with your partner.”',
			description: ['Ramit Sethi', 'Best-selling Author of I Will Teach You to Be Rich'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ramit+hero.jpg?v=1704815131',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramit+hero.jpg?v=1704815131',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramit+hero.jpg?v=1704815131',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ramit Sethi approved',
			paragraphs: ['Ramit swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ramit+footer+mobile.jpg?v=1704815131',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramit+footer+desktop.jpg?v=1704815130',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ramit+footer+desktop.jpg?v=1704815130',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ramit Sethi Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ramit+hero.jpg?v=1704815131',
			imageAlt: 'Portrait of Ramit Sethi',
			description: 'Ramit Sethi swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Ramit Sethi',
	},

	bert: {
		utm_source: 'podcast',
		utm_medium: 'bert',
		promoCode: 'bert',
		promoBar: {
			'*': {
				message: 'Something’s Burning Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Something’s Burning Exclusive Sale',
		savingsTag: 'Something’s Burning Exclusive: %%% off',
		heroSettings: {
			title: '',
			description: ['Bert Kreischer', 'Comedian'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bert+hero.jpg?v=1704815799',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bert+hero.jpg?v=1704815799',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bert+hero.jpg?v=1704815799',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Bert approved',
			paragraphs: ['Bert swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bert+footer+mobile.jpg?v=1704815798',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bert+footer+desktop.jpg?v=1704815798',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bert+footer+desktop.jpg?v=1704815798',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Something’s Burning Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/bert+hero.jpg?v=1704815799',
			imageAlt: 'Portrait of Bert',
			description: 'Bert swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Something’s Burning',
	},

	plattfuss: {
		utm_source: 'podcast',
		utm_medium: 'plattfuss',
		promoCode: 'plattfuss200',
		promoBar: {
			'*': {
				message: 'Plattfuß Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Plattfuß Exclusive Sale',
		savingsTag: 'Plattfuß Exclusive: %%% off',
		heroSettings: {
			title: '”Bereits die ersten Test-Tage haben erstaunliche Ergebnisse hervorgebracht. Hätten nicht gedacht, dass die Temperatur der Matratze so eine Auswirkung auf den Schlaf hat”',
			description: ['Lasse', 'Plattfuß Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/plattfuss+hero.jpg?v=1704902765',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/plattfuss+hero.jpg?v=1704902765',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/plattfuss+hero.jpg?v=1704902765',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Lasse Popken approved',
			paragraphs: ['Lasse Popken swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/plattfuss+footer+mobile.jpg?v=1704902764',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/plattfuss+footer+desktop.jpg?v=1704902763',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/plattfuss+footer+desktop.jpg?v=1704902763',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Plattfuß Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/plattfuss+hero.jpg?v=1704902765',
			imageAlt: 'Portrait of Lasse Popken',
			description: 'Lasse Popkens swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Lasse Popken',
	},

	richroll: {
		utm_source: 'podcast',
		utm_medium: 'richroll',
		promoCode: 'richroll',
		promoBar: {
			'*': {
				message: 'Rich Roll Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Rich Roll Exclusive Sale',
		savingsTag: 'Rich Roll Exclusive: %%% off',
		heroSettings: {
			title: '”A high tech solution to your age-old sleeping issues”',
			description: ['Rich Roll', 'World-renowned plant-based ultra-endurance athlete'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/richroll+hero.jpg?v=1704903791',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/richroll+hero.jpg?v=1704903791',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/richroll+hero.jpg?v=1704903791',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Rich Roll approved',
			paragraphs: ['Rich Roll swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/richroll+footer+mobile.jpg?v=1704903790',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/richroll+footer+desktop.jpg?v=1704903790',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/richroll+footer+desktop.jpg?v=1704903790',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Rich Roll Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/richroll+hero.jpg?v=1704903791',
			imageAlt: 'Portrait of Rich Roll',
			description: 'Rich Roll swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Rich Roll',
	},

	ricky: {
		utm_source: 'podcast',
		utm_medium: 'ricky',
		promoCode: 'ricky',
		promoBar: {
			'*': {
				message: 'Two Bit da Vinci Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Two Bit da Vinci Exclusive Sale',
		savingsTag: 'Two Bit da Vinci Exclusive: %%% off',
		heroSettings: {
			title: '”There’s probably not another product I look forward to using every day like my Eight Sleep Pod Cover”',
			description: ['Two Bit da Vinci', 'Engineer'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ricky+hero.jpg?v=1704959621',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ricky+hero.jpg?v=1704959621',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ricky+hero.jpg?v=1704959621',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Two Bit da Vinci approved',
			paragraphs: ['Two Bit da Vinci swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ricky+footer+mobile.jpg?v=1704959622',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ricky+footer+desktop.jpg?v=1704959621',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ricky+footer+desktop.jpg?v=1704959621',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Two Bit da Vinci Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ricky+hero.jpg?v=1704959621',
			imageAlt: 'Portrait of Ricky',
			description: 'Ricky swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Two Bit da Vinci',
	},

	astrum: {
		utm_source: 'yt',
		utm_medium: 'astrum',
		promoCode: 'astrum',
		promoBar: {
			'*': {
				message: 'Astrum Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Astrum Exclusive Sale',
		savingsTag: 'Astrum Exclusive: %%% off',
		heroSettings: {
			title: '”In these cold winter months, I’ve loved jumping into bed and finding it already warm with the Pod.”',
			description: ['Astrum', 'Solar System Expert'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/astrum+hero.jpg?v=1704988819',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/astrum+hero.jpg?v=1704988819',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/astrum+hero.jpg?v=1704988819',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Astrum approved',
			paragraphs: ['Astrum swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/astrum+footer+mobile.jpg?v=1704988817',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/astrum+footer+desktop.jpg?v=1704988817',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/astrum+footer+desktop.jpg?v=1704988817',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Astrum Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/astrum+hero.jpg?v=1704988819',
			imageAlt: 'Portrait of Astrum',
			description: 'Astrum swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Astrum',
	},

	bestzeit: {
		utm_source: 'podcast',
		utm_medium: 'bestzeit',
		promoCode: 'bestzeit200',
		promoBar: {
			'*': {
				message: 'Bestzeit Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Bestzeit Exclusive Sale',
		savingsTag: 'Bestzeit Exclusive: %%% off',
		heroSettings: {
			title: '”The individual setting & adjustment of the temperature has significantly increased my sleeping comfort and improved my recovery & regeneration. I now have more energy during the day”',
			description: ['Philipp Pflieger', 'Host of the Bestzeit Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bestzeit+hero.jpg?v=1704988818',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bestzeit+hero.jpg?v=1704988818',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bestzeit+hero.jpg?v=1704988818',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Philipp Pflieger approved',
			paragraphs: ['Philipp Pflieger swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bestzeit+footer+mobile.jpg?v=1704988817',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bestzeit+footer+desktop.jpg?v=1704988816',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bestzeit+footer+desktop.jpg?v=1704988816',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Bestzeit Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/bestzeit+hero.jpg?v=1704988818',
			imageAlt: 'Portrait of Philipp Pflieger',
			description: 'Philipp Pflieger swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Philipp Pflieger',
	},

	jr: {
		utm_source: 'podcast',
		utm_medium: 'jr',
		promoCode: 'jr',
		promoBar: {
			'*': {
				message: 'Grilling JR Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Grilling JR Exclusive Sale',
		savingsTag: 'Grilling JR Exclusive: %%% off',
		heroSettings: {
			title: '”Eight sleep is a game changer. Business is about to pick up here!”',
			description: ['Jim Ross', 'The Voice of Wrestling'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jr+hero.jpg?v=1705050718',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jr+hero.jpg?v=1705050718',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jr+hero.jpg?v=1705050718',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jim and Conrad approved',
			paragraphs: ['Jim and Conrad swear by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/jr+footer+mobile.jpg?v=1705050717',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jr+footer+desktop.jpg?v=1705050717',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/jr+footer+desktop.jpg?v=1705050717',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Grilling JR Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/jr+hero.jpg?v=1705050718',
			imageAlt: 'Portrait of Jim and Conrad',
			description: 'Jim and Conrad swear by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Jim and Conrad',
	},

	ziroth: {
		utm_source: 'yt',
		utm_medium: 'ziroth',
		promoCode: 'ziroth',
		promoBar: {
			'*': {
				message: 'Ziroth Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ziroth Exclusive Sale',
		savingsTag: 'Ziroth Exclusive: %%% off',
		heroSettings: {
			title: "“The best part of the Eight Sleep Pod has to be the individual temperature and gradual wake-up system - now I don't have to hear my partner's 10 alarms!”",
			description: ['Ziroth', 'Science and Engineering'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ziroth+hero.jpg?v=1705051292',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ziroth+hero.jpg?v=1705051292',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ziroth+hero.jpg?v=1705051292',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ziroth approved',
			paragraphs: ['Ziroth swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ziroth+footer+mobile.jpg?v=1705051292',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ziroth+footer+desktop.jpg?v=1705051292',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ziroth+footer+desktop.jpg?v=1705051292',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ziroth Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/ziroth+hero.jpg?v=1705051292',
			imageAlt: 'Portrait of Ziroth',
			description: 'Ziroth swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Ziroth',
	},

	trips: {
		utm_source: 'podcast',
		utm_medium: 'trips',
		promoCode: 'trips',
		promoBar: {
			'*': {
				message: 'Family Trips Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Family Trips Exclusive Sale',
		savingsTag: 'Family Trips Exclusive: %%% off',
		heroSettings: {
			title: '',
			description: ['Seth Meyers', 'Late Night Host'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/trips+hero.jpg?v=1705066762',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/trips+hero.jpg?v=1705066762',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/trips+hero.jpg?v=1705066762',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Seth approved',
			paragraphs: ['Seth swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/trips+footer+mobile.jpg?v=1705066760',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/trips+footer+desktop.jpg?v=1705066760',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/trips+footer+desktop.jpg?v=1705066760',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Family Trips Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/trips+hero.jpg?v=1705066762',
			imageAlt: 'Portrait of Seth Meyers',
			description: 'Seth swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Seth Meyers',
	},

	smarthome: {
		utm_source: 'yt',
		utm_medium: 'smarthome',
		promoCode: 'smarthome',
		promoBar: {
			'*': {
				message: 'Smart Home Solver Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Smart Home Solver Exclusive Sale',
		savingsTag: 'Smart Home Solver Exclusive: %%% off',
		heroSettings: {
			title: '“I noticed an improvement in my sleep from the first night of using the Pod Cover. I finally got deep sleep, without overheating.”',
			description: ['Smart Home Solver', 'Home Tech'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/smarthome+hero.jpg?v=1705067384',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smarthome+hero.jpg?v=1705067384',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smarthome+hero.jpg?v=1705067384',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Smart Home Solver approved',
			paragraphs: ['Smart Home Solver swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/smarthome+footer+mobile.jpg?v=1705067383',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smarthome+footer+desktop.jpg?v=1705067383',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/smarthome+footer+desktop.jpg?v=1705067383',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Smart Home Solver Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/smarthome+hero.jpg?v=1705067384',
			imageAlt: 'Portrait of Smart Home Solver',
			description: 'Smart Home Solver swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Smart Home Solver',
	},

	bisping: {
		utm_source: 'podcast',
		utm_medium: 'bisping',
		promoCode: 'bisping',
		promoBar: {
			'*': {
				message: 'Believe You Me Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Believe You Me Exclusive Sale',
		savingsTag: 'Believe You Me Exclusive: %%% off',
		heroSettings: {
			title: "“If you don't get a good night's sleep, you are far from the best version of yourself. Sleep is the foundation and the cornerstone of having a successful tomorrow.“",
			description: ['Mike Bisping', 'Late Night Host'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bisping+hero.jpg?v=1705082442',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bisping+hero.jpg?v=1705082442',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bisping+hero.jpg?v=1705082442',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mike Bisping approved',
			paragraphs: ['Mike swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/bisping+footer+mobile.jpg?v=1705082442',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bisping+footer+desktop.jpg?v=1705082442',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/bisping+footer+desktop.jpg?v=1705082442',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Believe You Me Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/bisping+hero.jpg?v=1705082442',
			imageAlt: 'Portrait of Mike Bisping',
			description: 'Mike Bisping swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Mike Bisping',
	},

	lit: {
		utm_source: 'podcast',
		utm_medium: 'lit',
		promoCode: 'lit',
		promoBar: {
			'*': {
				message: 'Litquidity Exclusive: Save an additional &&& off the Pod',
				link: null,
			},
		},
		saleName: 'Litquidity Exclusive Sale',
		savingsTag: 'Litquidity Exclusive: Save an additional &&& off the Pod',
		heroSettings: {
			title: '“As Warren Buffett has said throughout his career, the most important ingredient for success is to invest in yourself.“',
			description: ['Litquidity', 'Founder / CEO, Litquidity Media and General Partner, Litquidity Venture Partners'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/lit+hero.jpg?v=1705332874',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lit+hero.jpg?v=1705332874',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lit+hero.jpg?v=1705332874',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Litquidity approved',
			paragraphs: ['Lit swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/lit+footer+mobile.jpg?v=1705332874',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lit+footer+desktop.jpg?v=1705332874',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/lit+footer+desktop.jpg?v=1705332874',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Litquidity Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/lit+hero.jpg?v=1705332874',
			imageAlt: 'Portrait of Lit',
			description: 'Lit swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Litquidity',
	},

	chatsports: {
		utm_source: 'podcast',
		utm_medium: 'chatsports',
		promoCode: 'chatsports',
		promoBar: {
			'*': {
				message: 'Chat Sports Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Chat Sports Exclusive Sale',
		savingsTag: 'Chat Sports Exclusive: %%% off',
		heroSettings: {
			title: '“Getting quality sleep is the foundation of my daily routine, providing the stamina needed to stay sharp and engaging throughout each live show, especially on game day.“',
			description: ['Mitchell Renz', 'Chat Sports Host'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/chatsports+hero.jpg?v=1705510443',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/chatsports+hero.jpg?v=1705510443',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/chatsports+hero.jpg?v=1705510443',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Chat Sports approved',
			paragraphs: ['The Chat Sports Crew swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/chatsports+footer+mboile.jpg?v=1705510442',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/chatsports+footer+desktop.jpg?v=1705510443',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/chatsports+footer+desktop.jpg?v=1705510443',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Chat Sports Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/chatsports+hero.jpg?v=1705510443',
			imageAlt: 'Portrait of The Chat Sports Crew',
			description: 'The Chat Sports Crew swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'The Chat Sports Crew',
	},

	planz: {
		utm_source: 'podcast',
		utm_medium: 'planz',
		promoCode: 'planz200',
		promoBar: {
			'*': {
				message: 'PlanZ Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'PlanZ Exclusive Sale',
		savingsTag: 'PlanZ Exclusive: %%% off',
		heroSettings: {
			title: '”Sleep is one of the most important factors for recovery. I‘m happy I could step up my recovery game with Eight Sleep.”',
			description: ['Rick', 'Host of the PlanZ Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/planz+hero.jpg?v=1705653984',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/planz+hero.jpg?v=1705653984',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/planz+hero.jpg?v=1705653984',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Rick approved',
			paragraphs: ['Rick swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/planz+footer+mobile.jpg?v=1705653983',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/planz+footer+desktop.jpg?v=1705653984',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/planz+footer+desktop.jpg?v=1705653984',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'PlanZ Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/planz+hero.jpg?v=1705653984',
			imageAlt: 'Portrait of Rick',
			description: 'Rick swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'PlanZ',
	},

	sitzfleisch: {
		utm_source: 'podcast',
		utm_medium: 'sitzfleisch',
		promoCode: 'sitzfleisch',
		promoBar: {
			'*': {
				message: 'Sitzfleisch Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sitzfleisch Exclusive Sale',
		savingsTag: 'Sitzfleisch Exclusive: %%% off',
		heroSettings: {
			title: '”The Pod makes smart temperature adjustments which means my sleep quality and recovery during the night have really improved, and I can fully exploit my performance potential.”',
			description: ['Christoph Strasser', 'Host of the Sitzfleisch Podcast'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+hero.jpg?v=1705655577',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+hero.jpg?v=1705655577',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+hero.jpg?v=1705655577',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Christoph Strasser approved',
			paragraphs: ['Christoph Strasser swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+footer+mobile.jpg?v=1705655576',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+footer+desktop.jpg?v=1705655576',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/sitzfleisch+footer+desktop.jpg?v=1705655576',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Sitzfleisch Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/sitzfleisch+hero.jpg?v=1705655577',
			imageAlt: 'Portrait of Christoph Strasser',
			description: 'Christoph Strasser swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Sitzfleisch',
	},

	luke: {
		utm_source: 'YT',
		utm_medium: 'luke',
		promoCode: 'luke',
		promoBar: {
			'*': {
				message: 'Luke Miani Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Luke Miani Exclusive Sale',
		savingsTag: 'Luke Miani Exclusive: %%% off',
		heroSettings: {
			title: '”Eight Sleep is a true innovation– nobody has realized the potential of a smart bed until now. It has changed the way I sleep!”',
			description: ['Luke Miani', 'Tech YouTube'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/luke+hero.jpg?v=1706002539',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/luke+hero.jpg?v=1706002539',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/luke+hero.jpg?v=1706002539',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Luke Miani approved',
			paragraphs: ['Luke swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/luke+footer+mobile.jpg?v=1706002538',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/luke+footer+desktop.jpg?v=1706002538',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/luke+footer+desktop.jpg?v=1706002538',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Luke Miani Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/luke+hero.jpg?v=1706002539',
			imageAlt: 'Portrait of Luke Miani',
			description: 'Luke Miani swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Luke Miani',
	},

	heavyd: {
		utm_source: 'YT',
		utm_medium: 'heavyd',
		promoCode: 'heavyd',
		promoBar: {
			'*': {
				message: 'HeavyDSparks Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'HeavyDSparks Exclusive Sale',
		savingsTag: 'HeavyDSparks Exclusive: %%% off',
		heroSettings: {
			title: '”The Pod Cover has been the single most effective health and wellness item I have ever purchased”',
			description: ['HeavyDSparks', 'The Diesel Brothers'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+hero.jpg?v=1706186958',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+hero.jpg?v=1706186958',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+hero.jpg?v=1706186958',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'HeavyDSparks approved',
			paragraphs: ['HeavyDSparks swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+footer+mobile.jpg?v=1706186958',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+footer+desktop.jpg?v=1706186958',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/HeavyDSparks+footer+desktop.jpg?v=1706186958',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'HeavyDSparks Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/HeavyDSparks+hero.jpg?v=1706186958',
			imageAlt: 'Portrait of HeavyDSparks',
			description: 'HeavyDSparks swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'HeavyDSparks',
	},

	donut: {
		utm_source: 'YT',
		utm_medium: 'donut',
		promoCode: 'donut',
		promoBar: {
			'*': {
				message: 'Donut Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Donut Exclusive Sale',
		savingsTag: 'Donut Exclusive: %%% off',
		heroSettings: {
			title: '”I’ll admit I’ve sometimes left one side colder for my dog because he loves the Pod Cover as much as me”',
			description: ['Donut', 'Automotive YouTube'],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/donut+hero.jpg?v=1706187656',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/donut+hero.jpg?v=1706187656',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/donut+hero.jpg?v=1706187656',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Donut approved',
			paragraphs: ['The Donut crew swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/donut+footer+mobile.jpg?v=1706187655',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/donut+footer+desktop.jpg?v=1706187655',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/donut+footer+desktop.jpg?v=1706187655',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Donut Exclusive',
			imageSrc: 'https://eightsleep.imgix.net/donut+hero.jpg?v=1706187656',
			imageAlt: 'Portrait of HeavyDSparks',
			description: 'The Donut crew swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'The Donut crew',
	},

	cleo: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo-crop.png',
		utm_source: 'YT',
		utm_medium: 'cleo',
		promoCode: 'cleo',
		promoBar: {
			'*': {
				message: 'Cleo Abram Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Cleo Abram Exclusive Sale',
		savingsTag: 'Cleo Abram Exclusive: %%% off',
		heroSettings: {
			title: '”At its best, technology improves our health and our lives. With Eight Sleep, I get better rest and wake up more ready to take on the day.”',
			description: ['Cleo Abram', 'Host of Huge if True'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_hero.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_hero.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_hero.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Cleo approved',
			paragraphs: ['Cleo swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_footer_mobile.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_footer_desktop.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_footer_desktop.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Cleo Abram Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleo_hero.jpg',
			imageAlt: 'Portrait of Cleo Abram',
			description: 'Cleo swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Cleo',
	},

	veritasium: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium-crop.png',
		utm_source: 'YT',
		utm_medium: 'veritasium',
		promoCode: 'veritasium',
		promoBar: {
			'*': {
				message: 'Veritasium Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Veritasium Exclusive Sale',
		savingsTag: 'Veritasium Exclusive: %%% off',
		heroSettings: {
			title: '”I just moved to Australia and it’s so hot! But I’ve got the Eight Sleep Pod to keep me cool at night and thanks to that, my sleep has never been better.”',
			description: ['Veritasium', 'An Element of Truth'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_hero.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_hero.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_hero.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Veritasium approved',
			paragraphs: ['Derek swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_footer_mobile.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_footer_desktop.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_footer_desktop.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Veritasium Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/veritasium_hero.jpg',
			imageAlt: 'Portrait of Derek',
			description: 'Derek swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Veritasium',
	},

	bryson: {
		phantomImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-crop.png',
		utm_source: 'YT',
		utm_medium: 'bryson',
		promoCode: 'bryson',
		promoBar: {
			'*': {
				message: 'Bryson DeChambeau Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Bryson DeChambeau Exclusive Sale',
		savingsTag: 'Bryson DeChambeau Exclusive: %%% off',
		heroSettings: {
			title: '”Deep REM sleep is imperative to our daily recovery for professional sports and in life. As I am getting older, I feel younger with this sleep system.”',
			description: ['Bryson DeChambeau', 'Professional Golfer, Major Tournament Champion'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-hero.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-hero.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-hero.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Bryson DeChambeau approved',
			paragraphs: ['Bryson swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-footer-mobile.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-footer-desktop.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-footer-desktop.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Bryson DeChambeau Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bryson-hero.jpg',
			imageAlt: 'Portrait of Bryson DeChambeau',
			description: 'Bryson swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Bryson DeChambeau',
	},

	mattferrell: {
		utm_source: 'YT',
		utm_medium: 'mattferrell',
		promoCode: 'matt',
		promoBar: {
			'*': {
				message: 'Matt Ferrell Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Matt Ferrell Exclusive Sale',
		savingsTag: 'Matt Ferrell Exclusive: %%% off',
		heroSettings: {
			title:
				'“I’m obsessed with technology that can positively impact our lives, but even I was surprised at how much this tech has improved my sleep quality. It’s hard to sleep in a bed without it now.”',
			description: ['Matt Ferrell', 'Technology YouTube'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-hero.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-hero.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-hero.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Matt Ferrell approved',
			paragraphs: ['Matt swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-footer-mobile.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-footer-desktop.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-footer-desktop.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Matt Ferrell Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ferrell-hero.jpg',
			imageAlt: 'Portrait of Matt Ferrell',
			description: 'Matt swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Matt Ferrell',
	},

	pumpclub: {
		utm_source: 'newsletter',
		utm_medium: 'pumpclub',
		promoCode: 'pumpclub',
		promoBar: {
			'*': {
				message: 'Arnold’s Pump Club Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Arnold’s Pump Club Exclusive Sale',
		savingsTag: 'Arnold’s Pump Club Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: ['Arnold’s Pump Club'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Arnold’s Pump Club approved',
			paragraphs: ['Arnold’s Pump Club swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Arnold’s Pump Club Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/arnold-pump-club-logo.png',
			imageAlt: 'Portrait of Arnold’s Pump Club',
			description: 'Arnold’s Pump Club swears by the Pod to sleep at the perfect temperature. Enter your email to shop their exclusive sleep toolkit.',
		},
		partnerName: 'Arnold’s Pump Club',
	},

	smarter: {
		utm_source: 'yt',
		utm_medium: 'smartereveryday',
		promoCode: 'smarter',
		promoBar: {
			'*': {
				message: 'Smarter Every Day Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Smarter Every Day Exclusive Sale',
		savingsTag: 'Smarter Every Day Exclusive: %%% off',
		heroSettings: {
			title:
				'“One thing I really love about Eight Sleep is the fact that it helps me track not only how long I sleep, but the quality of that sleep. It’s helped me become more mindful about my sleep and understand how important prioritizing rest is in my life.”',
			description: ['Destin Sandlin', 'Smarter Every Day'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-every-day-t1-logo.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-every-day-t1-logo.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-every-day-t1-logo.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'An exclusive offer for our viewers',
			paragraphs: ['Destin invested in a Pod before we reached out to him about sponsoring Smarter Every Day, and when we did he was excited to work with us!'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-everyday-hero.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-everyday-hero.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-everyday-hero.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Smarter Every Day Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/smarter-every-day-t1-logo.jpg',
			imageAlt: 'Portrait of Destin Sandlin',
			description: 'Destin swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Smarter Every Day',
	},

	pow: {
		utm_source: 'podcast',
		utm_medium: 'pow',
		promoCode: 'pow',
		promoBar: {
			'*': {
				message: 'Pursuit of Wellness Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Pursuit of Wellness Exclusive Sale',
		savingsTag: 'Pursuit of Wellness Exclusive: %%% off',
		heroSettings: {
			title:
				'“Quality sleep is a crucial part of my wellness routine and Eight Sleep has been a game changer for me and my husband. I love that I can personalize my sleep experience with their temperature control technology.”',
			description: ['Mari Llewellyn', 'Founder of Bloom and host of the Pursuit of Wellness podcast'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Pursuit of Wellness approved',
			paragraphs: ['Mari Llewellyn swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Pursuit of Wellness Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pursuit+of+Wellness+Main.jpg',
			imageAlt: 'Portrait of Mari Llewellyn',
			description: 'Mari Llewellyn swears by the Pod to sleep at the perfect temperature. Enter your email to shop her exclusive sleep toolkit.',
		},
		partnerName: 'Pursuit of Wellness',
	},

	me: {
		utm_source: 'newsletter',
		utm_medium: 'marketingexamined',
		promoCode: 'me',
		promoBar: {
			'*': {
				message: 'Marketing Examined Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Marketing Examined Exclusive Sale',
		savingsTag: 'Marketing Examined Exclusive: %%% off',
		heroSettings: {
			title:
				'“I’m trying to build an empire. I workout twice a day. But I’ve never slept great. No matter what I tried. I bought an Eight Sleep. And yes, it changed my sleep. But seriously, it changed my life. Don’t underestimate what an extra hour or two of deep sleep will do.”',
			description: ['Alex Garcia', 'Founder of Marketing Examined'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Alex Garcia approved',
			paragraphs: ['Alex Garcia swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Marketing Examined Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/t1-marketing-examined-main.jpg',
			imageAlt: 'Portrait of Alex Garcia',
			description: 'Alex Garcia swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Marketing Examined',
	},

	rubin: {
		utm_source: 'podcast',
		utm_medium: 'rubin',
		promoCode: 'rubin',
		promoBar: {
			'*': {
				message: 'Dave Rubin Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dave Rubin Exclusive Sale',
		savingsTag: 'Dave Rubin Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: ['Dave Rubin', 'Host of the Rubin Report'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dave Rubin approved',
			paragraphs: ['Dave swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Dave Rubin Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rubin-t1-main-2024.jpg',
			imageAlt: 'Portrait of Dave Rubin',
			description: 'Dave Rubin swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Dave Rubin',
	},

	shapiro: {
		utm_source: 'podcast',
		utm_medium: 'shapiro',
		promoCode: 'shapiro',
		promoBar: {
			'*': {
				message: 'Ben Shapiro Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ben Shapiro Exclusive Sale',
		savingsTag: 'Ben Shapiro Exclusive: %%% off',
		heroSettings: {
			title: "“I can't keep up with my day if I don't get a decent night's sleep.“",
			description: ['Ben Shapiro', 'Co-Founder of the Daily Wire'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ben Shapiro approved',
			paragraphs: ['Ben swears by the Pod Cover to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ben Shapiro Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shapiro-t1-main-2024.jpg',
			imageAlt: 'Portrait of Ben Shapiro',
			description: 'Ben Shapiro swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Ben Shapiro',
	},
	hpp: {
		utm_source: 'podcast',
		utm_medium: 'hpp',
		promoCode: 'hpp',
		promoBar: {
			'*': {
				message: 'High Performance Podcast Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'High Performance Podcast Exclusive Sale',
		savingsTag: 'High Performance Podcast Exclusive: %%% off',
		heroSettings: {
			title: '“Eight Sleep has been a game changer for me to get high quality sleep and I feel much better for it.“',
			description: ['Jake Humphrey', 'High Performance Podcast Host'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'High Performance Podcast approved',
			paragraphs: ['Jake and Damion swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'High Performance Podcast Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hpp_10042024_main.png',
			imageAlt: 'Portrait of Jake and Damion ',
			description:
				'Jake and Damion swear by the Pod for unbeatable sleep. Sign up to unlock your offer.swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'High Performance Podcast',
	},

	healf: {
		utm_source: 'website',
		utm_medium: 'healf',
		promoCode: 'healfexclusive',
		promoBar: {
			'*': {
				message: 'Healf Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Healf Exclusive Sale',
		savingsTag: 'Healf Exclusive: %%% off',
		heroSettings: {
			title: '',
			description: ['Healf'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-hero-mobile-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-hero-desktop-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-hero-desktop-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'A special offer for Healf viewers',
			paragraphs: ['Healf swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-footer-2024.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-footer-2024.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-footer-2024.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Healf Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-ecap-2024.jpg',
			imageAlt: 'Portrait of Healf',
			description: 'Healf swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Healf',
	},

	smm: {
		utm_source: 'podcast',
		utm_medium: 'smm',
		promoCode: 'smm',
		promoBar: {
			'*': {
				message: 'Six Minute Mile Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Six Minute Mile Exclusive Sale',
		savingsTag: 'Six Minute Mile Exclusive: %%% off',
		heroSettings: {
			title:
				'“Athletes of all levels can benefit from improving their sleep. It’s a well-known fact that sleeping well can boost running performance. Sleep releases growth hormones that repair damaged tissue, making it vital for an athlete’s recovery.“',
			description: ['David Lavallee', 'Six Minute Mile Publisher'],
			heading: 'Six Minute Mile readers love the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Six Minute Mile approved',
			paragraphs: ['Six Minute Mile swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Six Minute Mile Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sixminutemile-main.jpg',
			imageAlt: 'Portrait of Healf',
			description: 'Six Minute Mile swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Six Minute Mile',
	},

	gary: {
		utm_source: 'podcast',
		utm_medium: 'ultimatehuman',
		promoCode: 'gary',
		promoBar: {
			'*': {
				message: 'Ultimate Human Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ultimate Human Exclusive Sale',
		savingsTag: 'Ultimate Human Exclusive: %%% off',
		heroSettings: {
			title:
				'“Embracing the Eight Sleep Pod Cover has revolutionized my nightly routine. This is more than just a product—it’s a wellness tool that has transformed every night into a personalized journey towards optimal health and comfort. I am not just sleeping anymore, I am tuning into a deeper understanding of what my body needs to get true rest.“',
			description: ['Gary Brecka', 'Human Biologist and Biohacker'],
			heading: 'Gary Brecka approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
			bgImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-background.jpg',
		},
		preFooterSettings: {
			title: 'Gary Brecka approved',
			paragraphs: ['Gary Brecka swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Ultimate Human Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/gary-brecka-main.jpg',
			imageAlt: 'Portrait of Gary Brecka',
			description: 'Gary Brecka swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Ultimate Human',
	},

	boyle: {
		utm_source: 'influencer',
		utm_medium: 'patrickboyle',
		promoCode: 'boyle',
		promoBar: {
			'*': {
				message: 'Patrick Boyle Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Patrick Boyle Exclusive Sale',
		savingsTag: 'Patrick Boyle Exclusive: %%% off',
		heroSettings: {
			title:
				'“The Eight Sleep Pod is amazing! It heats and cools your mattress and allows you to set two temperature zones in your bed, it then tracks your sleep and through the app recommends optimal temperatures for each phase of your sleep. It is incredibly high quality and has really improved my sleep.“',
			description: ['Patrick Boyle', 'Finance expert'],
			heading: 'Patrick Boyle loves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Patrick Boyle approved',
			paragraphs: ['Patrick Boyle swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Patrick Boyle Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/patrick-boyle-main.jpg',
			imageAlt: 'Portrait of Patrick Boyle',
			description: 'Patrick Boyle swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Patrick Boyle',
	},

	amy: {
		utm_source: 'influencer',
		utm_medium: 'amychang',
		promoCode: 'amy',
		promoBar: {
			'*': {
				message: 'Amy Chang Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Amy Chang Exclusive Sale',
		savingsTag: 'Amy Chang Exclusive: %%% off',
		heroSettings: {
			title:
				'“Getting high quality restorative sleep is crucial for healthy, youthful looking skin. Lack of sleep has shown to negatively impact collagen production and increase skin inflammation, which is why I’m a big fan of Eight Sleep. It helps me fall asleep faster, have deeper sleep and wake up feeling rested.“',
			description: ['Amy Chang', 'Skincare expert'],
			heading: 'Amy Chang loves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Amy Chang approved',
			paragraphs: ['Amy swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Amy Chang Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/amy-chang-main.jpg',
			imageAlt: 'Portrait of Amy Chang',
			description: 'Amy Chang swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Amy Chang',
	},

	hormone: {
		utm_source: 'podcast',
		utm_medium: 'hormone',
		promoCode: 'hormone',
		promoBar: {
			'*': {
				message: 'The Hormone Solution with Karen Martel Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Hormone Solution with Karen Martel Exclusive Sale',
		savingsTag: 'The Hormone Solution with Karen Martel Exclusive: %%% off',
		heroSettings: {
			title:
				"”Before, I'd wake up several times each night due to hot flashes. Ever since I got my Eight Sleep, I've been sleeping through the night without interruptions! I adore how it adjusts to a cozy warmth initially and then gradually cools as the night progresses.“",
			description: ['Karen Martel', 'Host of the The Hormone Solution with Karen Martel podcast'],
			heading: 'Karen Martel approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Karen Martel approved',
			paragraphs: ['Karen Martel swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'The Hormone Solution with Karen Martel Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/karen-martel-main.jpg',
			imageAlt: 'Portrait of Karen Martel',
			description: 'Karen Martel swears by the Pod to sleep at the perfect temperature. Enter your email to shop his exclusive sleep toolkit.',
		},
		partnerName: 'Karen Martel',
	},

	abbey: {
		utm_source: 'influencer',
		utm_medium: 'abbey',
		promoCode: 'abbey',
		promoBar: {
			'*': {
				message: 'Abbey Sharp Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Abbey Sharp Exclusive Sale',
		savingsTag: 'Abbey Sharp Exclusive: %%% off',
		heroSettings: {
			title: '”For someone who has struggled badly with sleep and has come to recognize how important it is for my overall health, I think Eight Sleep is worth its weight in gold.“',
			description: ['Abbey Sharp', 'Registered Dietitian'],
			heading: 'Abbey approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Abbey Sharp Approved',
			paragraphs: ['Abbey swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_profile.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Abbey Sharp Exclusive',
			subtitle: 'Abbey gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abbey_main.png',
			imageAlt: 'Portrait of Abbey Sharp',
			description: 'Abbey swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Abbey Sharp',
	},
	meno: {
		utm_source: 'influencer',
		utm_medium: 'meno',
		promoCode: 'meno',
		promoBar: {
			'*': {
				message: 'What The Menopause Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'What The Menopause Exclusive Sale',
		savingsTag: 'What The Menopause Exclusive: %%% off',
		heroSettings: {
			title:
				"”Sleep is a major problem for women in menopause because estrogen helps regulate body temperature and temperature regulates the sleep/wake cycle. Eight Sleep is a gamechanger for women struggling with disrupted sleep due to hot flashes and night sweats, by keeping body temperature low throughout the night. I personally haven't slept this well in years!“",
			description: ['Jessica Barac', 'Registered Nutritionist'],
			heading: 'What The Menopause approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'What The Menopause Approved',
			paragraphs: ['Jessica swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.pngg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'What The Menopause Exclusive',
			subtitle: 'Jessica gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/JessicaBarac_main.png',
			imageAlt: 'Portrait of Jessica Barac',
			description: 'Jessica swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Jessica Barac',
	},
	mbb: {
		utm_source: 'podcast',
		utm_medium: 'mbb',
		promoCode: 'mbb',
		promoBar: {
			'*': {
				message: 'Mayim Bialik Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mayim Bialik Exclusive Sale',
		savingsTag: 'Mayim Bialik Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod',
			description: [],
			heading: 'Mayim Bialik approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mayim Bialik Approved',
			paragraphs: ['Mayim Bialik swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Mayim Bialik Exclusive',
			subtitle: 'Mayim Bialik gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mayimbialik.png',
			imageAlt: 'Portrait of Mayim Bialik',
			description: 'Mayim Bialik swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Mayim Bialik',
	},
	better: {
		utm_source: 'podcast',
		utm_medium: 'better',
		promoCode: 'better',
		promoBar: {
			'*': {
				message: 'Better with Dr. Stephanie Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Better with Dr. Stephanie Exclusive Sale',
		savingsTag: 'Better with Dr. Stephanie Exclusive: %%% off',
		heroSettings: {
			title:
				"”I used to think I was a pretty good sleeper until I got an Eight Sleep pod cover. Now I know what excellent, restorative sleep actually feels like! I'm waking up well-rested, with natural energy ready to crush my early morning workouts.“",
			description: ['Dr. Stephanie Estima', 'Host of Better with Dr. Stephanie'],
			heading: 'Dr. Stephanie approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Stephanie Approved',
			paragraphs: ['Dr. Stephanie swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Better with Dr. Stephanie Exclusive',
			subtitle: 'Dr. Stephanie gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
			imageAlt: 'Portrait of Dr. Stephanie',
			description: 'Dr. Stephanie swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Dr. Stephanie Estima',
	},

	allin: {
		utm_source: 'podcast',
		utm_medium: 'allin',
		promoCode: 'allin',
		promoBar: {
			'*': {
				message: 'All In Podcast Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'All In Podcast Exclusive Sale',
		savingsTag: 'All In Podcast Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: ['Eight Sleep is an All-In Podcast Summit sponsor'],
			heading: 'The All-In Podcast approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'All In Approved',
			paragraphs: ['All In swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'All In Podcast Exclusive',
			subtitle: 'All In gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/allin-partner-main.png',
			imageAlt: 'Portrait of All In Podcast',
			description: 'All In Podcast swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'All In Podcast',
	},

	todd: {
		utm_source: 'influencer',
		utm_medium: 'todd',
		promoCode: 'todd42',
		promoBar: {
			'*': {
				message: 'Todd Anderson Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Todd Anderson Exclusive Sale',
		savingsTag: 'Todd Anderson Exclusive: %%% off',
		heroSettings: {
			title:
				'“Pod 4 Ultra is a game changer. Sleep is the foundation of health, wellness and performance. There is not one other product that will have as big of an impact on the quality of your sleep. Combined with the sleep tracking data capabilities it is truly a one stop shop for sleep performance.”',
			description: ['Todd Anderson', 'Health Performance Coach'],
			heading: 'Todd approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Todd Anderson Approved',
			paragraphs: ['Todd swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Todd Anderson Exclusive',
			subtitle: 'All In gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/todd-t1-main.png',
			imageAlt: 'Portrait of Todd Anderson',
			description: 'Todd Anderson swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Todd Anderson',
	},

	rober: {
		utm_source: 'influencer',
		utm_medium: 'rober',
		promoCode: 'rober',
		promoBar: {
			'*': {
				message: 'Mark Rober Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mark Rober Exclusive Sale',
		savingsTag: 'Mark Rober Exclusive: %%% off',
		heroSettings: {
			title:
				'“As a man of science, I actually tested out all the most popular bed cooling options about a year ago, and this was the one I stuck with because it worked so much better than anything else.”',
			description: ['Mark Rober', 'former NASA engineer & Youtuber '],
			heading: 'Mark approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mark Rober Approved',
			paragraphs: ['Mark swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Mark Rober Exclusive',
			subtitle: 'All In gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rober-t1-main.jpg',
			imageAlt: 'Portrait of Mark Rober',
			description: 'Mark Rober swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Mark Rober',
	},

	dtw: {
		utm_source: 'podcast',
		utm_medium: 'dtw',
		promoCode: 'dtw',
		promoBar: {
			'*': {
				message: 'Do The Work Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Do The Work Exclusive Sale',
		savingsTag: 'Do The Work Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: [],
			heading: 'Sabrina Zohar approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sabrina Zohar Approved',
			paragraphs: ['Sabrina swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Do The Work Exclusive',
			subtitle: 'Sabrina Zohar gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/dtw-t1-main.jpg',
			imageAlt: 'Portrait of Sabrina Zohar',
			description: 'Sabrina Zohar swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Sabrina Zohar',
	},

	skinny: {
		utm_source: 'podcast',
		utm_medium: 'skinny',
		promoCode: 'skinny',
		promoBar: {
			'*': {
				message: 'Skinny Confidential Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Skinny Confidential Exclusive Sale',
		savingsTag: 'Skinny Confidential Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: [],
			heading: 'Lauryn and Michael approve the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Skinny Confidential Approved',
			paragraphs: ['Lauryn and Michael swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Skinny Confidential Exclusive',
			subtitle: 'Lauryn and Michael gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/skinny-t1-main.jpg',
			imageAlt: 'Portrait of Lauryn and Michael',
			description: 'Lauryn and Michael swear by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Lauryn and Michael',
	},

	tracy: {
		utm_source: 'influencer',
		utm_medium: 'tracy',
		promoCode: 'tracy',
		promoBar: {
			'*': {
				message: 'Tracy Anderson Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Tracy Anderson Exclusive Sale',
		savingsTag: 'Tracy Anderson Exclusive: %%% off',
		heroSettings: {
			title:
				"“Just like in workouts, maintaining the right temperature is crucial for sleep, especially for women. Since integrating the Pod 4 into my life, I'm sleeping better, my recovery times have improved, and so has my daily energy.”",
			description: ['Tracy Anderson', 'Founder of The Tracy Anderson Method'],
			heading: 'Tracy approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Tracy Anderson Approved',
			paragraphs: ['Tracy swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Tracy Anderson Exclusive',
			subtitle: 'Tracy Anderson gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tracy-anderson-t1-main.jpg',
			imageAlt: 'Portrait of Tracy Anderson',
			description: 'Tracy Anderson swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Tracy Anderson',
	},

	perform: {
		utm_source: 'podcast',
		utm_medium: 'perform',
		promoCode: 'perform',
		promoBar: {
			'*': {
				message: 'Andy Galpin Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Perform Exclusive Sale',
		savingsTag: 'Perform Exclusive: %%% off',
		heroSettings: {
			title: "“I've been sleeping on an Eight Sleep for several years now and it is a total game changer. Being able to cool down in bed has done wonders for my sleep latency and quality.”",
			description: ['Host of Perform with Dr. Andy Galpin'],
			heading: 'Andy Galpin approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Perform Approved',
			paragraphs: ['Andy Galpin swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Perform Exclusive',
			subtitle: 'Perform gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/AndyGalpin-main.png',
			imageAlt: 'Portrait of Andy Galpin',
			description: 'Andy Galpin swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Andy Galpin',
	},

	drstephanie: {
		utm_source: 'newsletter',
		utm_medium: 'drstephanie',
		promoCode: 'drstephanie',
		promoBar: {
			'*': {
				message: 'Mini Pause with Dr. Stephanie Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Mini Pause with Dr. Stephanie Exclusive Sale',
		savingsTag: 'Mini Pause with Dr. Stephanie Exclusive: %%% off',
		heroSettings: {
			title:
				"“I used to think I was a pretty good sleeper until I got an Eight Sleep pod cover. Now I know what excellent, restorative sleep actually feels like! I'm waking up well-rested, with natural energy ready to crush my early morning workouts.”",
			description: ['Dr. Stephanie Estima', 'Host of Better with Dr. Stephanie and the Mini Pause newsletter'],
			heading: 'Dr. Stephanie approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Stephanie Approved',
			paragraphs: ['Dr. Stephanie swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Mini Pause with Dr. Stephanie Exclusive',
			subtitle: 'Dr. Stephanie gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drstephanie_main_2.png',
			imageAlt: 'Portrait of Dr. Stephanie',
			description: 'Dr. Stephanie swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Dr. Stephanie',
	},

	waveform: {
		utm_source: 'podcast',
		utm_medium: 'waveform',
		promoCode: 'wave',
		promoBar: {
			'*': {
				message: 'Waveform Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Waveform Exclusive Sale',
		savingsTag: 'Waveform Exclusive: %%% off',
		heroSettings: {
			title: '“In an age where humans are worse at sleeping than ever before with all this tech around us, finding some tech that actually helps improve my sleep is incredibly important to me.”',
			description: ['Marques Brownlee', 'Tech YouTuber and co-host of the Waveform podcast'],
			heading: 'Waveform approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Waveform Approved',
			paragraphs: ['Waveform swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Waveform Exclusive',
			subtitle: 'Waveform gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/waveform-main-t1.jpg',
			imageAlt: 'Portrait of Waveform',
			description: 'Waveform swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Waveform',
	},

	brew: {
		utm_source: 'newsletter',
		utm_medium: 'morningbrew',
		promoCode: 'brew',
		promoBar: {
			'*': {
				message: 'Morning Brew Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Morning Brew Exclusive Sale',
		savingsTag: 'Morning Brew Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: [],
			heading: 'Morning Brew approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Morning Brew Approved',
			paragraphs: ['Morning Brew swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Morning Brew Exclusive',
			subtitle: 'Morning Brew gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/morning-brew-main-t1.png',
			imageAlt: 'Portrait of Morning Brew',
			description: 'Morning Brew swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Morning Brew',
	},

	notboring: {
		utm_source: 'newsletter',
		utm_medium: 'notboring',
		promoCode: 'notboring',
		promoBar: {
			'*': {
				message: 'Not Boring Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Not Boring Exclusive Sale',
		savingsTag: 'Not Boring Exclusive: %%% off',
		heroSettings: {
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: [],
			heading: 'Packy McCormick approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Not Boring Approved',
			paragraphs: ['Packy McCormick swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Not Boring Exclusive',
			subtitle: 'Not Boring gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/not-boring-t1-main.png',
			imageAlt: 'Portrait of Not Boring',
			description: 'Packy McCormick  swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Packy McCormick',
	},

	flagrant: {
		utm_source: 'podcast',
		utm_medium: 'flagrant',
		promoCode: 'flagrant',
		promoBar: {
			'*': {
				message: 'Flagrant Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Flagrant Exclusive Sale',
		savingsTag: 'Flagrant Exclusive: %%% off',
		heroSettings: {
			title:
				"“Man, let me tell you, since I got my Eight Sleep mattress, my life has changed. I'm sleeping deeper and waking up feeling like a million bucks. This thing is like having a personal sleep coach, adjusting the temperature just right all night. If you want to feel refreshed and ready to crush your day, get yourself an Eight Sleep. Trust me, it's a game-changer.”",
			description: ['Andrew Schulz'],
			heading: 'Andrew Schulz approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Flagrant Approved',
			paragraphs: ['Andrew Schulz swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Flagrant Exclusive',
			subtitle: 'Flagrant gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-schulz-t1-main.jpg',
			imageAlt: 'Portrait of Flagrant',
			description: 'Andrew Schulz swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Andrew Schulz',
	},

	babish: {
		utm_source: 'influencer',
		utm_medium: 'babish',
		promoCode: 'babish',
		promoBar: {
			'*': {
				message: 'Babish Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Babish Exclusive Sale',
		savingsTag: 'Babish Exclusive: %%% off',
		heroSettings: {
			title:
				'“For me, getting to sleep used to mean an hourlong hostage negotiation between my body temperature and a heavy comforter - no more. The Pod 4 has helped repair my relationship with rest.”',
			description: ['Andrew Rea', 'Host of the Babish Culinary Universe'],
			heading: 'Andrew approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Babish Approved',
			paragraphs: ['Andrew swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Babish Exclusive',
			subtitle: 'Babish gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/babish-main-t1.jpg',
			imageAlt: 'Portrait of Babish',
			description: 'Andrew swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Andrew',
	},

	austen: {
		utm_source: 'influencer',
		utm_medium: 'austen',
		promoCode: 'austen',
		promoBar: {
			'*': {
				message: 'Austen Alexander Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Austen Alexander Exclusive Sale',
		savingsTag: 'Austen Alexander Exclusive: %%% off',
		heroSettings: {
			title: '“Eight Sleep has seriously optimized the time that I’m in bed. My REM sleep is up by 50% within the first two nights on Pod 4.”',
			description: ['Austen Alexander', 'Veteran and Youtuber'],
			heading: 'Austen approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Austen Alexander Approved',
			paragraphs: ['Austen Alexander swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Austen Alexander Exclusive',
			subtitle: 'Austen Alexander gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/austen-alexander-main-t1.jpg',
			imageAlt: 'Portrait of Austen Alexander',
			description: 'Austen Alexander swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Austen Alexander',
	},

	ryanshaw: {
		utm_source: 'influencer',
		utm_medium: 'shaw',
		promoCode: 'shaw',
		promoBar: {
			'*': {
				message: 'Ryan Shaw Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ryan Shaw Exclusive Sale',
		savingsTag: 'Ryan Shaw Exclusive: %%% off',
		heroSettings: {
			title:
				'“Quality sleep is essential for anything we want to do, and the Pod 4 Ultra not only improves my sleep automatically without using my phone, but gives me metrics to help improve from there. I’ve never seen a tech product do so much, so effectively.”',
			description: ['Ryan Shaw', 'Expert in all things Tesla related'],
			heading: 'Ryan Shaw approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ryan Shaw Approved',
			paragraphs: ['Ryan swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Ryan Shaw Exclusive',
			subtitle: 'Ryan Shaw gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/RST_Merch_Jan2021-3-1+1.jpg',
			imageAlt: 'Portrait of Ryan Shaw',
			description: 'Ryan Shaw swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Ryan Shaw',
	},

	sullins: {
		utm_source: 'influencer',
		utm_medium: 'sullins',
		promoCode: 'sullins',
		promoBar: {
			'*': {
				message: 'Ben Sullins Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ben Sullins Exclusive Sale',
		savingsTag: 'Ben Sullins Exclusive: %%% off',
		heroSettings: {
			title:
				'“Pod 4 has significantly improved the quality of sleep I’m getting. As a father with two young boys I need all the rest I can get just to keep up to them so I’m grateful for the support from the team at Eight Sleep.”',
			description: ['Ben Sullins', 'Exploring Sustainable Tech'],
			heading: 'Ben approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ben Sullins Approved',
			paragraphs: ['Ben swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Ben Sullins Exclusive',
			subtitle: 'Ben Sullins gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sullins-t1-main.jpg',
			imageAlt: 'Portrait of Ben Sullins',
			description: 'Ben Sullins swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Ben Sullins',
	},

	primal: {
		utm_source: 'influencer',
		utm_medium: 'primal',
		promoCode: 'primal',
		promoBar: {
			'*': {
				message: 'Eric Leija Exclusive: ### off the Pod',
				link: null,
			},
		},
		saleName: 'Eric Leija Exclusive Sale',
		savingsTag: 'Eric Leija Exclusive: %%% off',
		heroSettings: {
			title: "“I've dialed in my fitness routine, but it's the perfect blend of tech and comfort from Eight Sleep that truly optimizes my recovery.”",
			description: ['Eric Leija', 'Professional Crossfit Athlete '],
			heading: 'Eric approves the Pod',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Eric Leija Approved',
			paragraphs: ['Eric swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Eric Leija Exclusive',
			subtitle: 'Eric Leija gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/leija-t1-main.jpg',
			imageAlt: 'Portrait of Eric Leija',
			description: 'Eric swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Eric Leija',
	},

	lifetime: {
		utm_source: 'website',
		utm_medium: 'lifetime',
		promoCode: 'EXCLUSIVE4LIFETIME',
		promoBar: {
			'*': {
				message: 'Life Time Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Life Time Exclusive Sale',
		savingsTag: 'Life Time Exclusive: %%% off',
		heroSettings: {
			title: '',
			description: ['Life Time'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifeecapmob.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifeecapmob.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetimeecap.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'An exclusive offer for Life Time members',
			paragraphs: [`Life Time has carefully reviewed the Pod before recommending to its members. Now it's your turn.`],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime_prefooter.jpeg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime_prefooter.jpeg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime_prefooter.jpeg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Life Time Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-main.jpg',
			imageAlt: 'Portrait of Life Time',
			description: 'The Pod is approved by Life Time. Sign up to unlock your offer.',
		},
		partnerName: 'Lifetime',
	},

	doctormike: {
		utm_source: 'influencer',
		utm_medium: 'doctormike',
		promoCode: 'doctormike',
		promoBar: {
			'*': {
				message: 'Doctor Mike Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Doctor Mike Exclusive Sale',
		savingsTag: 'Doctor Mike Exclusive: %%% off',
		heroSettings: {
			heading: '“Thanks to Eight Sleep, no more hot, sleepless nights!”',
			title: '',
			description: ['Doctor Mike', 'Board Certified Family Medicine Doctor '],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Doctor Mike Approved',
			paragraphs: ['Doctor Mike swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Doctor Mike Exclusive',
			subtitle: 'Doctor Mike gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/drmike-t1-main.jpg',
			imageAlt: 'Portrait of Doctor Mike',
			description: 'Doctor Mike swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Doctor Mike',
	},

	yestheory: {
		utm_source: 'influencer',
		utm_medium: 'yestheory',
		promoCode: 'yestheory',
		promoBar: {
			'*': {
				message: 'Yes Theory Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Yes Theory Exclusive Sale',
		savingsTag: 'Yes Theory Exclusive: %%% off',
		heroSettings: {
			title:
				'“From running like a furnace in my sleep and constantly being disrupted by excessively sweating to now sleeping a full night like a baby on Pod 4 (aka sleep paradise). It is one of the best investments you can make to improve the very thing we all spend a third of our lives doing.”',
			description: ['Thomas and Ammar', 'Yes Theory Co-Founders'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Yes Theory Approved',
			paragraphs: ['Thomas and Ammar swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Yes Theory Exclusive',
			subtitle: 'Thomas and Ammar gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/yestheoryimg.png',
			imageAlt: 'Portrait of Thomas and Ammar',
			description: 'Thomas and Ammar swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Yes Theory',
	},

	seek: {
		utm_source: 'influencer',
		utm_medium: 'seek',
		promoCode: 'seek',
		promoBar: {
			'*': {
				message: 'Seek Discomfort Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Seek Discomfort Exclusive Sale',
		savingsTag: 'Seek Discomfort Exclusive: %%% off',
		heroSettings: {
			title:
				'“The Pod is one of the best investments you can make to improve the thing we spend 33% of our lives doing. I didn’t think it was possible to get deep without AC in the middle of the summer. Now I sleep like a baby with just the cooling power from Pod 4.”',
			description: ['Thomas and Ammar', 'Seek Discomfort Co-Founders'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Seek Discomfort Approved',
			paragraphs: ['Thomas and Ammar swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Seek Discomfort Exclusive',
			subtitle: 'Thomas and Ammar gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/seekdiscomfortimg.png',
			imageAlt: 'Portrait of Thomas and Ammar',
			description: 'Thomas and Ammar swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Seek Discomfort',
	},

	matty: {
		utm_source: 'influencer',
		utm_medium: 'matty',
		promoCode: 'matty',
		promoBar: {
			'*': {
				message: 'Matty Matheson Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Matty Matheson Exclusive Sale',
		savingsTag: 'Matty Matheson Exclusive: %%% off',
		heroSettings: {
			heading: 'Matty Matheson approves the Pod',
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: ['Matty Matheson', 'Actor & Restaurateur'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Matty Matheson Approved',
			paragraphs: ['Matty Matheson swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Matty Matheson Exclusive',
			subtitle: 'Matty gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/matty-t1-main.jpg',
			imageAlt: 'Portrait of Matty Matheson',
			description: 'Matty swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Matty Matheson',
	},

	sleepisaskill: {
		utm_source: 'influencer',
		utm_medium: 'sleepisaskill',
		promoCode: 'mollie',
		promoBar: {
			'*': {
				message: 'Mollie Eastman Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Mollie Eastman Exclusive Sale',
		savingsTag: 'Mollie Eastman Exclusive: %%% off',
		heroSettings: {
			heading: 'Mollie Eastman approves the Pod',
			title:
				"“I've analyzed thousands of wearable data points over the years, and few factors consistently improve metrics like deep sleep, heart rate, HRV, and wake-up events as effectively as sleeping cool. Eight Sleep offers a seamless and effortless solution that stands out in the market.”",
			description: ['Mollie Eastman', 'Sleep Expert & Behavioral Change Expert'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Mollie Eastman Approved',
			paragraphs: ['Mollie Eastman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Mollie Eastman Exclusive',
			subtitle: 'Mollie gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/mollie-estman-t1-main.jpg',
			imageAlt: 'Portrait of Mollie Eastman',
			description: 'Mollie swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Mollie Eastman',
	},

	wildtimes: {
		utm_source: 'podcast',
		utm_medium: 'wildtimes',
		promoCode: 'wildtimes',
		promoBar: {
			'*': {
				message: 'Wild Times Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Wild Times Exclusive Sale',
		savingsTag: 'Wild Times Exclusive: %%% off',
		heroSettings: {
			heading: 'Wild Times approves the Pod',
			title: 'The #1 health hack. Fits on any bed. Warm up, cool down, and track your health with the Pod.',
			description: [''],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Wild Times Approved',
			paragraphs: ['Wild Times swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Wild Times Exclusive',
			subtitle: 'Wild Times gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wild-times-t1-main.jpg',
			imageAlt: 'Portrait of Wild Times',
			description: 'Wild Times swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Wild Times',
	},

	ftd: {
		utm_source: 'influencer',
		utm_medium: 'ftd',
		promoCode: 'ftd',
		promoBar: {
			'*': {
				message: 'Action Bronson Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Action Bronson Exclusive Sale',
		savingsTag: 'Action Bronson Exclusive: %%% off',
		heroSettings: {
			heading: 'Action Bronson approves the Pod',
			title: 'Since using Pod 4, I’ve seen a spike in my dancing ability, my reading ability, my comprehension skills, and my problem solving skills.',
			description: ['Action Bronson', 'Musician, Culinary Explorer, and Designer '],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Action Bronson Approved',
			paragraphs: ['Action Bronson swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Action Bronson Exclusive',
			subtitle: 'Action gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/action-bronson-t1-main.jpg',
			imageAlt: 'Portrait of Action Bronson',
			description: 'Action swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Action Bronson',
	},

	wynwood: {
		utm_source: 'website',
		utm_medium: 'wynwood',
		promoCode: 'EXCLUSIVE4WYNWOOD',
		promoBar: {
			'*': {
				message: 'Wynwood Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Wynwood Exclusive Sale',
		savingsTag: 'Wynwood Exclusive: %%% off',
		heroSettings: {
			title: 'Up to ### off, exclusive to Wynwood players',
			description: ['Wynwood'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifeecapmob.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifeecapmob.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifeecapmob.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'An exclusive offer for Wynwood players',
			paragraphs: [`Wynwood has carefully reviewed the Pod before recommending to its members. Now it’s your turn.`],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wynwood_footer.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wynwood_footer.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wynwood_footer.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Life Time Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-main.jpg',
			imageAlt: 'Portrait of Life Time',
			description: 'The Pod is approved by Life Time. Sign up to unlock your offer.',
		},
		partnerName: 'Wynwood',
	},

	goodbounce: {
		utm_source: 'goodbounce',
		utm_medium: 'goodbounce',
		promoCode: 'EXCLUSIVE4GOODBOUNCE',
		promoBar: {
			'*': {
				message: 'Goodbounce Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Goodbounce Exclusive Sale',
		savingsTag: 'Goodbounce Exclusive: %%% off',
		heroSettings: {
			title: 'Up to ### off, exclusive to Goodbounce players',
			description: ['Goodbounce'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_ecap_mob.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_ecap_desktop.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_ecap_desktop.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'An exclusive offer for Goodbounce players',
			paragraphs: [`Goodbounce has carefully reviewed the Pod before recommending to its members. Now it's your turn.`],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_footer.jpeg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_footer.jpeg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_footer.jpeg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Are you looking to improve your sleep?',
			subtitle: 'Goodbounce Exclusive',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-main.jpg',
			imageAlt: 'Portrait of Life Time',
			description: 'The Pod is approved by Life Time. Sign up to unlock your offer.',
		},
		partnerName: 'Goodbounce',
	},

	krissy: {
		utm_source: 'influencer',
		utm_medium: 'krissy',
		promoCode: 'krissy',
		promoBar: {
			'*': {
				message: 'Krissy Cela Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Krissy Cela Exclusive Sale',
		savingsTag: 'Krissy Cela Exclusive: %%% off',
		heroSettings: {
			heading: 'Krissy Cela approves the Pod',
			title: '“I LOVE how my Pod 4 helps me get warm and cozy to the perfect temperature throughout the whole night, best sleep I’ve ever had!”',
			description: ['Krissy Cela', 'Fitness Expert & Entrepreneur'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Krissy Cela Approved',
			paragraphs: ['Krissy Cela swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Krissy Cela Exclusive',
			subtitle: 'Krissy gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/221020_Krissy_Cela_Press_224098+1+1.jpg',
			imageAlt: 'Portrait of Krissy Cela',
			description: 'Krissy swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Krissy',
	},
	cleetusm: {
		utm_source: 'influencer',
		utm_medium: 'cleetusm',
		promoCode: 'cleetusm',
		promoBar: {
			'*': {
				message: 'Cleetus McFarland Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Cleetus McFarland Exclusive Sale',
		savingsTag: 'Cleetus McFarland Exclusive: %%% off',
		heroSettings: {
			heading: 'Cleetus McFarland approves the Pod',
			title: "“I tend to get hot when sleeping and the Eight sleep's cooling option has been a game changer! Work hard, sleep harder.”",
			description: ['Cleetus McFarland', 'Automotive Enthusiast, YouTube Personality, and Entrepreneur'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Cleetus McFarland Approved',
			paragraphs: ['Cleetus McFarland swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Cleetus McFarland Exclusive',
			subtitle: 'Cleetus McFarland gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/cleetusm-t1-hero-resize.png',
			imageAlt: 'Portrait of Cleetus McFarland',
			description: 'Cleetus McFarland swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Cleetus McFarland',
	},

	tucker: {
		utm_source: 'podcast',
		utm_medium: 'tucker',
		promoCode: 'tucker',
		promoBar: {
			'*': {
				message: 'Tucker Carlson Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Tucker Carlson Exclusive Sale',
		savingsTag: 'Tucker Carlson Exclusive: %%% off',
		heroSettings: {
			heading: 'Tucker Carlson approves the Pod',
			title: '“It changes everything. You get far fewer problems with falling asleep and staying asleep, so you feel rested the next day.”',
			description: ['Tucker Carlson', 'Host of The Tucker Carlson Show'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Tucker Carlson Approved',
			paragraphs: ['Tucker swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Tucker Carlson Exclusive',
			subtitle: 'Tucker gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/tucker-carlson-main.jpg',
			imageAlt: 'Portrait of Tucker Carlson',
			description: 'Tucker swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Tucker Carlson',
	},

	pivot: {
		utm_source: 'podcast',
		utm_medium: 'pivot',
		promoCode: 'pivot',
		promoBar: {
			'*': {
				message: 'Pivot Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Pivot Exclusive Sale',
		savingsTag: 'Pivot Exclusive: %%% off',
		heroSettings: {
			heading: 'Pivot approves the Pod',
			title: '',
			description: ['Kara Swisher and Scott Galloway', 'Hosts of Pivot'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Pivot Approved',
			paragraphs: ['Kara and Scott swear by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Pivot Exclusive',
			subtitle: 'Pivot gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pivot-t1-main.jpg',
			imageAlt: 'Portrait of Kara and Scott',
			description: 'Kara and Scott swear by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Kara and Scott',
	},

	profg: {
		utm_source: 'podcast',
		utm_medium: 'profg',
		promoCode: 'profg',
		promoBar: {
			'*': {
				message: 'Scott Galloway Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Scott Galloway Exclusive Sale',
		savingsTag: 'Scott Galloway Exclusive: %%% off',
		heroSettings: {
			heading: 'Scott Galloway approves the Pod',
			title: '',
			description: ['Scott Galloway', 'Host of the Prof G Pod'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Scott Galloway Approved',
			paragraphs: ['Scott swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Scott Galloway Exclusive',
			subtitle: 'Scott Galloway gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Galloway-Hero-Final_mid.jpg',
			imageAlt: 'Portrait of Scott Galloway',
			description: 'Scott swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Scott Galloway',
	},
	today: {
		utm_source: 'podcast',
		utm_medium: 'today',
		promoCode: 'today',
		promoBar: {
			'*': {
				message: 'Today, Explained Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Today, Explained Exclusive Sale',
		savingsTag: 'Today, Explained Exclusive: %%% off',
		heroSettings: {
			heading: 'Today, Explained approves the Pod',
			title: '',
			description: ['Sean Rameswaram and Noel King', 'Hosts of Today, Explained'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Today, Explained Approved',
			paragraphs: ['Today, Explained swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Today, Explained Exclusive',
			subtitle: 'Today, Explained gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Voss-VoxGroup24FINAL-2-web-1200x799.jpg',
			imageAlt: 'Portrait of Today, Explained hosts',
			description: 'Today, Explained swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Today, Explained',
	},

	carriker: {
		utm_source: 'influencer',
		utm_medium: 'carriker',
		promoCode: 'carriker',
		promoBar: {
			'*': {
				message: 'Matt Carriker Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Matt Carriker Exclusive Sale',
		savingsTag: 'Matt Carriker Exclusive: %%% off',
		heroSettings: {
			heading: 'Matt Carriker approves the Pod',
			title: "“I tend to get hot when sleeping and the Eight Sleep's cooling option has been a game changer! Work hard, sleep harder.”",
			description: ['Matt Carriker,', 'Host of Demolition Ranch'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Matt Carriker Approved',
			paragraphs: ['Matt swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Matt Carriker Exclusive',
			subtitle: 'Matt Carriker gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carriker-t1-main.jpg',
			imageAlt: 'Portrait of Matt Carriker',
			description: 'Matt Carriker swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Matt Carriker',
	},

	rof: {
		utm_source: 'podcast',
		utm_medium: 'ringoffire',
		promoCode: 'rof',
		promoBar: {
			'*': {
				message: 'Ring of Fire Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Ring of Fire Exclusive Sale',
		savingsTag: 'Ring of Fire Exclusive: %%% off',
		heroSettings: {
			heading: 'Ring of Fire approves the Pod',
			title:
				'“This product is an absolute game changer. I’m finally able to sleep through the night and wake up in the morning without feeling exhausted, and the overall positive benefits to my health are undeniable.”',
			description: ['Farron Cousins', 'Host of The Ring of Fire'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Ring of Fire  Approved',
			paragraphs: ['Ring of Fire swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main-3.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Ring of Fire Exclusive',
			subtitle: 'Ring of Fire gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rof-t1-main.jpg',
			imageAlt: 'Portrait of Ring of Fire',
			description: 'Ring of Fire swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Ring of Fire',
	},

	dailycoach: {
		utm_source: 'newsletter',
		utm_medium: 'dailycoach',
		promoCode: 'dailycoach',
		promoBar: {
			'*': {
				message: 'Daily Coach Exclusive: ### off the Pod 4 Ultra',
				link: null,
			},
		},
		saleName: 'Daily Coach Exclusive Sale',
		savingsTag: 'Daily Coach Exclusive: %%% off',
		heroSettings: {
			heading: 'The Daily Coach approves the Pod',
			title: '',
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'The Daily Coach Approved',
			paragraphs: ['The Daily Coach swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Daily Coach Exclusive',
			subtitle: 'Daily Coach gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/daily-coach-t1-main.png',
			imageAlt: 'Portrait of Daily Coach',
			description: 'Daily Coach swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Daily Coach',
	},

	glueguys: {
		utm_source: 'podcast',
		utm_medium: 'glueguys',
		promoCode: 'glueguys',
		promoBar: {
			'*': {
				message: 'Glue Guys Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Glue Guys Exclusive Sale',
		savingsTag: 'Glue Guys Exclusive: %%% off',
		heroSettings: {
			heading: ' ',
			title:
				'“I took about 1,000 naps over my 13-year NBA career and boy do I wish I had Eight Sleep back then. I am a health and wellness guy, and Eight Sleep is a game-changer. I wake up more refreshed and more focused. If you care about recovery, this is what you are looking for.”',
			description: ['Shane Battier', 'American former professional basketball player and Host of Glue Guys'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Shane Battier Approved',
			paragraphs: ['Shane Battier swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Glue Guys Exclusive',
			subtitle: 'Glue Guys gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-11-24+(Shane+Battier)+139.jpg',
			imageAlt: 'Portrait of Glue Guys hosts',
			description: 'Shane Battier swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Glue Guys',
	},

	elissa: {
		utm_source: 'podcast',
		utm_medium: 'elissa',
		promoCode: 'elissa',
		promoBar: {
			'*': {
				message: 'Elissa Goodman Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Elissa Goodman Exclusive Sale',
		savingsTag: 'Elissa Goodman Exclusive: %%% off',
		heroSettings: {
			heading: 'Elissa Goodman approves the Pod',
			title:
				'“Sleep is truly the most foundational piece of wellness. Without it, all the whole foods, supplements, meditation, and yoga in the world won’t have the same impact. And here’s something I know for sure: healing on a cellular level can’t happen without good, deep sleep. That’s why I’ve been using the Eight Sleep for years - can’t live without it now!”',
			description: ['Elissa Goodman', 'Nutritionist and Cleanse Expert'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Elissa Goodman Approved',
			paragraphs: ['Elissa Goodman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Elissa Goodman Exclusive',
			subtitle: 'Elissa Goodman gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elissa-t1-main.jpg',
			imageAlt: 'Portrait of Elissa Goodman',
			description: 'Elissa Goodman swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Elissa Goodman',
	},

	rosen: {
		utm_source: 'newsletter',
		utm_medium: 'rosen',
		promoCode: 'rosen',
		promoBar: {
			'*': {
				message: 'Eric Rosen Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Eric Rosen Exclusive Sale',
		savingsTag: 'Eric Rosen Exclusive: %%% off',
		heroSettings: {
			heading: 'Eric Rosen approves the Pod',
			title:
				"“I sleep better and longer with Eight Sleep and especially like the ability to adjust settings to a cool temp.  After using Eight Sleep I regularly sleep 7.5 hours- almost 2 hours longer than I did prior to using the mattress pod; it's a game changer.”",
			description: ['Eric Rosen', 'Writer of "The Rosen Report"'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Eric Rosen Approved',
			paragraphs: ['Eric Rosen swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Eric Rosen Exclusive',
			subtitle: 'Eric Rosen gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/rosen-t1-ecap-new.jpg',
			imageAlt: 'Portrait of Eric Rosen',
			description: 'Eric Rosen swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Eric Rosen',
	},

	kimberly: {
		utm_source: 'podcast',
		utm_medium: 'kimberly',
		promoCode: 'kimberly',
		promoBar: {
			'*': {
				message: 'Kimberly Snyder Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Kimberly Snyder Exclusive Sale',
		savingsTag: 'Kimberly Snyder Exclusive: %%% off',
		heroSettings: {
			heading: 'Kimberly Snyder approves the Pod',
			title:
				'“Sleep is such a profoundly important part of our holistic lifestyle, and I have to say that the Pod has made a huge improvement to my quality of sleep. I highly recommend it- investing in your sleep is so important!”',
			description: ['Kimberly Snyder', 'Author of the New York Times 3-time bestselling book "The Hidden Power of the Five Hearts"'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Kimberly Snyder Approved',
			paragraphs: ['Kimberly Snyder swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Kimberly Snyder Exclusive',
			subtitle: 'Kimberly Snyder gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kimberly-new-t1-main.png',
			imageAlt: 'Portrait of Kimberly Snyder',
			description: 'Kimberly Snyder swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Kimberly Snyder',
	},

	london: {
		utm_source: 'newsletter',
		utm_medium: 'london',
		promoCode: 'london',
		promoBar: {
			'*': {
				message: 'Dr. Jeremy London Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Jeremy London Exclusive Sale',
		savingsTag: 'Dr. Jeremy London Exclusive: %%% off',
		heroSettings: {
			heading: 'Dr. Jeremy London approves the Pod',
			title: "“For years, I have struggled with sleep. The Pod's thermo-regulation technology has been transformative. The personalized data allows me to target and address never-recognized issues.”",
			description: ['Dr. Jeremy London', 'Board Certified Cardiovascular Surgeon'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Jeremy London Approved',
			paragraphs: ['Dr. Jeremy London swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Dr. Jeremy London Exclusive',
			subtitle: 'Dr. Jeremy London gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/jeremy-london-new-t1-main.png',
			imageAlt: 'Portrait of Dr. Jeremy London',
			description: 'Dr. Jeremy London swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Dr. Jeremy London',
	},

	sweetspot: {
		utm_source: 'newsletter',
		utm_medium: 'sweetspot',
		promoCode: 'sweetspot',
		promoBar: {
			'*': {
				message: 'Sweet Spot Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Sweet Spot Exclusive Sale',
		savingsTag: 'Sweet Spot Exclusive: %%% off',
		heroSettings: {
			heading: 'Sweet Spot approves the Pod',
			title:
				'“I take my sleep routine very seriously, and adding Eight Sleep to the mix has been a game changer. Being able to control the temperature of my mattress throughout the night has helped me get a deeper, more restful sleep. Additionally, I love seeing the data every morning; it gives me good feedback on maintaining the habits that are giving me the best results.”',
			description: ['Jon Sherman', 'Host of Sweet Spot'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Sweet Spot Approved',
			paragraphs: ['Sweet Spot swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Sweet Spot Exclusive',
			subtitle: 'Sweet Spot gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sweet-spot-t1-mai.png',
			imageAlt: 'Portrait of Sweet Spot',
			description: 'Sweet Spot swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Sweet Spot',
	},

	kevinrose: {
		utm_source: 'newsletter',
		utm_medium: 'kevinrose',
		promoCode: 'kevinrose',
		promoBar: {
			'*': {
				message: 'Kevin Rose Limited Offer: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Kevin Rose Limited Offer Sale',
		savingsTag: 'Kevin Rose Limited Offer: %%% off',
		heroSettings: {
			heading: 'Kevin Rose approves the Pod',
			title: '',
			description: [],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Kevin Rose Approved',
			paragraphs: ['Kevin Rose swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Kevin Rose Limited Offer',
			subtitle: 'Kevin Rose gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/kevin-rose-t1-main.png',
			imageAlt: 'Portrait of Kevin Rose',
			description: 'Kevin Rose swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Kevin Rose',
	},

	breakdowns: {
		utm_source: 'podcast',
		utm_medium: 'breakdowns',
		promoCode: 'breakdowns',
		promoBar: {
			'*': {
				message: 'Business Breakdowns Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Business Breakdowns Exclusive Sale',
		savingsTag: 'Business Breakdowns Exclusive: %%% off',
		heroSettings: {
			heading: 'Matt Reustle approves the Pod',
			title:
				"“I lived my life just assuming I was a bad sleeper... then I got an EightSleep. You don't realize how sleep-deprived you are until you get a full, uninterrupted, climate-controlled night of sleep. The temperature control is such a game changer.”",
			description: ['Matt Reustle', 'Host of Business Breakdowns'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Business Breakdowns Approved',
			paragraphs: ['Business Breakdowns swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Business Breakdowns Limited Offer',
			subtitle: 'Business Breakdowns gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/business-t1-main.jpg',
			imageAlt: 'Portrait of Matt Reustle',
			description: 'Business Breakdowns swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Business Breakdowns',
	},

	practicalai: {
		utm_source: 'podcast',
		utm_medium: 'practicalai',
		promoCode: 'practicalai',
		promoBar: {
			'*': {
				message: 'Practical AI Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Practical AI Exclusive Sale',
		savingsTag: 'Practical AI Exclusive: %%% off',
		heroSettings: {
			heading: 'Practical AI approves the Pod',
			title: '',
			description: [],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Practical AI Approved',
			paragraphs: ['Practical AI swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Practical AI Exclusive',
			subtitle: 'Practical AI gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/practicalai_1200x1200bf-60.jpg',
			imageAlt: '',
			description: 'Practical AI swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Practical AI',
	},

	joys: {
		utm_source: 'podcast',
		utm_medium: 'joys',
		promoCode: 'joys',
		promoBar: {
			'*': {
				message: 'Joys of Compounding Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Joys of Compounding Exclusive Sale',
		savingsTag: 'Joys of Compounding Exclusive: %%% off',
		heroSettings: {
			heading: 'Joys of Compounding approves the Pod',
			title:
				"“I've been an amateur biohacker for decades. I was given the name Mr. Cholesterol by my family. This company has fundamentally changed my daily and nightly life. Literally on my first night, I got an hour more of deep sleep and my HRV almost doubled. It's now been over a year and it's been a total gamechanger.”",
			description: ['Rick Buhrman'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Joys of Compounding Approved',
			paragraphs: ['Rick Buhrman swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Joys of Compounding Exclusive',
			subtitle: 'Joys of Compounding gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Joys+of+Compounding+main+t1.png',
			imageAlt: 'Portrait of Rick Buhrman',
			description: 'Rick Buhrman swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Joys of Compounding',
	},

	theproof: {
		utm_source: 'podcast',
		utm_medium: 'theproof',
		promoCode: 'theproof',
		promoBar: {
			'*': {
				message: 'The Proof Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Proof Exclusive Sale',
		savingsTag: 'The Proof Exclusive: %%% off',
		heroSettings: {
			heading: 'Simon Hill approves the Pod',
			title: '',
			description: ['Simon Hill', 'Physiotherapist and Nutrition Scientist'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Simon Hill Approved',
			paragraphs: ['Simon Hill swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'The Proof Exclusive',
			subtitle: 'The Proof gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/simon-hill-t1-main.jpg',
			imageAlt: 'Portrait of Simon Hill',
			description: 'Simon Hill swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'The Proof',
	},

	changelog: {
		utm_source: 'podcast',
		utm_medium: 'changelog',
		promoCode: 'changelog',
		promoBar: {
			'*': {
				message: 'The Changelog Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'The Changelog Exclusive Sale',
		savingsTag: 'The Changelog Exclusive: %%% off',
		heroSettings: {
			heading: 'The Changelog approves the Pod',
			title: '',
			description: [],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'The Changelog Approved',
			paragraphs: ['The Changelog swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'The Changelog Exclusive',
			subtitle: 'The Changelog gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/the-changelog-t1-main.png',
			imageAlt: 'Portrait of The Changelog',
			description: 'The Changelog swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'The Changelog',
	},

	codie: {
		utm_source: 'podcast',
		utm_medium: 'codie',
		promoCode: 'codie',
		promoBar: {
			'*': {
				message: 'Contrarian Thinking Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Contrarian Thinking Exclusive Sale',
		savingsTag: 'Contrarian Thinking Exclusive: %%% off',
		heroSettings: {
			heading: 'Codie approves the Pod',
			title: '“I’m tired of my husband telling me to not touch the thermostat 😂 … problem solved.”',
			description: ['Codie Sanchez'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Contrarian Thinking Approved',
			paragraphs: ['Codie swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Contrarian Thinking Exclusive',
			subtitle: 'Contrarian Thinking gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/codie-sanchez-t1-new.jpg',
			imageAlt: 'Portrait of Codie',
			description: 'Codie swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Contrarian Thinking',
	},

	livemore: {
		utm_source: 'podcast',
		utm_medium: 'livemore',
		promoCode: 'livemore',
		promoBar: {
			'*': {
				message: 'Dr. Chatterjee Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Dr. Chatterjee Exclusive Sale',
		savingsTag: 'Dr. Chatterjee Exclusive: %%% off',
		heroSettings: {
			heading: 'Dr. Chatterjee approves the Pod',
			title: '“A good night’s sleep is a crucial ingredient for how we feel in the short term and our long term health…many have found the pod to be transformative.”',
			description: ['Dr. Chatterjee'],
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Dr. Chatterjee Approved',
			paragraphs: ['Dr. Chatterjee swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Dr. Chatterjee Exclusive',
			subtitle: 'Dr. Chatterjee gifted you up to %%% off the Pod',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2livemore-t1-new-updated.jpg',
			imageAlt: 'Portrait of Dr. Chatterjee',
			description: 'Dr. Chatterjee swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Dr. Chatterjee',
	},

	junglebadger: {
		utm_source: 'podcast',
		utm_medium: 'junglebadger',
		promoCode: 'junglebadger',
		promoBar: {
			'*': {
				message: 'Jungle Badger Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Jungle Badger Exclusive Sale',
		savingsTag: 'Jungle Badger Exclusive: %%% off',
		heroSettings: {
			heading: 'Jungle Badger approves the Pod',
			title: '',
			description: [''],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Jungle Badger Approved',
			paragraphs: ['Jungle Badger swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Jungle Badger Exclusive',
			subtitle: 'Jungle Badger gifted you up to %%% off the Pod',
			imageSrc: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
			imageAlt: 'Portrait of Jungle Badger',
			description: 'Jungle Badger swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Jungle Badger',
	},

	techbadger: {
		utm_source: 'podcast',
		utm_medium: 'techbadger',
		promoCode: 'techbadger',
		promoBar: {
			'*': {
				message: 'Tech Badger Exclusive: Up to ### off the Pod',
				link: null,
			},
		},
		saleName: 'Tech Badger Exclusive Sale',
		savingsTag: 'Tech Badger Exclusive: %%% off',
		heroSettings: {
			heading: 'Tech Badger approves the Pod',
			title: '',
			description: [''],
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		preFooterSettings: {
			title: 'Tech Badger Approved',
			paragraphs: ['Tech Badger swears by the Pod to get better sleep. Learn more for yourself.'],
			ctaButtonLabel: 'Shop now',
			isDark: true,
			sources: [
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(max-width: 599px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 600px) and (max-width: 1023px)',
				},
				{
					src: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
					mediaQuery: '(min-width: 1024px)',
				},
			],
		},
		emailCapSettings: {
			title: 'Tech Badger Exclusive',
			subtitle: 'Tech Badger gifted you up to %%% off the Pod',
			imageSrc: 'https://eightsleep.imgix.net/ecap_generic.jpg?v=1695885509',
			imageAlt: 'Portrait of Tech Badger',
			description: 'Tech Badger swears by the Pod for unbeatable sleep. Sign up to unlock your offer.',
		},
		partnerName: 'Tech Badger',
	},
}

const vanityAdditionalRoutes: IVanityConfig = {
	joe: { ...vanities['joe-pomp'] },
}

const combinedVanities = { ...vanities, ...vanityAdditionalRoutes }

Object.keys(combinedVanities).forEach((vanityKey) => {
	if (!combinedVanities[vanityKey].klaviyoProperties) {
		combinedVanities[vanityKey].klaviyoProperties = {
			title: combinedVanities[vanityKey].heroSettings?.title || '',
			image: combinedVanities[vanityKey].heroSettings?.sources?.[0].src || 'https://eightsleep.imgix.net/email-cap-modal-mobile.png?v=1635285948',
			name: combinedVanities[vanityKey].saleName.split('Sale')[0].trim(),
			discount: '###',
			mattressDiscount: '%%%',
			link: `https://www.eightsleep.com/${vanityKey}`,
		}
	}
})

export const vanity_utms = Object.keys(combinedVanities).reduce((acc, currentKey) => {
	return {
		...acc,
		[currentKey]: {
			utm_source: combinedVanities[currentKey].utm_source,
			utm_medium: combinedVanities[currentKey].utm_medium,
		},
	}
}, {})

export const generateVanityUrls = () => {
	return Object.keys(vanityConfig)
		.filter((path) => !vanityConfig[path].page || vanityConfig[path].page?.startsWith('*'))
		.map((path) => ({
			params: {
				t1: path.toLowerCase(),
			},
		}))
}

export const vanity_paths = () =>
	generateVanityUrls().flatMap((p) => {
		return config.allRegions.map((it) => `${it ? '/' + it : ''}/${p.params.t1}`)
	})

export const getVanityData = () => {
	if (window.location.search.includes('code=') && window.location.search.includes('50')) {
		try {
			const searchParams = new URLSearchParams(window.location.search)
			const code = searchParams.get('code').toLowerCase()
			const vanity = getVanityDataFromDiscountCode(code.replace('50', ''))
			if (vanity) {
				return { ...vanity, utm_source: searchParams.get('utm_source') || vanity.utm_source, promoCode: code }
			}
		} catch (e) {
			datadogReportError(e, 'Error extracting T1 code query param')
		}
	}

	const vanity_item = localStorage.getItem('vanity')
	if (vanity_item) {
		const key = vanity_item.split('|')[0]
		let result = { ...vanityConfig[key], page: key }
		const appliedDiscountCode = sessionStorage.getItem('appliedDiscountCode')
		if (appliedDiscountCode && appliedDiscountCode.startsWith(result.promoCode)) {
			result.promoCode = appliedDiscountCode
		}
		const searchParams = new URLSearchParams(window.location.search)
		const utm_source = searchParams.get('utm_source')
		if (utm_source) {
			result.utm_source = utm_source
		}
		return result
	}

	return null
}

export const setVanityData = (page: string) => {
	if (typeof window !== 'undefined') {
		const split = location.pathname.split('/')
		const pathname = split[split.length - 1] || split[split.length - 2]
		if (pathname && vanityConfig[pathname]) {
			localStorage.setItem('vanity', `${pathname}|${new Date().toISOString()}`)
		} else if (page && vanityConfig[page]) {
			localStorage.setItem('vanity', `${page}|${new Date().toISOString()}`)
		}
	}
}

export const getVanityDataFromDiscountCode = (discountCode: string) => {
	const vanityKey = Object.keys(combinedVanities).find((key) => vanityConfig[key].promoCode === discountCode)
	return vanityKey ? { ...combinedVanities[vanityKey], page: vanityKey } : null
}

export const VANITY_DISCOUNT_CODES = [...new Set(Object.values(combinedVanities).map((vanity) => vanity.promoCode)), ...PARTNER_COUPONS.map((it) => it.toLowerCase())]

export const vanityConfig: IVanityConfig = combinedVanities
