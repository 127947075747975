import { FC } from 'react'
import styles from './ProductTitle.module.scss'
import { ProductTitleProps } from './types'
import { PricingInfo } from '../PricingInfo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { PriceManager } from 'prices'
import { FinancingInfo } from 'components/Financing/Financing'
import cx from 'classnames'
import { Visuals } from 'components/Phantom/_shop/Visuals'
import { PaymentOptionTabsConnected } from 'components/FinancingTabModal'
import { Type } from 'components/Type'
import useShopPagePricingAB from 'hooks/useShopPagePricingAB'

/**
 * Connected version of the `<ProductTitle/>` component. Automatically subscribes to the shop store.
 */
export const ProductTitleConnected = observer(() => {
	const { shopStore, priceStore } = useRootStore()

	const isShopPagePricingAB = useShopPagePricingAB()

	const selectors = isShopPagePricingAB ? shopStore.currentSelectorsShopPricingAB : shopStore.currentSelectors
	const selectorsProps = selectors.map((selectorKey) => shopStore.getSelectorProps(selectorKey)).filter((x) => !!x)
	const currentVisuals = shopStore.getCurrentVisuals(isShopPagePricingAB)
	const currentSelections = shopStore.getCurrentSelections()

	const props: ProductTitleProps = {
		title: shopStore.podData.name,
		price: shopStore.podVariant.prices.price,
		comparePrice: shopStore.podVariant.prices.comparePrice,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
		currency: priceStore.currency,
	}

	return (
		<>
			<div className={styles.mobile_visual}>
				<Visuals
					visuals={currentVisuals}
					currentSelectorKey={selectorsProps[0].selectorKey}
					renderSingle={true}
					currentSelections={currentSelections}
				/>
			</div>

			{isShopPagePricingAB && (
				<div className={styles.sticky_wrapper_mobile}>
					<PaymentOptionTabsConnected />
				</div>
			)}

			<ProductTitle
				{...props}
				isShopPagePricingAB={isShopPagePricingAB}
				as={'h1'}
			/>
		</>
	)
})

/**
 * First shop page component. Displays the product title, price, and financing information.
 *
 * **Usually connected to the RootStore using the `<ProductTitleConnected/>` component**
 */
export const ProductTitle: FC<ProductTitleProps> = (props) => {
	const { title, price, comparePrice, currency, financingAmount, isShopPagePricingAB, as = 'h1' } = props

	return (
		<section className={cx(styles.container, 'connected-title')}>
			<Type.Headline2
				as={as}
				className={styles.product_title}
				animateOnScroll={false}
			>
				{title}
			</Type.Headline2>
			{!isShopPagePricingAB && (
				<>
					<PricingInfo
						price={PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}
						comparePrice={PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)}
						discountText={`${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
					/>
					<Type.SmallPrint className={styles.financing_info}>
						<FinancingInfo
							priceFormatted={financingAmount}
							className={styles.financing_info_inner}
						/>
					</Type.SmallPrint>
					<TruemedInfo className={styles.financing_info} />
				</>
			)}
		</section>
	)
}
