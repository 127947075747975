import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getAutopilotVisuals = (addPills = false): VisualProps => {
	return {
		type: 'image',
		associatedSelectorKeys: ['auto-pilot'],
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot_77843331.png',
			alt: 'App showing autopilot',
		},
		pills: addPills
			? [
					{
						icon: 'CoolLight',
						text: 'Sleep and health reports',
					},
					{
						icon: 'BedLight',
						text: 'Automatic temperature adjustments',
					},
					{
						icon: 'TwoUsersLight',
						text: 'One plan for you and your partner',
					},
			  ]
			: [],
	}
}
