import React, { CSSProperties, FC, useEffect } from 'react'
import styles from './Shop.module.scss'
import { ProductTitleConnected } from 'components/Phantom/_shop/ProductTitle'
import { ModalConnected } from 'components/Phantom/_shop/Modals/Modal'
import { StickyConnected } from 'components/Phantom/_shop/Sticky'
import { SideNavConnected } from 'components/Phantom/_shop/SideNav'
import { observer } from 'mobx-react'
import { VisualsConnected } from 'components/Phantom/_shop/Visuals'
import { SelectorsConnected } from 'components/Phantom/_shop/Selectors'
import { useRootStore } from 'components/_hooks/useRootStore'
import { PaymentOptionTabsConnected } from 'components/FinancingTabModal'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import useShopPagePricingAB from 'hooks/useShopPagePricingAB'

export const ConnectedShop: FC = observer(() => {
	const isShopPagePricingAB = useShopPagePricingAB()

	useEffect(() => {
		const intersectionObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && isShopPagePricingAB) {
					document.body.style.setProperty('--kustomer-height', '5.5rem')
				} else {
					document.body.style.removeProperty('--kustomer-height')
				}
			})
		})

		intersectionObserver.observe(document.getElementById('shop-side-nav'))

		return () => {
			intersectionObserver.disconnect()
		}
	}, [isShopPagePricingAB])

	return (
		<main>
			<StickyConnected />
			<ModalConnected />

			<div
				className={styles.container}
				style={
					{
						'--desktop-padding': isShopPagePricingAB ? '8rem' : '10rem',
					} as CSSProperties
				}
				id="shop"
			>
				<div className={styles.container_inner}>
					<div className={styles.desktop_carousel_wrapper}>
						<VisualsConnected />
					</div>
					<div className={styles.sections}>
						{isShopPagePricingAB && (
							<div className={styles.sticky_wrapper_desktop}>
								<PaymentOptionTabsConnected />
							</div>
						)}
						<ProductTitleConnected />
						<SelectorsConnected />
						{isShopPagePricingAB && <BottomStickyConnected />}
					</div>
				</div>
				<div className={styles.side_nav_outer}>
					<div className={styles.side_nav_container}>
						<SideNavConnected />
					</div>
				</div>
			</div>
			{/*<Debug />*/}
		</main>
	)
})

const BottomStickyConnected = observer(() => {
	const { shopStore } = useRootStore()

	const shippingText = shopStore.getShippingText()

	const getPricingContent = () => {
		if (shopStore.paymentOption === 'affirm') {
			return (
				<>
					{shopStore.totalPriceFinancingNoMembership}/mo <del>{shopStore.totalPriceFinancingCompareNoMembership}/mo</del>
				</>
			)
		} else {
			return (
				<>
					{shopStore.shopTotal.priceString} <del>{shopStore.shopTotal.comparePriceString}</del>
				</>
			)
		}
	}

	return (
		<div className={styles.bottom_sticky_wrapper}>
			<Button.Empty
				id={'pricing-button'}
				onClick={() => shopStore.selectModal('financing-modal')}
				className={styles.pricing_button}
			>
				<span className={styles.big_chevron}>
					<Icon
						name={'ChevronDownLight'}
						color={'black'}
						size={16}
					/>
				</span>
				<span className={styles.pricing_button_interior}>
					<span className={styles.pricing_content}>
						{getPricingContent()}{' '}
						<Icon
							name={'ChevronDownLight'}
							color={'black'}
						/>
					</span>

					<span className={styles.membership_content}>
						{shopStore.paymentOption === 'affirm' && <p className={styles.membership_text}>+ Autopilot plan</p>}
						{shopStore.paymentOption !== 'affirm' && <p className={styles.membership_text}>includes Autopilot plan {shopStore.hasAccessory && ' + accessories'}</p>}
					</span>
				</span>
			</Button.Empty>
			<div className={styles.checkout_button}>
				<Button.Dark
					id={'checkout-button'}
					onClick={() => shopStore.checkout('selection-summary')}
				>
					Checkout
				</Button.Dark>
				{shippingText && <p className={styles.shipping_text}>{shippingText}</p>}
			</div>
		</div>
	)
})

const Debug = observer(() => {
	const { shopStore } = useRootStore()
	return (
		<div
			style={{
				position: 'fixed',
				bottom: '0',
				left: '0',
				backgroundColor: 'rgba(200,210,255,0.5)',
				color: 'black',
				fontSize: '0.75rem',
				maxWidth: '40vw',
				contain: 'paint',
				zIndex: 1000,
			}}
		>
			<pre>{JSON.stringify(shopStore.getCurrentSelections(), null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectedVariant, null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectedUpsells, null, 2)}</pre>
			<pre>{JSON.stringify(shopStore.selectionKeys, null, 2)}</pre>
		</div>
	)
})
