import { FC } from 'react'
import styles from './PricingInfo.module.scss'
import { PricingInfoProps } from './types'
import cx from 'classnames'
import { Type } from 'components/Type'

export const PricingInfo: FC<PricingInfoProps & { big?: boolean }> = (props) => {
	const { price, comparePrice, discountText } = props

	const isDiscount = comparePrice && comparePrice > price
	return (
		<Type.Body1
			className={cx(styles.pricing_info, { [styles.small]: !props.big })}
			animateOnScroll={false}
		>
			<span aria-label="Current price">{price}</span>
			{comparePrice > price && <del aria-label="Old price">{comparePrice}</del>}
			{discountText && (
				<span
					className={styles.discount_tag}
					style={{ visibility: isDiscount ? 'visible' : 'hidden' }}
					aria-hidden={!isDiscount}
				>
					{discountText}
				</span>
			)}
		</Type.Body1>
	)
}
