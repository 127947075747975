import { useEffect, useState } from 'react'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { scrollLocked } from 'utils/jss'

export const useManageShopSticky = (id = 'selection-summary-button', wipeWhenPrevented = true) => {
	const [stickyPreventionInView, setStickyPreventionInView] = useState(false)
	const headerWiped = useScrollThreshold()

	useEffect(() => {
		const header = document.querySelector('header')

		const resizeListener = () => {
			if (header && window.innerWidth >= 1024 && headerWiped && (!stickyPreventionInView || wipeWhenPrevented)) {
				header.style.transform = 'translateY(-100%)'
				header.style.transition = 'transform 0.3s ease-in-out'
			} else if (!scrollLocked) {
				header.style.transform = 'none'
				header.style.transition = 'transform 0.3s ease-in-out'
			}
		}

		resizeListener()

		const selectionSummaryElement = document.getElementById(id)

		const observer = new IntersectionObserver((entries) => {
			setStickyPreventionInView(entries[0].isIntersecting)
		})

		if (selectionSummaryElement) {
			observer.observe(selectionSummaryElement)
		}

		window.addEventListener('resize', resizeListener)
		return () => {
			observer.disconnect()
			window.removeEventListener('resize', resizeListener)
		}
	}, [headerWiped, stickyPreventionInView, wipeWhenPrevented])

	return stickyPreventionInView
}
